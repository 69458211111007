import React from "react";
import commonstyles from "../../styles/Management_Common.module.css";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import arrowDown from "../../assets/images/arrow_down.svg";
import dots from "../../assets/images/column_dots.svg";
import exportIcon from "../../assets/images/export_icon.svg";
import excel from "../../assets/images/excel.svg";
import pdfIcon from "../../assets/images/excel_icon.svg";
import excelIcon from "../../assets/images/excel_icon.svg";
import eyeView from "../../assets/img/action_view.svg";
import { useHistory } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { AxiosInstance /* , PORTS */ } from "../../utilities";
import AntDateRangeSelectComponent from "../ReusableComponent/AntDateRangeSelectComponent";
import { arrayMove } from "react-movable";
import { notification, Dropdown, Menu, Select } from "antd";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import MovableListComponent from "../ReusableComponent/MovableListComponent";
import Search from "../../assets/images/search_icon.svg";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import ButtonComponent from "../ReusableComponent/ButtonComponent";
import moment from "moment";
import "antd/dist/antd.css";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Customer Name", value: "customer" },
  { _id: 3, status: 1, name: "Email Address", value: "email" },
  { _id: 4, status: 1, name: "Mobile Number", value: "mobile" },
  { _id: 5, status: 1, name: "Order Total", value: "order" },
  { _id: 6, status: 1, name: "Location", value: "location" },
  { _id: 8, status: 1, name: "Action", value: "Action" },
];

const filters = [{ id: 1, isChecked: false, name: "Date", value: "date" }];

const daysObj = [
  { _id: 1, name: "Today", value: 0 },
  { _id: 2, name: "30 day back", value: 30 },
  { _id: 3, name: "60 day back", value: 60 },
  { _id: 4, name: "90 day back", value: 90 },
  { _id: 5, name: "120 day back", value: 120 },
  { _id: 6, name: "180 day back", value: 90 },
  { _id: 7, name: "1 year back", value: 365 },
  { _id: 8, name: "2 year back", value: 365 * 2 },
  { _id: 9, name: "3 year back", value: 365 * 3 },
  { _id: 10, name: "5 year back", value: 365 * 5 },
  { _id: 11, name: "Custom", value: "custom" },
];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

const original_columns = [
  "customer",
  "email",
  "mobile",
  "order",
  "location",
  "Action",
];

const column_value = {
  customer: true,
  email: true,
  mobile: true,
  order: true,
  location: true,
  Action: true,
};

function CustomerList() {
  const history = useHistory();
  const [customerList, setCustomerList] = useState([]);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setpagination] = useState({});
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [IsLoading, setIsLoading] = useState(false);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [filterDays, setFilterDays] = useState("");
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [sortOrder, setSortorder] = useState("DSC");
  const [filteredResult, setFilteredResult] = useState("");
  const [getCompany, setGetCompany] = useState([]);
  const [filterCompany, setfilterCompany] = useState("");
  const timeoutId = useRef();
  const [firstTime, setFirstTime] = useState(true);

  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Customer Name",
      visible: false,
      key: "customer",
      render: (text, record, index) => (
        <NavLink to={`/customer/${record?._id}`}>
          <div>
            <span>
              {record?.firstName} {record?.lastName}
            </span>
          </div>
        </NavLink>
      ),
      sorter: (a, b) => a.firstName - b.firstName,
    },
    {
      title: "Email Address",
      visible: false,
      key: "email",
      render: (text, record, index) => (
        <NavLink to={`/customer/${record?._id}`}>
          <div>
            <span>{record?.email ? record?.email : "-"}</span>
          </div>
        </NavLink>
      ),
    },
    {
      title: "Mobile Number",
      visible: false,
      key: "mobile",
      render: (text, record, index) => (
        <NavLink to={`/customer/${record?._id}`}>
          <div>
            <span>{record?.phone ? record?.phone : "-"}</span>
          </div>
        </NavLink>
      ),
    },
    {
      title: "Order Total",
      visible: false,
      key: "order",
      render: (text, record, index) => (
        <NavLink to={`/customer/${record?._id}`}>
          <div>
            <span>{record?.totalOrders ? record?.totalOrders : "-"}</span>
          </div>
        </NavLink>
      ),
    },
    {
      title: "Location",
      visible: false,
      key: "location",
      render: (text, record, index) => (
        <NavLink to={`/customer/${record?._id}`}>
          <div>
            <span>
              {record?.state ? record?.state + ", " + record?.city : "-"}
            </span>
          </div>
        </NavLink>
      ),
    },
    // {
    //   title: "Action",
    //   visible: false,
    //   key: "Action",
    //   render: (text, record, index) => (
    //     // <Link to={`/site-settings/modular/${record._id}`}>
    //     <img
    //       src={eyeView}
    //       className={`${commonstyles.admin_table_edit_img} mr-2`}
    //       //  onClick={() => editCustomer(record)}
    //       alt="View customer details"
    //       title="View customer details"
    //       style={{ cursor: "pointer" }}
    //     />
    //     // </Link>
    //   ),
    // },
  ]);

  function onChange(pagination, filters, sorter, extra) {
    // console.log('params', sorter);
    let sortValue = sorter.order === "ascend" ? "ASC" : "DSC";
    // console.log(sortValue);
    setSortorder(sortValue);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder: sorter.order === "ascend" ? "ASC" : "DSC",
    };
    setNextPage(1);
    getCustomerList(pageSize, 1, updatedFilter, true, true);
  }

  const [selectedOrder, setSelectedOrder] = useState([]);
  const childCheckbox = (e, record) => {
    // console.log(e, record);
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("customerList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("customerList", JSON.stringify(cloneOrderList));
    setCustomerList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
    const cloneSelectFilter = [...selectedOrder];
    cloneOrderList.forEach((item) => {
      if (item.isChecked === true) {
        cloneSelectFilter.push(item._id);
      }
    });
    setSelectedOrder(cloneSelectFilter);
  };
  // console.log("sort",sortOrder);
  const parentCheckbox = (e) => {
    localStorage.removeItem("selectedOrder");
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("customerList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        cloneOrderList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("customerList", JSON.stringify(cloneOrderList));
    setCustomerList(cloneOrderList);
    setSelectedOrder(cloneSelectedOrder);
  };
  useEffect(() => {
    getCompanyDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    set_new_header(headers);
    let filter_value = JSON.parse(sessionStorage.getItem("customer_filter"));
    //console.log(filter_value);
    setPageSize(filter_value?.pagesize ?? 10);
    setNextPage(filter_value?.nextpage ?? 1);
    //setSearch(filter_value?.search ?? "")
    const filter = {
      filterDays: filter_value?.filterDays ?? "",
      shipmentType: filter_value?.shipmentType ?? "",
      startDate: filter_value?.startDate ?? "",
      endDate: filter_value?.endDate ?? "",
    };
    getCustomerList(
      filter_value?.pagesize ?? 10,
      filter_value?.nextpage ?? 1,
      "",
      false,
      false,
      filter
    );
    //getCustomerList(pageSize, nextPage, "");
    localStorage.removeItem("selectedIds");
    return () => {
      localStorage.removeItem("customerList");
      localStorage.removeItem("selectedIds");
    };
  }, [filterCompany]);
  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }
  function getCompanyDetail() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getCompanies?pageNum=1&count=20&sortField=firstName&sortValue=DSC`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetCompany(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get company details",
        };
        showNotification(error);
        // return er;
      });
  }
  // *** FETCH CUSTOMER LIST *** //
  function getCustomerList(pageSize, nextPage, filterarr, type, first, filter) {
    // setIsLoading(false);
    // console.log(filterarr);
    if (!type) {
      setIsLoading(true);
    }
    var filterOptions
    if(filter) {
      filterOptions = {
       pagesize: pageSize,
       nextpage: nextPage,
       companyId: filterCompany,
       //search: "",
       shipmentType: filter.shipmentType,
       filterDays: filter.filterDays,
       //successShipment:"",
       startDate: filter.startDate,
       endDate: filter.endDate
     };
   }else{
      filterOptions = {
       pagesize: pageSize,
       nextpage: nextPage,
       companyId: filterCompany,
       //search: "",
       shipmentType: "",
       filterDays: "",
       //successShipment:"",
       startDate: "",
       endDate: "",
     };
   }
    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else if (search !== "") {
      if (filterarr && filterarr.search !== "") {
        filterOptions.search = search;
      }
    }
    if (filterarr && filterarr.filterDays) {
      filterOptions.filterDays = +filterarr.filterDays;
    } else if (filteredDate.filterDays !== "") {
      if (filterarr && filterarr.filterDays !== "") {
        filterOptions.filterDays = +filterarr.filterDays;
      }
    }

    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    /* if (filterarr && filterarr.sortOrder) {
          filterOptions.sortOrder = Number(filterarr.sortOrder);
        } else if (sortOrder !== "") {
          if (filterarr && filterarr.sortOrder !== "") {
            filterOptions.sortOrder = Number(sortOrder);
          }
        } */
    // console.log("filter",filterarr?.filterDays);

    if (!firstTime || first)
      sessionStorage.setItem("customer_filter", JSON.stringify(filterOptions));
    AxiosInstance(8000, "application/json")
      .post(
        `customers/getCustomers?pageNum=${nextPage}&count=${pageSize}&sortField=firstName&sortValue=${filterarr?.sortOrder ? filterarr?.sortOrder : "DSC"}&companyId=${filterCompany}`,
        filterOptions
      )
      .then((res) => {
        // console.log(filterOptions);
        if (res && res.data && res.status == "200") {
          // console.log(res.data.data);
          if (res.data.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // console.log(res.data)
            localStorage.setItem("customerList", JSON.stringify(res.data.data));
            setCustomerList(res.data.data);
            setpagination(res.data.pagination);
          } else {
            setCustomerList([]);
            setpagination({});
          }
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  /* useEffect(() => {
    getCustomerList(pageSize, nextPage);
  }, [search,nextPage,pageSize]);
 */
  /* // Row click redirect
  function onRowClick(e, record) {
    // console.log(e.target);
    // console.log(e.target?.src);
    //  console.log(record);
    if(!e.target?.src) {
      history.push({
      pathname: `/ship/${record._id}`,
      state: record,
    });
    }
  }

  // *** EDIT CUSTOMER FUNCTION *** //
  function editCustomer(record) {
    // console.log(record);
    history.push({
      pathname: `/shipLog/${record._id}`,
      state: record,
    });
  } */

  function handleInput(e) {
    const { value } = e.target;
    if (value.trim() !== "") {
      const updatedFilter = {
        search: e.target?.value || "",
        nextpage: 1,
        filterDays: filterDays,
        sortOrder: sortOrder,
      };
      setSearch(e.target.value);
      debounceSearch(updatedFilter);
    } else if (search) {
      const updatedFilter = {
        search: "",
        nextpage: 1,
        filterDays: filterDays,
        sortOrder: sortOrder,
      };
      setSearch("");
      debounceSearch(updatedFilter);
    }
  }

  function debounceSearch(filter) {
    // console.log(filter);
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      setIsLoading(true);
      setNextPage(1);
      getCustomerList(pageSize, 1, filter, false, true);
      setFilteredResult(filter);
    }, 500);
  }

  function handlepaginationChanges(value, type) {
    //let filter = {};
    let filter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
      sortOrder: sortOrder,
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getCustomerList(value, 1, filter, false, true);
    } else if (type === "nextPage") {
      setNextPage(value);
      getCustomerList(pageSize, value, filter, false, true);
    }
  }
  function filterCustomerChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
      sortOrder: sortOrder,
    };
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    debounceSearch(updatedFilter);
  }

  // *** DATE RANGE ONCHANGE FUNCTION *** //
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
    };
    debounceSearch(updatedFilter);
  };

  /* //TO SHOW TABLE HEADERS BASED ON STATUS
const renderHeader = (props) => {
  return (
    <tr>
      {columns.map((item, idx) => {
        if (item.visible) return props.children[idx];
      })}
    </tr>
  );
};

// * TO SHOW TABLE DATA BASED ON HEADER STATUS
const renderBody = (props) => {
  if (props.children.length > 0) {
    return (
      <tr className={props.className}>
        {columns.map((item, idx) => {
          if (item.visible) {
            return props.children[idx];
          }
        })}
      </tr>
    );
  } else {
    return (
      <tr className="bg-transparent text-info">
        <td colSpan="3">No results found!</td>
      </tr>
    );
  }
}; 

const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0? 2 : oldIndex;
    newIndex = newIndex === 0? 2 : newIndex;
    if(oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOrder(values);
    }
};

const rearrange_array = (arr, oldIndex, newIndex) => {
  if (newIndex >= arr.length) {
    let i = newIndex - arr.length + 1;
    while (i--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

const fetchNewOrder = async (items) => {
  let newStatus = [];
  items.map((item, index) => {
    let obj = {};
    obj.order = index;
    obj.status = item.status;
    obj.value = item.value;
    obj._id = item._id;
    newStatus.push(obj);
  });
  setItems(newStatus);
  set_new_header(newStatus);
};

const on_field_check_handler = (e, id) => {
  const itemClone = [...items];
  let objIndex = items.findIndex((obj) => obj._id === id);
  itemClone[objIndex].status = e.target.checked ? 1 : id === 2? 1 : 0;
  setItems(itemClone);
  fetchNewOrder(items);
};

const menu = (
  <Menu className="columns_list">
    <MovableListComponent
      listProps={{
        values: items,
        onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
        renderList: ({ children, props }) => (
          <ul {...props} style={{ listStyleType: "none" }}>
            {children}
          </ul>
        ),
        renderItem: ({ value, props }) => (
          <li {...props} key={value._id} className="columns_list_item">
            {value.value !== "select" && (
              <>
                <input
                  type="checkbox"
                  checked={value.status === 1 ? true : false}
                  onChange={(event) =>
                    on_field_check_handler(event, value._id)
                  }
                />
                <span class="checkmark" style={{ cursor: "move" }}></span>
                <span>
                  {value.value
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })}
                  <img src={dots} alt="image" />
                </span>
              </>
            )}
          </li>
        ),
      }}
    />
  </Menu>
);
*/
  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder: sortOrder,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
          setFilterDaysName("Date");
        }
        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
        }
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  /* Export here */
  const exportToCSV = async (e) => {
    const filterOptions = {
      search: search,
      selectedRows: selectedOrder,
    };

    if (filteredResult && filteredResult.search) {
      filterOptions.search = filteredResult.search;
    } else if (search !== "") {
      if (filteredResult && filteredResult.search !== "") {
        filterOptions.search = search;
      }
    }
    if (filteredResult && filteredResult.filterDays) {
      filterOptions.filterDays = Number(filteredResult.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filteredResult && filteredResult.filterDays !== "") {
        filterOptions.filterDays = Number(filteredDate.filterDays);
      }
    }

    if (filteredResult && filteredResult.startDate) {
      filterOptions.startDate = filteredResult.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filteredResult && filteredResult.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filteredResult && filteredResult.endDate) {
      filterOptions.endDate = filteredResult.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filteredResult && filteredResult.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }

    //console.log(filterOptions);
    //return
    AxiosInstance(8000, "application/json")
      .post(
        `/customers/getCustomers/download?sortField=firstName&sortValue=${sortOrder}`,
        filterOptions
      )
      .then((resp) => {
        console.log(resp);
        const response = resp && resp.data;
        if (resp && (resp.status === "200" || resp.status === 200)) {
          var blob = new Blob([response], { type: "text/csv;charset=utf-8" });
          var url = URL.createObjectURL(blob);

          // Create a link to download it
          var pom = document.createElement("a");
          pom.href = url;
          pom.setAttribute("download", "Customer.csv");
          pom.click();
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // return [];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter{" "}
                <img alt="" src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                      <span key={filter._id}>
                        <input
                          type="checkbox"
                          checked={filter.isChecked}
                          onChange={(e) => commonFilterHandler(e, filter)}
                        ></input>
                        <label>{filter.name}</label>
                      </span>
                    ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                          <span
                            key={day._id}
                            onClick={() =>
                              filterCustomerChange("filterDays", day)
                            }
                          >
                            <label>{day.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );
  function filterOnChange(e) {
    const { name, value } = e.target;
    console.log(value);
    setfilterCompany(value);
    // setIsLoading(true);
  }
  return (
    <div className={commonstyles.management_contents}>
      <div className={commonstyles.management_header}>
        <div className="row">
          <div className="col-lg-2">
            <h1 className={commonstyles.management_header_heading}>Customer</h1>
          </div>
          <div className={` col-lg-6 ${commonstyles.customer_dropdown} d-flex`}>
          
          </div>
          <div className="col-lg-4 d-flex">
            <div className={commonstyles.export}>
              <span
                className={commonstyles.management_header_downloads_text}
                style={{ cursor: "pointer" }}
                onClick={(e) => exportToCSV(e)}
              >
                <img className={commonstyles.export_icon} src={excel} />
                Export
                <img
                  src={exportIcon}
                  alt="icon"
                  className={commonstyles.export_right_icon}
                  height={"13px"}
                  width={"13px"}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div /* className={styles.administrator} */>
        <div
          className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
        >
          <div
            className={`${commonstyles.management_contents_search_bar} input-group`}
          >
            <input
              type="search"
              className={`${commonstyles.form_control} form-control`}
              aria-label="Search"
              placeholder="Search Customer Name, Email, Mobile and Location"
              aria-describedby="search-addon"
              value={search}
              onChange={handleInput}
            />
            <img src={Search} alt="" />
          </div>

          <Dropdown
            overlay={filterMenu}
            className="usermanage_table_filter_columns ml-3"
          >
            <div className="ant-dropdown-link">
              Filter <img src={arrowDown} width={10} height={20} />
            </div>
          </Dropdown>
          <div className={`  ${commonstyles.dropdown}`}>
              <Menu className={`filter_dropdown datefilter`}>
                <div className="card">
                  <div className="card_contents">
                    <div className="row no-gutters">
                      <div className="card_dropdown show col-md-6">
                        <select
                          name="filterCompany"
                          value={filterCompany}
                          onChange={filterOnChange}
                          className={`form-control  ${commonstyles.form_control} `}
                          style={{ cursor: "pointer" }}
                        >
                          <option value="">ALL</option>
                          {Array.isArray(getCompany)
                            ? getCompany.sort((a, b) => (a.code > b.code ? 1 : -1)).map((company) => (
                                <option
                                  key={company?.code}
                                  value={company?._id}
                                >
                                  {company?.code}
                                </option>
                              ))
                            : null}
                        </select>
                        <img src={arrowDown} className="dropDownArrow" />
                      </div>
                    </div>
                  </div>
                </div>
              </Menu>
            </div>
          {/* <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Columns <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown> */}
        </div>
        <div className={`${commonstyles.management_table} table-responsive`}>
          {!IsLoading && customerList.length > 0 && (
            <input
              type="checkbox"
              className={`${commonstyles.parentCheckbox}`}
              onChange={parentCheckbox}
            ></input>
          )}
          {IsLoading === true ? (
            <div
              style={{
                marginLeft: "70vh",
                marginTop: "20vh",
              }}
            >
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching Customer details..." />
                </td>
              </tr>
            </div>
          ) : (
            <AntTableComponent
              className="order_table"
              tableProps={{
                columns: columns,
                dataSource: customerList,
                pagination: false,
                // onRow: (record, index) => ({
                //   onClick: (e) => onRowClick(e, record)
                // }),
                onChange: onChange,

                /* components: {
                header: {
                  row: (props) => renderHeader(props),
                },
                body: {
                  row: (props) => renderBody(props),
                },
              }, */
              }}
            />
          )}
        </div>
      </div>
      {!IsLoading.list && Array.isArray(customerList) && customerList.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          //handlepaginationChanges={handlepaginationChanges}
          handlepaginationChanges={(value, type) => {
            setFirstTime((prev) => !prev);
            handlepaginationChanges(value, type);
          }}
        />
      ) : null}
    </div>
  );
}

export default CustomerList;
