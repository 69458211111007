import React from "react";
import commonstyles from "../../styles/SettingsTable.module.css";
import deleteIcon from "../../assets/img/delete.svg";
import editIcon from "../../assets/img/edit.svg";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { AxiosInstance } from "../../utilities";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import { Modal, notification, Menu } from "antd";
import "antd/dist/antd.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import arrowDown from "../../assets/images/arrow_down.svg";
import BackArrow from "../../assets/icons/back_arrow.svg";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

function OriginLocationList() {
  const history = useHistory();
  const [regionList, setRegionList] = useState([]);
  // const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setpagination] = useState({});
  const [IsLoading, setIsLoading] = useState(false);
  const company = JSON.parse(localStorage.getItem("companydata"));
  const { confirm } = Modal;
  const [getCompany, setGetCompany] = useState([]);
  const [filterCompany, setfilterCompany] = useState("");
  const [columns, setColumns] = useState([
    {
      title: "Warehouse Name",
      visible: false,
      key: "name",
      render: (text, record, index) => <span>{record?.name ? record?.name : "-"}</span>,
    },
    {
      title: "City",
      visible: false,
      key: "city",
      render: (text, record, index) => <span>{record?.city ? record?.city : "-"}</span>,
    },
    {
      title: "State",
      visible: false,
      key: "state",
      render: (text, record, index) => <span>{record?.state ? record?.state : "-"}</span>,
    },
    {
      title: "Postal Code",
      visible: false,
      key: "postalCode",
      render: (text, record, index) => <span>{record?.postalCode ? record?.postalCode : "-"}</span>,
    },
    {
      title: "Description",
      visible: false,
      key: "displayName",
      render: (text, record, index) => (
        <span>{record?.displayName ? record?.displayName : "-"}</span>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) => (
        <>
          <NavLink to={`/settings/editOriginLocation/${record._id}`}>
          <img
            src={editIcon}
            className={`${commonstyles.admin_table_edit_img} mr-2`}
            onClick={() => editShip(record)}
            alt="Edit Origin Location"
            title="Edit Origin Location"
            style={{ cursor: "pointer" }}
            data-testid="editIcon"
          />
          </NavLink>
          <img
            src={deleteIcon}
            className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
            onClick={() => showDeleteConfirm(record._id)}
            alt="Delete origin location"
            title="Delete origin location"
            data-testid="deleteIcon"
          />
        </>
      ),
    },
  ]);
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }
  useEffect(() => {
    getCompanyDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   getOriginList(pageSize, nextPage, "");
  //   localStorage.removeItem("selectedIds");

  //   console.log(OriginLocationListTestData);
  //   setIsLoading(true);
  //   OriginLocationListTestData?.data.forEach((item, indx) => {
  //     item["key"] = indx + 1;
  //     item["isChecked"] = false;
  //   });
  //   localStorage.setItem(
  //     "regionList",
  //     JSON.stringify(OriginLocationListTestData?.data)
  //   );
  //   setRegionList(OriginLocationListTestData?.data);
  //   setpagination(OriginLocationListTestData?.pagination);
  //   setIsLoading(false);
  // }, [OriginLocationListTestData]);

  useEffect(() => {
   
      getOriginList(pageSize, nextPage, "");
      localStorage.removeItem("selectedIds");
    

    return () => {
      localStorage.removeItem("regionList");
      localStorage.removeItem("selectedIds");
    };
  }, [filterCompany]);

  function getCompanyDetail() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getCompanies/settingsSection?pageNum=1&count=20&sortField=createdAt&sortValue=DSC`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetCompany(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get company details",
        };
        showNotification(error);
        // return er;
      });
  }
  // *** FETCH REGION LIST *** //
  function getOriginList(pageSize, nextPage, filterarr, type) {
    // setIsLoading(false);
    console.log(filterarr);
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };
    // const company = JSON.parse(localStorage.getItem("companydata"))

    AxiosInstance(8000, "application/json")
      .get(
        `companies/getOriginLocations?pageNum=${nextPage}&count=${pageSize}&sortField=createdAt&sortValue=DSC&companyId=${filterCompany}`,
        filterOptions
      )
      .then((res) => {
        // console.log(filterOptions);
        console.log(res, "data");
        if (res && res.data && res.status == "200") {
          console.log(res, "data1");
          if (res.data.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // console.log(res.data)
            localStorage.setItem("regionList", JSON.stringify(res.data.data));
            setRegionList(res.data.data);
            setpagination(res.data.pagination);
          } else {
            setRegionList([]);
            setpagination({});
          }
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }

  // *** EDIT origin FUNCTION *** //
  function addOriginLocation() {
    history.push(`/settings/originLocation/addOriginLocation`);
  }

  // *** EDIT origin FUNCTION *** //
  function editShip(record) {
    // console.log(record);
    history.push({
      pathname: `/settings/editOriginLocation/${record._id}`,
      state: record,
    });
  }

  // DELETE Origin Location
  function showDeleteConfirm(id) {
    // console.log(id);
    confirm({
      title: "Are you sure want to delete this ?",
      icon: <ExclamationCircleOutlined />,
      // content: "Some descriptions",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        deleteOriginLocation(id);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  }

  function deleteOriginLocation(id) {
    // console.log(id);
    setIsLoading(true);
    AxiosInstance(8000, "application/json")
      .delete(`companies/removeOriginLocation/${id}`)
      .then((res) => {
        // console.log(res);
        if (res && res?.data && res?.status == "200") {
          showNotification({
            type: "success",
            message: "Success",
            description: "Origin Location deleted successfully",
          });
          setIsLoading(false);
          getOriginList(pageSize, nextPage, "");
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Failed to delete Origin location",
          });
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function debounceSearch(filter) {
    // console.log(filter);
    setIsLoading(true);
    setNextPage(1);
    getOriginList(pageSize, 1, filter);
  }

  function handlepaginationChanges(value, type) {
    let filter = {};
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getOriginList(value, 1, filter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getOriginList(pageSize, value, filter);
    }
  }
  function filterOnChange(e) {
    const { name, value } = e.target;
    console.log(value);
    setfilterCompany(value);
    // setIsLoading(true);
  }
  return (
    <div className={commonstyles.management_contents}>
      <div className="">
        <div className="row d-flex">
          <div className="back_arrow">
            <img
              src={BackArrow}
              onClick={() => history.push("/settings/vendorAccounts")}
              alt=""
              className="cursor-pointer"
              title="back"
              width={"25px"}
              height={"21px"}
            />
            <h1 className="heading">Origin Location</h1>
          </div>
        </div>
      </div>
      <div className={commonstyles.management_header}>
        <div className="row">
          <div className="col-lg-6">
            <button
              type="button"
              className="btn btn-outline-primary"
              aria-label="originLocationAddButton"
              onClick={addOriginLocation}
              data-testid="add_button"
            >
              Add Origin Location
            </button>
          </div>
          <div className="col-lg-6 d-flex flex-row-reverse">
            <div className={` filter_width ${commonstyles.dropdown}`}>
              <Menu className={`filter_dropdown datefilter`}>
                <div className="card">
                  <div className="card_contents">
                    <div className="row no-gutters">
                      <div className="card_dropdown show col-md-6">
                        <select
                          name="filterCompany"
                          value={filterCompany}
                          onChange={filterOnChange}
                          className={`form-control  ${commonstyles.form_control} `}
                          style={{ cursor: "pointer" }}
                        >
                          <option value="">ALL</option>
                          {Array.isArray(getCompany)
                            ? getCompany
                                .sort((a, b) => (a.code > b.code ? 1 : -1))
                                .map((company) => (
                                  <option
                                    key={company?.code}
                                    value={company?._id}
                                  >
                                    {company?.code}
                                  </option>
                                ))
                            : null}
                        </select>
                        <img src={arrowDown} className="dropDownArrow" />
                      </div>
                    </div>
                  </div>
                </div>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
        >
          {/* <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <input
            type="search"
            className={`${commonstyles.form_control} form-control`}
            aria-label="Search"
            placeholder="Search by State... "
            aria-describedby="search-addon"
            value={search}
            onChange={ handleInput}
          />
          <img src={Search} alt="" />
        </div> */}
        </div>
        <div className={`${commonstyles.management_table} table-responsive`}>
          {/* {!IsLoading && regionList.length > 0 && (
                    <input
                        type="checkbox"
                        className={`${commonstyles.parentCheckbox}`}
                        onChange={parentCheckbox}
                    ></input>
                )} */}
          {IsLoading === true && (
            <div
              style={{
                marginLeft: "70vh",
                marginTop: "10vh",
              }}
            >
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching Origin Location details..." />
                </td>
              </tr>
            </div>
          )}
          {!IsLoading && regionList.length > 0 && (
            <AntTableComponent
              className="order_table"
              tableProps={{
                columns: columns,
                dataSource: regionList,
                pagination: false,
              }}
            />
          )}
          {!IsLoading && regionList.length === 0 && (
            <AntTableComponent
              className="order_table"
              tableProps={{
                columns: columns,
                dataSource: regionList,
                pagination: false,
              }}
            />
          )}
        </div>
      </div>
      {!IsLoading.list && Array.isArray(regionList) && regionList.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          handlepaginationChanges={handlepaginationChanges}
        />
      ) : null}
    </div>
  );
}

export default OriginLocationList;
