import React, { useState, useEffect } from "react";
import styles from "../../styles/Warehouse.module.css";
import "./LoginComponent.css";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import Logo from "../../assets/img/hgrlogo.png";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification } from "antd";
import FormErrorText from "../FormErrorText";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import validationRules from "../../validationRules";
import { isObject, isString, isEqual } from "lodash";
import { Link, useParams } from "react-router-dom";
import SelectComponent from "../ReusableComponent/SelectComponent";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import OtpInput from "react-otp-input-rc-17";
import eyeOpen from "../../assets/icons/eye_icon.svg";
import eyeClose from "../../assets/icons/eye_close.svg";
import { passwordReg, emailRegx, otpRegx } from "../RegularExp/RegularExp";
import BackGroundImage from "../../assets/img/login_banner_img.png";

export default function OtpVerification() {
  const history = useHistory();
  const { id } = useParams();
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [enable, setEnable] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwd2, setIsRevealPwd2] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  useEffect(() => {
    setIsLoading(false);
  }, []);

  //   const { email } = history.location;
  const email = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : null;
  //   let isReload=window.performance.getEntriesByType("navigation")[0].type;
  // console.log(isReload);
  //   console.log(email);
  if (email === undefined || email === "" || email == "" || email == null) {
    showNotification({
      type: "error",
      message: "Error",
      description: "Email id not found",
    });
    history.push(`/login`);
  }

  function onChangeOtp(otp) {
    var otpErr = "";
    if (otp.trim() === "") {
      otpErr = "";
    } else if (!otpRegx.test(otp)) {
      otpErr = "Please enter a 6-digit pin";
    } else {
      otpErr = "";
    }
    setOtp(otp);
    setOtpError(otpErr);
  }

  const onVerifyOtp = (e) => {
    e.preventDefault();
    let otpErr = "";
    if (otp.trim() === "") {
      otpErr = "Please enter a otp";
    } else if (!otpRegx.test(otp.trim())) {
      otpErr = "Please enter a 6-digit pin";
    } else {
      otpErr = "";
    }
    setOtpError(otpErr);

    if (
      otpError.trim() === "" &&
      otp.trim() !== "" &&
      email !== undefined &&
      email !== ""
    ) {
      let data = {
        email: email,
        otp: otp,
      };
      //   history.push(`/forgot`);
      submitResetPasswordWithOtp(data);
    }
  };

  function submitResetPasswordWithOtp(data) {
    setLoading({ ...loading, update: true });

    AxiosInstance(8000, "application/json")
      .post(`/users/verifyOtp`, data)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setEnable(true);
          showNotification({
            type: "success",
            message: "Success",
            description: "OTP verified successfully",
          });
          localStorage.setItem("otp", otp);
          history.push(`/forgot`);
        } else if (res.status === 400) {
          showNotification({
            type: "error",
            message: "Wrong OTP or User not found",
            description: "Error occured" || res.message,
          });
        }
      })
      .catch((error) => {
        showNotification({
          type: "error",
          message: "Error occured",
          description: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className="login_bg row m-0">
      <div>
        {isLoading === true ? (
          <tr className="bg-transparent d-flex justify-content-center">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching Fogot password..." />
            </td>
          </tr>
        ) : (
          <div className="row">
            <div className="col-lg-4 ">
              <div className="login">
                <div className="logo_img">
                  <img src={Logo} alt="logo" />
                </div>

                <h1 className="title_text">Verify OTP</h1>
                <p className="welcome_text">Enter 6 digit OTP</p>
                <div className="">
                  <OtpInput
                    className={`otp_input_form otp_input_field otp_input_field_admin`}
                    numInputs={6}
                    value={otp}
                    onChange={onChangeOtp}
                    separator={<span>-</span>}
                  />
                  <div className="text-center">
                    {otpError && otpError.trim() !== "" ? (
                      <span
                        className="theme-txt-sm theme-error-txt text-center px-1 mr-1"
                        data-test="otp_error"
                      >
                        {otpError}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    onClick={onVerifyOtp}
                    className="btn_login verify_btn"
                  >
                    Verify
                  </button>
                  <div className={`${styles.login_otp_text} text-right`}>
                    <p>
                      back to{" "}
                      <a
                        onClick={() => history.goBack()}
                        className="back_toLogin_color"
                      >
                        login
                      </a>
                    </p>
                  </div>
                  <div className="privacy_policy">
                    Terms & condition | Privacy Policy
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="login_banner_img">
                <img src={BackGroundImage} alt="" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
