import React, { useState, useEffect } from "react";
import styles from "../../styles/Warehouse.module.css";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification } from "antd";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import FormErrorText from "../FormErrorText";
import {
  onlyNumbers,
  onlyAlphabetsWithSpace,
  numbersAlphabetsWithoutSpace
} from "../RegularExp/RegularExp";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import SelectComponent from "../ReusableComponent/SelectComponent";
import validationRules from "../../validationRules";
import { isObject, isString } from "lodash";
import {  useParams } from "react-router-dom";

const initialFormState = {
  logisticCode: "",
  serviceMethod: "",
  serviceName: "",
  serviceCode: "",
};

export default function AddCarriersDetails() {
  const history = useHistory();
  const { id } = useParams();
  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [logistic, setLogistic] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const company = JSON.parse(localStorage.getItem("companydata"));

  const rules = {
    logisticCode: [validationRules.required],
    serviceMethod: [validationRules.required],
    serviceName: [validationRules.required],
    serviceCode: [validationRules.required],
  };

  useEffect(() => {
    getLogistics();
    if (id) {
      getCarriersDetails(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  function getLogistics() {
    AxiosInstance(8000, "application/json")
      .get("logistics/getLogistics")
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setLogistic(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      });
  }
  function getCarriersDetails(id) {
    //  console.log(id);
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`logistics/getCarrier/${id}`)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          const val = res.data;
          // console.log(val)
          setEditForm(val);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  // ***FORM INPUT HANDLE CHANGES *** //
  function handleFormInput(e) {
    let { name, value } = e.target;
    // console.log(name, value);
    if (name === "logisticCode") {
      if (!value && value === "") {
        formErrors.logisticCode = "Required";
      } else {
        formErrors.logisticCode = "";
      }
    }
    if (name === "serviceName") {
        value = value.charAt(0).toUpperCase() + value.slice(1);
        if (!e.target.value.match(onlyAlphabetsWithSpace) || !e.target.value === "") {
          setFormErrors({
            ...formErrors,
            [name]: "Please Enter a Carrier Name",
          });
        } else {
          setFormErrors({ ...formErrors, [name]: "" });
        }
      }
  
      if (name === "serviceMethod") {
        value = value.trim()
        value = value.charAt(0).toUpperCase() + value.slice(1);
        if (!e.target.value.match(numbersAlphabetsWithoutSpace) || !e.target.value === "") {
          setFormErrors({
            ...formErrors,
            [name]: "Please Enter a Carrier Method",
          });
        } else {
          setFormErrors({ ...formErrors, [name]: "" });
        }
      }
      if (name === "serviceCode") {
        value = value.trim()
        value = value.charAt(0).toUpperCase() + value.slice(1);
        if (!value && value === "") {
          formErrors.serviceCode = "Required";
        } else if (
          !numbersAlphabetsWithoutSpace.test(value.trim()) &&
          value.trim() !== ""
        ) {
          formErrors.serviceCode = "Please enter a valid Code";
        }
        else {
          formErrors.serviceCode = "";
        }
      }

    const updatedValues = { ...editForm };
    updatedValues[name] = value;
    setEditForm(updatedValues);
  }

  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    data = data || {};
    const result = {};

    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation here
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  function submit(e) {
    // let data = JSON.parse(JSON.stringify(editForm));
    // console.log(data);
    // console.log(editForm);
    let data = {
        logisticCode: editForm?.logisticCode,
        serviceName: editForm?.serviceName,
        serviceMethod: editForm?.serviceMethod,
        serviceCode: editForm?.serviceCode,

    }
    let data1 = [{
        logisticCode: editForm?.logisticCode,
        serviceName: editForm?.serviceName,
        serviceMethod: editForm?.serviceMethod,
        serviceCode: editForm?.serviceCode,
    }]
    const result = validate(undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }

    if (data.serviceName) {
      if (!onlyAlphabetsWithSpace.test(data.serviceName) || !data.serviceName === "") {
        setFormErrors({
          ...formErrors,
          serviceName: "Please Enter Alphabet only",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, serviceName: "" });
      }
    }
    if (data.serviceMethod) {
        if (!numbersAlphabetsWithoutSpace.test(data.serviceMethod) || !data.serviceName === "") {
          setFormErrors({
            ...formErrors,
            serviceMethod: "Please Enter Alphabet only",
          });
          return false;
        } else {
          setFormErrors({ ...formErrors, serviceMethod: "" });
        }
      }
      if (data.serviceCode) {
        if (
          !numbersAlphabetsWithoutSpace.test(data.serviceCode) ||
          !data.serviceCode === ""
        ) {
          setFormErrors({
            ...formErrors,
            serviceCode: "Please Enter Number only",
          });
          return false;
        } else {
          setFormErrors({ ...formErrors, serviceCode: "" });
        }
      }
    setLoading({ ...loading, update: true });

    // console.log(result);
    if (id) {
      AxiosInstance(8000, "application/json")
        .put(`logistics/updateCarrier/${id}`, data)
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Carrier updated successfully",
            });
            history.push(`/settings/carriers`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding Carrier failed",
              description: "Error occured" || res.message,
            });
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Carrier failed",
            description: "Error occured",
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    } else {
      AxiosInstance(8000, "application/json")
        .post(`logistics/addCarriersToLogistic/${editForm?.logisticCode}`, data1)
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Carrier added successfully",
            });

            history.push(`/settings/carriers`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding logistics failed",
              description: "Error occured" || res.message,
            });
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Logistics failed",
            description: "Error occured",
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt="Back Arrow"
            className="cursor-pointer"
            onClick={() => history.push("/settings/carriers")}
          />
          {id ? "Edit Carriers" : "Add Carriers"}
        </h1>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching logistics detail..." />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>
          <div className={styles.management_details_contents}>
            <div className="row">
            <div className="col-lg-6 mb-4">
                <label>
                  Logistics Name
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.logisticCode}
                  className="form-control-md mb-3"
                  attributes={{
                    name: "logisticCode",
                    value: editForm.logisticCode,
                    onChange: (e) => {
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                    Select option
                  </option>
                  {Array.isArray(logistic)
                    ? logistic.map((logi) => (
                        <option key={logi._id} value={logi.code}>
                          {logi.code}
                        </option>
                      ))
                    : null}
                </SelectComponent>
                <div
                 
                >
                  <FormErrorText error={formErrors.logisticCode} />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="theme-form-label">
                  Carrier Name
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors?.serviceName}
                  formControl={{
                    placeholder: "Type the Carrier Name",
                    name: "serviceName",
                    value: editForm?.serviceName,
                    onChange: handleFormInput,
                  }}
                />
                <div
                 
                >
                  <FormErrorText error={formErrors.serviceName} />
                </div>
              </div>
          
           
            </div>
            <div className="row">
            <div className="col-lg-6 mb-4">
                <label className="theme-form-label">
                  Carrier Method
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors?.serviceMethod}
                  formControl={{
                    placeholder: "Type the Carrier Method",
                    name: "serviceMethod",
                    value: editForm?.serviceMethod,
                    onChange: handleFormInput,
                  }}
                />
                <div
                 
                >
                  <FormErrorText error={formErrors.serviceMethod} />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="theme-form-label">
                 Code
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors?.serviceCode}
                  formControl={{
                    placeholder: "Type the code",
                    name: "serviceCode",
                    value: editForm?.serviceCode,
                    onChange: handleFormInput,
                  }}
                />
                <div
                 
                >
                  <FormErrorText error={formErrors.serviceCode} />
                </div>
              </div>
            </div>
            </div>
        </div>
      )}
      <div className={styles.add_page_save_button}>
        <button type="button" className="btn btn-outline-primary" onClick={submit}>
          Save
        </button>
      </div>
    </div>
   
  );
}
