import React, { useState, useEffect, NavLink } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import BackArrow from "../../assets/icons/back_arrow.svg";
import Img from "../../assets/img/img-about.png";
// import "./QuoteDetail.css";
import { AxiosInstance } from "../../utilities";
import moment from "moment";
import arrowDown from "../../assets/images/arrow_down.svg";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import arrowUp from "../../assets/img/arrow_Up.svg";

const QuoteDetail = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const path = props.location.pathname.split("/")[3];
  const [dataSource, setDataSource] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState({ details: false, update: false });
  //const [isReadMore, setIsReadMore] = useState(true);
  const [orderId, setOrderId] = useState("");
  const toggleReadMore = (oId) => {
    if (oId === orderId) {
      setOrderId("");
    } else {
      setOrderId(oId);
    }
  };

  useEffect(() => {
    if (id) {
      getQuotedDetails(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  const getQuotedDetails = (id) => {
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      // .get(`/customers/getCustomer/${id}`)
      .get(`/customers/getCustomer/${id}`)
      .then((resp) => {
        const response = resp && resp;
        if (response && response.status === 200) {
          let data = response.data;
          setDataSource(data);

          setIsLoading(false);
        }
        if (response && response.status === 400) {
          setDataSource();
          setIsLoading(false);
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  };

  return (
    <section className="quote-details">
      <div className="">
        <div className="row d-flex">
          <div className="back_arrow">
            <img
              src={BackArrow}
              onClick={() => history.push("/customer")}
              alt=""
              className="cursor-pointer"
              title="back"
              width={"25px"}
              height={"21px"}
            />
            <h1 className="heading">Customer Details</h1>
          </div>
        </div>
      </div>
      {IsLoading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "40vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching Customer details..." />
            </td>
          </tr>
        </div>
      ) : (
        <>
          <div className="customer-details-bg-wrapper-customer">
            <div className="row bottom-border-line">
              <div className=" details" style={{width: "104px"}}>
                <p className="customer-primary-sub">User Name : </p>

                <p className="customer-primary-sub">Email : </p>

                <p className="customer-primary-sub">Phone : </p>

                <p className="customer-primary-sub">Address : </p>

                <p className="customer-primary-sub">Site : </p>
              </div>
              <div className="user_details" style={{width: "400px", padding: "0px"}}>
                <p className="customer-primary-sub">
                  {dataSource?.firstName
                    ? dataSource?.firstName +
                      " " +
                      (dataSource?.lastName ? dataSource?.lastName : "")
                    : "-"}
                </p>

                <p className="customer-primary-sub">
                  {dataSource?.email ? dataSource?.email : "-"}
                </p>

                <p className="customer-primary-sub">
                  {dataSource?.phone ? dataSource?.phone : "-"}
                </p>

                <p className="customer-primary-sub">
                  {dataSource?.address1
                    ? (dataSource?.address1 ? dataSource?.address1 + ", " : "") +
                      (dataSource?.address2 ? dataSource?.address2 + ", " : "") +
                      
                      (dataSource?.city ? dataSource?.city + ", " : "") +
                      
                      (dataSource?.state ? dataSource?.state + ", " : "") +
                     
                      (dataSource?.country ? dataSource?.country + " " : "-") +
                      
                      (dataSource?.postalCode ? dataSource?.postalCode  : "-") +
                      "."
                    : "-"}
                </p>
                <p className="customer-primary-sub">
                  {dataSource?.company?.name ? dataSource?.company?.name : "-"}
                </p>
              </div>
            </div>

            <div className="total-cost">
              <div className="">
                <h4 className="heading-main">Total Cost</h4>

                <div className="total-cost-container">
                  <div className="row d-flex">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 total-cost-colum-width right-border-line">
                      <h4 className="customer-primary-sub-total">
                        Freight Charge 
                      </h4>
                      <h2 className="heading-main-total">
                        {dataSource?.totalShippingPrice ? '$'+(dataSource?.totalShippingPrice?.toFixed(2)) : "-"}{" "}
                      </h2>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 total-cost-colum-width right-border-line">
                      <h4 className="customer-primary-sub-total ">
                        Handling Charge
                      </h4>
                      <h2 className="heading-main-total">
                        {dataSource?.totalHandleCharge ? '$'+dataSource?.totalHandleCharge?.toFixed(2) : "-"}{" "}
                      </h2>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 total-cost-colum-width">
                      <h4 className="customer-primary-sub-total">
                        Total Charge
                      </h4>
                      <h2 className="heading-main-total">
                        {dataSource?.totalCharge ? '$'+dataSource?.totalCharge?.toFixed(2) : "-"}{" "}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="customer-details">
            <div className="row">
              <div className="back_arrow">
                <h1 className="heading">Order History</h1>
              </div>
            </div>
            <div className="customer-details-bg-wrapper-customer-order-history">
              {dataSource?.orders &&
                dataSource?.orders.length > 0 &&
                dataSource?.orders.map((item) => (
                  <>
                    <div className="customer-details-wrapper">
                      <div className="row d-flex">
                        <h4 className="heading-main-inline-block">Order Id</h4>
                        <p className="customer-primary-customer link-page"
                        
                          onClick={() =>
                            history.push(
                              `/ship/${item?._id}`
                            )
                          }
                          >
                         
                           #{item?.orderId}
                         
                       
                         
                        </p>
                        <p className="customer-primary-sub-customer d-flex">
                          {moment(item?.createdAt).format("MM/DD/YYYY")} | {moment(item?.createdAt).format("HH:mm:ss")}
                        </p>
                        <p className="customer-primary-sub-customer-toggle">
                          {orderId === item._id ? (
                            <img
                              src={arrowUp}
                              alt="arrow"
                              style={{ cursor: "pointer" }}
                              height={"10px"}
                              width={"10px"}
                              onClick={() => toggleReadMore(item?._id)}
                            />
                          ) : (
                            <img
                              src={arrowDown}
                              alt="arrow"
                              style={{ cursor: "pointer" }}
                              height={"10px"}
                              width={"10px"}
                              onClick={() => toggleReadMore(item?._id)}
                            />
                          )}
                        </p>
                      </div>

                      {orderId === item._id &&
                        item?.productsWithDetails &&
                        item?.productsWithDetails.length > 0 &&
                        item?.productsWithDetails.map((data) => (
                          <div className="row d-flex justify-content-between">
                            <div className="shipping-address-wrapper-image">
                              {data?.length > 0 && (
                                <img
                                  className="img-about"
                                  alt="productImg"
                                  src={data?.image ? data?.image : Img }
                                ></img>
                              )}
                                   {/* {data?.length === 0 && (
                                <img
                                  className="img-about"
                                  alt="dummyImg"
                                  src={Img}
                                ></img>
                              )} */}

                              <div className="ml-10">
                                <p className="customer-primary-sub-machine-name">
                                  {data.name ? data.name : "-"}
                                </p>
                                <p className="customer-primary-sub">
                                  Quantity: {data?.quantity ? data?.quantity : "-"}{" "}
                                </p>
                                <p className="customer-primary-sub">
                                  ${data?.totalCharge ? data?.totalCharge?.toLocaleString() : "-"}{" "}
                                </p>
                              </div>
                            </div>

                            <div className="shipping-address-wrapper">
                              <h4 className="heading-main-customer-main">
                                Warehouse Venue
                              </h4>

                              <p className="customer-primary-sub">
                                {data?.wareHouseLoc?.billingLocation?.name
                                  ? data?.wareHouseLoc?.billingLocation?.name
                                  : ""}
                              </p>
                              <p className="customer-primary-sub">
                                {data?.wareHouseLoc?.billingLocation?.street1
                                  ? data?.wareHouseLoc?.billingLocation?.street1 + ","
                                  : ""} <br></br>
                                {data?.wareHouseLoc?.billingLocation?.street2
                                  ? data?.wareHouseLoc?.billingLocation?.street2 + ","
                                  : ""} <br></br>
                                {data?.wareHouseLoc?.billingLocation?.city
                                  ? data?.wareHouseLoc?.billingLocation?.city + ","
                                  : ""} <br></br>
                                {data?.wareHouseLoc?.billingLocation?.state
                                  ? data?.wareHouseLoc?.billingLocation?.state + ","
                                  : ""} <br></br>
                                {data?.wareHouseLoc?.billingLocation?.country
                                  ? data?.wareHouseLoc?.billingLocation?.country + ","
                                  : ""} <br></br>
                                {data?.wareHouseLoc?.billingLocation?.postalCode
                                  ? data?.wareHouseLoc?.billingLocation 
                                      ?.postalCode 
                                  : ""}
                                .
                              </p>
                            </div>

                            <div className="shipping-address-wrapper">
                              <h4 className="heading-main-customer-main">
                                Shipping Address
                              </h4>
                              <p className="customer-primary-sub">
                                {item?.reqBody.customerDetails
                                  .shipping_first_name
                                  ? item?.reqBody.customerDetails
                                      .shipping_first_name
                                  : ""} 
                                {item?.reqBody.customerDetails
                                  .shipping_last_name
                                  ? item?.reqBody.customerDetails
                                      .shipping_last_name
                                  : ""}
                              </p>
                              <p className="customer-primary-sub">
                                {item?.reqBody.customerDetails.shipping_address1
                                  ? item?.reqBody.customerDetails
                                      .shipping_address1 + ","
                                  : ""} <br></br>
                                {item?.reqBody.customerDetails.shipping_address2
                                  ? item?.reqBody.customerDetails
                                      .shipping_address2 + ","
                                  : ""} <br></br>
                                {item?.destination?.city
                                  ? item?.destination?.city + ","
                                  : ""} <br></br>
                                {item?.destination?.state
                                  ? item?.destination?.state + ","
                                  : ""} <br></br>
                                {item?.destination?.country
                                  ? item?.destination?.country + ","
                                  : "-"} <br></br>
                                {item?.destination?.postalCode
                                  ? item?.destination?.postalCode
                                  : "-"}
                                .
                              </p>
                            </div>
                            <div className="shipping-address-wrapper">
                              <div className=" customer-primary-sub-gree">
                                <h6
                                  style={
                                    item?.success === true
                                      ? {
                                          color: "green",
                                          backgroundColor: "#00800035",
                                          padding: "5px 20px",
                                          borderRadius: "16px",
                                          width: "120px",
                                        }
                                      : {
                                          color: "red",
                                          background: "#ff000045",
                                          padding: "5px 20px",
                                          borderRadius: "16px",
                                          width: "100px",
                                        }
                                  }
                                >
                                  {item?.success === true
                                    ? "Completed"
                                    : "Pending"}
                                </h6>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                ))}
            </div>
          </div>
        </>
      )}
    </section>
  );
};
export default QuoteDetail;
