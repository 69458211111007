import React, { useState, useEffect, NavLink } from "react";

const WelcomeComponent = (props) => {
 
  return (
    <section className="quote-details">
      <h3>Hello, This is HGR shipping UI</h3>
    </section>
  );
};
export default WelcomeComponent;
