import React from "react";
import commonstyles from "../../styles/Management_Common.module.css";
import { useState, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { notification } from "antd";
import "../QuoteManagementComponent/QuoteDetail.css";
import { AxiosInstance } from "../../utilities";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import { Link, useParams } from "react-router-dom";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import moment from "moment";
import arrowDown from "../../assets/images/arrow_down.svg";
import arrowUp from "../../assets/img/arrow_Up.svg";
import tickIcon from "../../assets/images/tickIcon.svg";
import eyeView from "../../assets/img/action_view.svg";
import Img from "../../assets/img/img-about.png";

const ViewZone = ({ Detail, setDetails }) => {
  const { id } = useParams();
  const history = useHistory();
  const [editState, setEditState] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState({ details: false, update: false });
  const [enquiryDetail, setEnquiryDetail] = useState([]);
  const [productId, setProductId] = useState("");
  const toggleReadMore = (pId) => {
    if (pId === productId) {
      setProductId("");
    } else {
      setProductId(pId);
    }
  };

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  useEffect(() => {
    if (id) {
      getEnquiryDetails(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  function getEnquiryDetails(id) {
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`/shipments/getShipment/${id}`)
      .then((res) => {
        console.log(res.data);
        if (res && res.data && res.status == "200") {
          const val = res.data;
          setEnquiryDetail(val);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldn't fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  return (
    <section className="quote-details">
      <div className="container">
        <div className="row d-flex">
          <div className="back_arrow">
            <img
              src={BlackLeftArrow}
              onClick={() => history.push("/ship")}
              alt=""
              className="cursor-pointer"
              title="back"
            />
            <h1 className="heading"> Shipment Details</h1>
          </div>
        </div>
        <>
          {isLoading === true ? (
            <div
              style={{
                marginLeft: "70vh",
                marginTop: "40vh",
              }}
            >
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching shipping view details..." />
                </td>
              </tr>
            </div>
          ) : (
            <div>
              <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6 ">
                  <div className="row">
                    <div className="col-lg-5 "></div>
                    <div className="col-lg-7 d-flex">
                      <h4 className="heading_sub">
                        {" "}
                        Order ID :{" "}
                        <span className="customer-primary">
                          {enquiryDetail?.orderId
                            ? enquiryDetail?.orderId
                            : "-"}
                        </span>
                      </h4>
                      <div className=" eye_icon ">
                        <NavLink to={`/shipLog/${id}`}>
                          <img
                            src={eyeView}
                            alt="View quotes details"
                            title="View quotes details"
                            style={{ cursor: "pointer" }}
                          />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="customer-details-bg-wrapper">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                    <h4 className="heading-main">Customer Details</h4>
                    <div className="customerDetails-boder-line">
                      <p className="customer-primary">Customer Name</p>
                      <p
                        className="nav_link"
                        onClick={() =>
                          history.push(
                            `/customer/${enquiryDetail?.customer?._id}`
                          )
                        }
                      >
                        {enquiryDetail?.customer?.firstName
                          ? enquiryDetail?.customer?.firstName
                          : "-"}{" "}
                        {enquiryDetail?.customer?.lastName}
                      </p>
                      <p className="customer-primary">Customer Phone</p>
                      <p className="customer-primary-sub">
                        {enquiryDetail?.customer?.phone
                          ? enquiryDetail?.customer?.phone
                          : "-"}
                      </p>
                      <p className="customer-primary">Customer Email</p>
                      <p className="customer-primary-sub">
                        {enquiryDetail?.customer?.email
                          ? enquiryDetail?.customer?.email
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-4 col-sm-4 col-xs-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <h4 className="heading-main">Quote Details</h4>
                        <p className="customer-primary">Quote Date</p>
                        <p className="customer-primary-sub">
                          {" "}
                          {moment(enquiryDetail?.createdAt).format(
                            "MMM DD, YYYY hh:mm:ss A"
                          )}
                        </p>
                        <p className="customer-primary">Destination</p>
                        <p className="customer-primary-sub">
                          {
                            enquiryDetail?.reqBody?.customerDetails
                              ?.shipping_address1
                          }{" "}
                          {
                            enquiryDetail?.reqBody?.customerDetails
                              ?.shipping_address2
                          }{" "}
                          {
                            enquiryDetail?.reqBody?.customerDetails
                              ?.shipping_city
                          }{" "}
                          {
                            enquiryDetail?.reqBody?.customerDetails
                              ?.shipping_state
                          }
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <h4 className="heading-main"></h4>
                        <p className="customer-primary">Record ID </p>
                        <p
                          className="customer-primary-sub"
                          onClick={() =>
                            history.push(
                              `/quotes/getQuote/${enquiryDetail?.reqBody?.recordID}`
                            )
                          }
                        >
                          <NavLink
                            className="nav_link"
                            to={`/quotes/getQuote/${enquiryDetail?.reqBody?.recordID}`}
                          >
                            {enquiryDetail?.reqBody?.recordID
                              ? enquiryDetail?.reqBody?.recordID
                              : "-"}
                          </NavLink>
                        </p>
                        <p className="customer-primary">Order Status</p>
                        <div className="customer-primary-sub-green">
                          <h6
                            style={
                              enquiryDetail?.success === true
                                ? {
                                    color: "green",
                                    backgroundColor: "#00800035",
                                    padding: "5px 20px",
                                    borderRadius: "16px",
                                    width: "120px",
                                  }
                                : {
                                    color: "red",
                                    background: "#ff000045",
                                    padding: "5px 20px",
                                    borderRadius: "16px",
                                    width: "100px",
                                  }
                            }
                          >
                            {enquiryDetail?.success === true
                              ? "Completed"
                              : "Pending"}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="customer-details">
                <div className="row">
                  <div className="back_arrow">
                    <h1 className="heading_details">Product Details</h1>
                  </div>
                </div>
                <div className="customer-details-bg-wrapper-customer-order-history">
                  {enquiryDetail?.productsWithDetails &&
                    enquiryDetail?.productsWithDetails.length > 0 &&
                    enquiryDetail?.productsWithDetails.map((item) => (
                      <>
                        <div className="customer-details-wrapper">
                          <div className="row d-flex">
                            {item?.image ? (
                              item?.length > 0 && (
                                <img
                                  className="img_about2"
                                  alt="productImg"
                                  src={item?.image}    
                                ></img>
                              )
                            ) : (
                              <img
                                className="img_about2"
                                alt="dummyImg"
                                src={Img}
                              ></img>
                            )}

                            <div className="hide2">
                              {item?.image ? (
                                <img alt="" src={item?.image} />
                              ) : (
                                ""
                              )}
                            </div>

                            <h4 className="customer-primary-sub-quote">
                              Inventory Number -
                            </h4>
                            <p
                              className="customer-primary-sub-quote"
                              style={{
                                color: "#1890ff",
                                textDecoration: "underline",
                              }}
                            >
                              <a
                                href={`https://hgrinc.com/productDetail/h/gr/${item?.productCode?.replaceAll(
                                  "-",
                                  ""
                                )}`}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                {item?.productCode
                                  ? "#" + item?.productCode
                                  : "-"}
                              </a>
                            </p>
                            <p className="customer-primary-sub-quote">
                              Weight - {item?.weight ? item?.weight : "-"}
                            </p>
                            <p className="customer-primary-sub-quote">
                              L x W x H - {item?.length ? item?.length : "-"} x{" "}
                              {item?.width} x {item?.height}
                            </p>
                            <p className="customer-primary-sub-quote">
                              Quantity - {item?.quantity ? item?.quantity : "-"}
                            </p>
                            <p className="customer-primary-sub-quote-toggle">
                              {productId === item.productCode ? (
                                <img
                                  src={arrowUp}
                                  alt="arrow"
                                  style={{ cursor: "pointer" }}
                                  height={"10px"}
                                  width={"10px"}
                                  onClick={() =>
                                    toggleReadMore(item?.productCode)
                                  }
                                />
                              ) : (
                                <img
                                  src={arrowDown}
                                  alt="arrow"
                                  style={{ cursor: "pointer" }}
                                  height={"10px"}
                                  width={"10px"}
                                  onClick={() =>
                                    toggleReadMore(item?.productCode)
                                  }
                                />
                              )}
                            </p>
                          </div>

                          {productId === item.productCode && (
                            <div className="product_details-container">
                              <div className="back_arrow">
                                <h1 className="heading-detail">Carriers</h1>
                              </div>
                              <table className="table table-borderless">
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      {enquiryDetail?.shipmentType === "PARCEL"
                                        ? "Shipping Method"
                                        : "Carrier"}{" "}
                                    </th>
                                    <th scope="col">Shipping Cost</th>
                                    <th scope="col">Handling Fee</th>
                                    <th scope="col">Total Quote</th>
                                    <th scope="col">Customer Selected</th>
                                  </tr>
                                </thead>

                                {item?.carriers &&
                                  item?.carriers.length > 0 &&
                                  item?.carriers.map((data) => (
                                    <tbody>
                                      <tr>
                                        <td>
                                          {data?.service_name
                                            ? data?.service_name
                                            : "-"}
                                        </td>
                                        <td>
                                          {data?.price
                                            ? "$" + data?.price.toLocaleString()
                                            : "-"}
                                        </td>
                                        <td>
                                          {data?.handlingCharge
                                            ? "$" +
                                              data?.handlingCharge.toLocaleString()
                                            : "-"}
                                        </td>
                                        <td>
                                          {data?.totalCharge
                                            ? "$" +
                                              data?.totalCharge.toLocaleString()
                                            : "-"}
                                        </td>
                                        <td>
                                          {data?.userSelected === true ? (
                                            <img
                                              alt=""
                                              src={tickIcon}
                                              width={30}
                                              height={30}
                                            />
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))}
                              </table>

                              <div className="back_arrow">
                                <h1 className="heading-detail">
                                  Shipping Details
                                </h1>
                              </div>
                              <table className="table table-borderless">
                                <thead>
                                  <tr>
                                    <th scope="col">Shipping box(L x W x H)</th>
                                    <th scope="col">Total Items</th>
                                    <th scope="col">Tracking Number</th>
                                  </tr>
                                </thead>

                                {item?.orderDetail &&
                                  item?.orderDetail.length > 0 &&
                                  item?.orderDetail.map((data) => (
                                    <tbody>
                                      <tr>
                                        <td>
                                          {data?.matchedBox
                                            ? data?.matchedBox?.length +
                                              " x " +
                                              data?.matchedBox?.height +
                                              " x " +
                                              data?.matchedBox?.width
                                            : "-"}
                                        </td>
                                        <td>
                                          {data?.matchedBox
                                            ? data?.matchedBox?.totalItems
                                            : "-"}
                                        </td>
                                        <td>
                                          <a
                                            href={`https://www.ups.com/track?trackNums=${data?.TrackingNumber}&requester=ST/trackdetails`}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                            className="nav-link"
                                          >
                                            {data?.TrackingNumber
                                              ? data?.TrackingNumber
                                              : "-"}
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))}
                              </table>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    </section>
  );
};

export default ViewZone;
