import React, { useState, useEffect } from "react";
import styles from "../../styles/Warehouse.module.css";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification } from "antd";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import FormErrorText from "../FormErrorText";
import {
  onlyAlphabetsWithoutSpace,
  onlyAlphabetsWithSpace,
  onlyUppercaseAlphabetsWithoutSpace
 
} from "../RegularExp/RegularExp";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import validationRules from "../../validationRules";
import { isObject, isString, isEqual } from "lodash";
import { Link, useParams } from "react-router-dom";
import eyeOpen from "../../assets/icons/eye_icon.svg";
import eyeClose from "../../assets/icons/eye_close.svg";

const initialFormState = {
  name: "",
  code: "",
};

export default function AddLogisticDetails() {
  const history = useHistory();
  const { id } = useParams();
  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);

  const rules = {
    name: [validationRules.required],
    code: [validationRules.required],
  };

  useEffect(() => {
    if (id) {
      getLogisticsDetails(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  function getLogisticsDetails(id) {
    //  console.log(id);
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`logistics/getLogistic/${id}`)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          const val = res.data;
          // console.log(val)
          setEditForm(val);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  // ***FORM INPUT HANDLE CHANGES *** //
  function handleFormInput(e) {
    let { name, value } = e.target;
    // console.log(name, value);
    if (name === "name") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(onlyAlphabetsWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 3 and maximum 30)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "code") {
     value = value.toLocaleUpperCase()
      if (!e.target.value.match(onlyUppercaseAlphabetsWithoutSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 3 and maximum 6)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }

    const updatedValues = { ...editForm };
    updatedValues[name] = value;
    setEditForm(updatedValues);
  }

  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    data = data || {};
    const result = {};

    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation here
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  function submit(e) {
    let data = JSON.parse(JSON.stringify(editForm));
    // console.log(data);
    // console.log(editForm);

    const result = validate(undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }

    if (data.name) {
      if (!onlyAlphabetsWithSpace.test(data.name) || !data.name === "") {
        setFormErrors({
          ...formErrors,
          name: "Enter only alphabets (minimum 3 and maximum 30)",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, name: "" });
      }
    }

    if (data.code) {
      if (!onlyUppercaseAlphabetsWithoutSpace.test(data.code) || !data.code === "") {
        setFormErrors({
          ...formErrors,
          code: "Enter only alphabets (minimum 3 and maximum 6)",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, code: "" });
      }
    }
    setLoading({ ...loading, update: true });

    // console.log(result);
    if (id) {
      AxiosInstance(8000, "application/json")
        .put(`logistics/updateLogistic/${id}`, data)
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Logistics updated successfully",
            });
            history.push(`/settings/logistics`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding logistics failed",
              description: "Error occured" || res.message,
            });
          } else {
            showNotification({
              type: "error",
              message: "Adding logistics failed",
              description: "Error occured" || res.message,
            });
          }
        })
        .catch((error) => {
          showNotification({
            type: "error",
            message: "Adding logistics failed",
            description: error?.response?.data?.message,
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    } else {
      AxiosInstance(8000, "application/json")
        .post(`logistics/addLogistic`, data)
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Logistics added successfully",
            });

            history.push(`/settings/logistics`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding logistics failed",
              description: "Error occured" || res.message,
            });
          }
        })
        .catch((error) => {
          showNotification({
            type: "error",
            message: "Logistics failed",
            description: error.response.data.message,
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt="Back Arrow"
            className="cursor-pointer"
            onClick={() => history.push("/settings/logistics")}
          />
          {id ? "Edit Logistics" : "Add Logistics"}
        </h1>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching logistics detail..." />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>
          <div className={styles.management_details_contents}>
            <div className="row">
              <div className="col-lg-6 mb-4">
                <label className="theme-form-label">
                  Name
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.name}
                  formControl={{
                    placeholder: "Type the name",
                    name: "name",
                    value: editForm?.name,
                    onChange: handleFormInput,
                  }}
                />
                <div
                 
                >
                  <FormErrorText error={formErrors.name} />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="theme-form-label">
                  Code
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.code}
                  formControl={{
                    placeholder: "Type the code",
                    name: "code",
                    value: editForm?.code,
                    onChange: handleFormInput,
                  }}
                />
                <div
                 
                >
                  <FormErrorText error={formErrors.code} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.add_page_save_button}>
        <button type="button" className="btn btn-outline-primary" onClick={submit}>
          Save
        </button>
      </div>
    </div>
  );
}
