import React, { useState, useEffect } from "react";
import styles from "../../styles/Warehouse.module.css";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification } from "antd";
import {
  onlyNumbers, price, twoDigitWithFloat
 
} from "../RegularExp/RegularExp";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import SelectComponent from "../ReusableComponent/SelectComponent";
import FormErrorText from "../FormErrorText";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import validationRules from "../../validationRules";
import { isObject, isString } from "lodash";
import { useParams } from "react-router-dom";

const initialFormState = {
  logisticCode: "",
  price: "",
  company: "",
  length: "",
  width: "",
  height: "",
};

export default function AddHandlingChargeComponent() {
  const history = useHistory();
  const { id } = useParams();

  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [getCompany, setGetCompany] = useState([]);
  const [getlogisticData, setGetLogisticData] = useState("");
  const [getLogistic, setGetLogistic] = useState([]);
  const [companyID, setCompanyID] = useState("")
  const company = JSON.parse(localStorage.getItem("companydata"));
  // const onlyNumbers = /^(?:0?[1-9]\d*(?:\.\d*[0-9]*)?)|(:?^0.[1-9]+0)$/;

  const rules = {
    logisticCode: [validationRules.required],
    price: [validationRules.required],
    company: [validationRules.required],
    length: [validationRules.required],
    width: [validationRules.required],
    height: [validationRules.required],
  };

  useEffect(() => {
    getLogistics();
    getCompanyDetail();
    // console.log(id);
    if (id) {
      getHandlingChargeDetails(id);
    } else {
      setIsLoading(false);
    }
  }, [getlogisticData]);

  function getCompanyDetail() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getCompanies/settingsSection?pageNum=1&count=20&sortField=createdAt&sortValue=DSC`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetCompany(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get company details",
        };
        showNotification(error);
        // return er;
      });
  }

  /**
   * @author Subhashree
   * GET LOGISTIC DETAILS
   */
   function getLogistics(_id) {
    // var companyCode = e.target.value
    console.log(getlogisticData);
    AxiosInstance(8000, "application/json")
      .get(`companies/getCompanyLogistics/${getlogisticData}`)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetLogistic(res.data);
          console.log(res.data);
        }
      })
      .catch((er) => {
        // return er;
      });
  }
  function getHandlingChargeDetails(id) {
    //  console.log(id);
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`companies/getHandleCharge/${id}`)
      .then((res) => {
        //   console.log(res);
        if (res && res?.data && res?.status == "200") {
          const val = res.data;
          console.log(val)
          setEditForm(val);
          setGetLogisticData(val?.company?.code);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e, parent, err) {
    let { name, value } = e.target;
    //console.log(name, value);
    if (name === "company") {
      getCompany.map((company) => {
        if (value === company.code){
          setCompanyID(company._id)
        }
      }
       
      );
      if (!value && value === "") {
        formErrors.company = "Required";
      } else {
        formErrors.company = "";
      }
    }
    if (name === "logisticCode") {
      if (!value && value === "") {
        formErrors.logisticCode = "Required";
      } else {
        formErrors.logisticCode = "";
        getLogistics(value)
      }
    }
    if (name === "price") {
      value = value.trim();
      if (!value && value === "") {
        formErrors.price = "Required";
      } else if (!twoDigitWithFloat.test(value.trim()) && value.trim() !== "") {
        formErrors.price = "Please enter a valid number";
      } else {
        formErrors.price = "";
      }
    }
    if (err === "length") {
      value = value.trim();
      if (!value && value === "") {
        formErrors["length"] = "Required";
      } else if (!twoDigitWithFloat.test(value.trim()) && value.trim() !== "") {
        formErrors["length"] = "Please enter a valid number";
      } else {
        formErrors["length"] = "";
      }
    }
    if (err === "width") {
      value = value.trim();
      if (!value && value === "") {
        formErrors.width = "Required";
      } else if (!twoDigitWithFloat.test(value.trim()) && value.trim() !== "") {
        formErrors.width = "Please enter a valid number";
      } else {
        formErrors.width = "";
      }
    }
    if (err === "height") {
      value = value.trim();
      if (!value && value === "") {
        formErrors.height = "Required";
      } else if (!twoDigitWithFloat.test(value.trim()) && value.trim() !== "") {
        formErrors.height = "Please enter a valid number";
      } else {
        formErrors.height = "";
      }
    }

    const updatedValues = { ...editForm };
    // switch (parent) {
    //   case "maxRange": {
    //     updatedValues.maxRange[name] = value;
    //     break;
    //   }
    //   case "dimension": {
    //     updatedValues.dimension[name] = value;
    //     break;
    //   }
    //   default: {
    //     updatedValues[name] = value;
    //   }
    // }

    updatedValues[name] = value;
    setEditForm({ ...updatedValues });
  }

  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    //console.log(name,data)
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  function submit(e) {
    let data1 = JSON.parse(JSON.stringify(editForm));
    delete data1.createdAt;
    delete data1.updatedAt;
    delete data1.__v;
    delete data1.deleted;
    const result = validate(undefined, data1);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }

    let data = {
      logisticCode: editForm?.logisticCode,
      companyId: editForm?.company?._id ? (editForm?.company?._id) : (companyID),
      price: Number(editForm?.price),
      length: Number(editForm?.length),
      width: Number(editForm?.width),
      height: Number(editForm?.height),
    };
    const formErrorNew = { ...formErrors };
    if (data.price === 0) {
      formErrorNew.price = "Required";
    } else if (data.price === undefined) {
      formErrorNew.price = "Required";
    } else if (!twoDigitWithFloat.test(data.price) && data.price !== 0) {
      formErrorNew.price = "Please enter a valid number";
    } else {
      formErrorNew.price = "";
    }
    if (!twoDigitWithFloat.test(data["length"]) && data["length"] !== 0) {
      formErrorNew["length"] = "Please enter a valid number";
    } else {
      formErrorNew["length"] = "";
    }
    if (data["width"] === 0) {
      formErrorNew.width = "Required";
    } else if (data["width"] === undefined) {
      formErrorNew.width = "Required";
    } else if (!twoDigitWithFloat.test(data["width"]) && data["width"] !== 0) {
      formErrorNew.width = "Please enter a valid number";
    } else {
      formErrorNew.width = "";
    }
    if (data["height"] === 0) {
      formErrorNew.height = "Required";
    } else if (data["height"] === undefined) {
      formErrorNew.height = "Required";
    } else if (!twoDigitWithFloat.test(data["height"]) && data["height"] !== 0) {
      formErrorNew.height = "Please enter a valid number";
    } else {
      formErrorNew.height = "";
    }
    setFormErrors(formErrorNew);

    setLoading({ ...loading, update: true });

    //console.log(data);
    //console.log(company);
    //return
    const isEmpty = Object.values(formErrorNew).every(
      (x) => x === null || x === ""
    );
    console.log(formErrorNew, "former");
    if (id && isEmpty) {
     
      AxiosInstance(8000, "application/json")
        .put(`/companies/updateHandleCharge/${id}`, data)
        .then((res) => {
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Handling Charge updated successfully",
            });
            history.push(`/settings/handling-charge`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding Handling Charge failed",
              description: "Error occured",
            });
          }
        })
        .catch((er) => {
          if (er.response.status === 409) {
            //console.log(er.response.data);
            showNotification({
              type: "error",
              message: er.response.data.message,
              description: "Error occured",
            });
          } 
          else {
          showNotification({
            type: "error",
            message: "Handling Charge failed",
            description: "Error occured",
          });
        }
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    } else if (isEmpty){ 
      AxiosInstance(8000, "application/json")
        .post(`/companies/addHandleCharge`, data)
        .then((res) => {
          //   console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Handling Charge added successfully",
            });
            history.push(`/settings/handling-charge`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding Handling Charge failed",
              description: "Error occured",
            });
          }
        })
        .catch((er) => {
          if (er.response.status === 409) {
            //console.log(er.response.data);
            showNotification({
              type: "error",
              message: er.response.data.message,
              description: "Error occured",
            });
          } 
          else{
            showNotification({
              type: "error",
              message: "Adding Handling Charge failed",
              description: "Error occured",
            });
          }
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    }
    else {
      showNotification({
        type: "error",
        message: "Adding Handling Charge failed",
        description: "Error occured",
      });
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt="Back Arrow"
            className="cursor-pointer"
            onClick={() => history.push("/settings/handling-charge")}
          />
          {id ? "Edit Handling Charge" : "Add Handling Charge"}
        </h1>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching Handling Charge detail..." />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>
          <div className={styles.management_details_contents}>
            <div className="row">
            <div className="col-lg-6 mb-4">
                <label>
                  Site
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.company}
                  className="form-control-md mb-3"
                  attributes={{
                    name: "company",
                    value: editForm.company?.code,
                    onChange: (e) => {
                      console.log(e.target.value, "HGR");
                      setGetLogisticData(e.target.value);
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" >
                    Select option
                  </option>
                  {Array.isArray(getCompany)
                    ? getCompany.map((company) => (
                      <option key={company?.code} value={company?.code}>
                        {company?.code}
                      </option>
                      ))
                    : null}
                </SelectComponent>
                <div
                
                >
                  <FormErrorText error={formErrors.company} />
                </div>

              </div>
              <div className="col-lg-6 mb-4">
                <label>
                  Logistics Name
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors?.logisticCode}
                  className="form-control-md mb-3"
                  attributes={{
                    name: "logisticCode",
                    value: editForm.logisticCode,
                    onChange: (e) => {
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" >
                    Select option
                  </option>
                  {Array.isArray(getLogistic)
                    ? getLogistic.map((logistic) => (
                        <option
                          key={logistic?.code}
                          value={logistic?.BlackLeftArrowcode}
                        >
                          {console.log(logistic)}
                          {logistic?.code}
                        </option>
                      ))
                    : null}
                </SelectComponent>
                <div
                
                >
                  <FormErrorText error={formErrors.logisticCode} />
                </div>

              </div>
            
            </div>

            <div className="row">
            <div className="col-lg-12 mb-4">
                <label className="theme-form-label">
                  Handling charge
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.price}
                  formControl={{
                    placeholder: "Type the handling Charge",
                    name: "price",
                    value: editForm?.price,
                    onChange: handleFormInput,
                  }}
                />
               <div
                
                >
                  <FormErrorText error={formErrors.price} />
                </div>

              </div>
              </div>

            <div className="row">
              <label className="theme-form-label">Dimensions</label>
            </div>
            <div className="row">
              <div className="col-lg-4 mb-4">
                <label className="theme-form-label">
                  Length
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors["length"]}
                  formControl={{
                    placeholder: "Type the length",
                    name: "length",
                    value: editForm["length"],
                    onChange: (e) => handleFormInput(e, "dimension", "length"),
                  }}
                />
                 <div
                
                >
                <FormErrorText error={formErrors["length"]} />
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <label className="theme-form-label">
                  Width
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.width}
                  formControl={{
                    placeholder: "Type the width",
                    name: "width",
                    value: editForm?.width,
                    onChange: (e) => handleFormInput(e, "dimension", "width"),
                  }}
                />
                <div
                
                >
                <FormErrorText error={formErrors.width} />
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <label className="theme-form-label">
                  Height
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.height}
                  formControl={{
                    placeholder: "Type the heigth",
                    name: "height",
                    value: editForm?.height,
                    onChange: (e) => handleFormInput(e, "dimension", "height"),
                  }}
                />
                 <div
                
                >
                  <FormErrorText error={formErrors.height} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.add_page_save_button}>
        <button type="button" className="btn btn-outline-primary" onClick={submit}>
          Save
        </button>
      </div>
    </div>
  );
}
