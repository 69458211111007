import React from "react";
import commonstyles from "../../styles/SettingsTable.module.css";
import deleteIcon from "../../assets/img/delete.svg";
import editIcon from "../../assets/img/edit.svg";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { AxiosInstance } from "../../utilities";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import { Modal, notification, Menu } from "antd";
import "antd/dist/antd.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import BackArrow from "../../assets/icons/back_arrow.svg";
import arrowDown from "../../assets/images/arrow_down.svg";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

function HandlingCharges() {
  const history = useHistory();
  const [regionList, setRegionList] = useState([]);
  // const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setpagination] = useState({});
  const [IsLoading, setIsLoading] = useState(false);
  const [getCompany, setGetCompany] = useState([]);
  const [filterCompany, setfilterCompany] = useState("");
  //const company = JSON.parse(localStorage.getItem("companydata"));
  const { confirm } = Modal;
  const [logisticCode, setLogisticCode] = useState("");
  const [logistic, setLogistic] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: "Site",
      visible: false,
      key: "company",
      render: (text, record, index) => <span>{record?.company?.code ? record?.company?.code : "-"}</span>,
    },
    {
      title: "Logistic",
      visible: false,
      key: "logisticCode",
      render: (text, record, index) => (
        <span>{record?.logisticCode ? record?.logisticCode : "-"}</span>
      ),
    },
    {
      title: "Handling Charge",
      visible: false,
      key: "price",
      render: (text, record, index) => <span>${record?.price ? record?.price : "-"}</span>,
    },
    {
      title: "L x W x H",
      visible: false,
      key: "dimension",
      render: (text, record, index) => (
        <span>
          {record?.length} x {record?.width} x {record?.height}
        </span>
      ),
    },
    {
      title: "Volume",
      visible: false,
      key: "volume",
      render: (text, record, index) => <span>{record?.volume.toLocaleString()}(in3)</span>,
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) => (
        <>
          <NavLink to={`/settings/editHandlingCharge/${record._id}`}>
            <img
              src={editIcon}
              className={`${commonstyles.admin_table_edit_img} mr-2`}
              onClick={() => editShip(record)}
              alt="View handling charge details"
              title="Edit handing charge"
              style={{ cursor: "pointer" }}
            />
          </NavLink>
          <img
            src={deleteIcon}
            className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
            onClick={() => showDeleteConfirm(record._id)}
            alt="Delete handling charge"
            title="Delete handling charge"
          />
        </>
      ),
    },
  ]);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }
  useEffect(() => {
    getCompanyDetail();
    getLogistics();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getHandlingCharges(pageSize, nextPage, "");

    localStorage.removeItem("selectedIds");
    return () => {
      localStorage.removeItem("regionList");
      localStorage.removeItem("selectedIds");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCompany, logisticCode]);

  function getLogistics() {
    AxiosInstance(8000, "application/json")
      .get("logistics/getLogistics")
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setLogistic(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      });
  }

  function getCompanyDetail() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getCompanies/settingsSection?pageNum=1&count=20&sortField=createdAt&sortValue=DSC`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetCompany(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get company details",
        };
        showNotification(error);
        // return er;
      });
  }
  // *** FETCH REGION LIST *** //
  function getHandlingCharges(pageSize, nextPage, filterarr, type) {
    // setIsLoading(false);
    console.log(filterarr);
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };
    //const company = JSON.parse(localStorage.getItem("companydata"));
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getHandleCharges?pageNum=${nextPage}&count=${pageSize}&sortField=createdAt&sortValue=DSC&companyId=${filterCompany}&logisticCode=${logisticCode}`,
        filterOptions
      )
      .then((res) => {
        // console.log(filterOptions);
        if (res && res.data && res.status == "200") {
          if (res.data.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // console.log(res.data)
            localStorage.setItem("regionList", JSON.stringify(res.data.data));
            setRegionList(res.data.data);
            setpagination(res.data.pagination);
          } else {
            setRegionList([]);
            setpagination({});
          }
        }
      })
      .catch((er) => {
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get handling charge",
        };
        showNotification(error);
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }

  function addHandlingCharge() {
    history.push(`/settings/addHandlingCharge`);
  }

  // *** EDIT Handling Charge FUNCTION *** //
  function editShip(record) {
    // console.log(record);
    history.push({
      pathname: `/settings/editHandlingCharge/${record._id}`,
      state: record,
    });
  }

  // DELETE Handling charge
  function showDeleteConfirm(id) {
    // console.log(id);
    confirm({
      title: "Are you sure want to delete this ? ",
      icon: <ExclamationCircleOutlined />,
      // content: "Some descriptions",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        deleteHandlingCharge(id);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  }

  function deleteHandlingCharge(id) {
    // console.log(id);
    setIsLoading(true);
    AxiosInstance(8000, "application/json")
      .delete(`companies/removeHandleCharge/${id}`)
      .then((res) => {
        // console.log(res);
        if (res && res?.data && res?.status == "200") {
          showNotification({
            type: "success",
            message: "Success",
            description: "Handling charge deleted successfully",
          });
          setIsLoading(false);
          getHandlingCharges(pageSize, nextPage, "");
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Failed to delete handling charge",
          });
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function debounceSearch(filter) {
    // console.log(filter);
    setIsLoading(true);
    setNextPage(1);
    getHandlingCharges(pageSize, 1, filter);
  }

  function handlepaginationChanges(value, type) {
    let filter = {};
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getHandlingCharges(value, 1, filter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getHandlingCharges(pageSize, value, filter);
    }
  }
  function filterOnChange(e) {
    const { name, value } = e.target;
    console.log(value);
    setfilterCompany(value);
    // setIsLoading(true);
  }

  function handleFormInput(e) {
    let { name, value } = e.target;
    setLogisticCode(value);
  }

  return (
   
      <div className={`${commonstyles.management_contents}  `}>
      <div className="row d-flex">
          <div className="back_arrow">
            <img
              src={BackArrow}
              onClick={() => history.push("/settings")}
              alt=""
              className="cursor-pointer"
              title="back"
              width={"25px"}
              height={"21px"}
            />
            <h1 className="heading">Handling Charge</h1>
          </div>
        </div>
        <div className={`${commonstyles.management_header}  `}>
          <div className="row">
            <div className="col-lg-6">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={addHandlingCharge}
              >
                Add Handling Charge
              </button>
            </div>
            <div className="col-lg-6 d-flex flex-row-reverse">
              <div className={` filter_width ${commonstyles.dropdown} `}>
                <Menu className={`filter_dropdown datefilter`}>
                  <div className="card">
                    <div className="card_contents">
                      <div className="row no-gutters">
                        <div className="card_dropdown show col-md-6">
                          <select
                            name="logisticCode"
                            value={logisticCode}
                            onChange={handleFormInput}
                            className={`form-control  ${commonstyles.form_control} `}
                            style={{ cursor: "pointer" }}
                          >
                            <option value="">ALL</option>
                            {Array.isArray(logistic)
                              ? logistic.sort((a, b) => (a.code > b.code ? 1 : -1)).map((logi) => (
                                  <option key={logi._id} value={logi.code}>
                                    {logi.code}
                                  </option>
                                ))
                              : null}
                          </select>
                          <img src={arrowDown} className="dropDownArrow" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Menu>
              </div>
              <div className={` filter_width ${commonstyles.dropdown}`}>
                <Menu className="filter_dropdown datefilter">
                  <div className="card">
                    <div className="card_contents">
                      <div className="row no-gutters">
                        <div className="card_dropdown show col-md-6">
                          <select
                            name="filterCompany"
                            value={filterCompany}
                            onChange={filterOnChange}
                            className={`form-control  ${commonstyles.form_control} `}
                            style={{ cursor: "pointer" }}
                          >
                            <option value="">ALL</option>
                            {Array.isArray(getCompany)
                              ? getCompany.sort((a, b) => (a.code > b.code ? 1 : -1)).map((company) => (
                                  <option
                                    key={company?.code}
                                    value={company?._id}
                                  >
                                    {company?.code}
                                  </option>
                                ))
                              : null}
                          </select>
                          <img src={arrowDown} className="dropDownArrow" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div
            className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
          >
            {" "}
          </div>
          <div className={`${commonstyles.management_table} table-responsive`}>
            {IsLoading === true ? (
              <div
                style={{
                  marginLeft: "70vh",
                  marginTop: "20vh",
                }}
              >
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching Handling Charge details..." />
                  </td>
                </tr>
              </div>
            ) : (
              <AntTableComponent
                className="order_table"
                tableProps={{
                  columns: columns,
                  dataSource: regionList,
                  pagination: false,
                }}
              />
            )}
          </div>
        </div>
        {!IsLoading.list && Array.isArray(regionList) && regionList.length ? (
          <Pagination
            pageSizes={pageSizes}
            pageSize={pageSize}
            nextPage={nextPage}
            data={pagination}
            handlepaginationChanges={handlepaginationChanges}
          />
        ) : null}
      </div>
   
  );
}

export default HandlingCharges;
