import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./DashboardComponent.css";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import { useDispatch } from "react-redux";
import * as action from "../../Store/Actions/index";
import { drawGraph } from "../DrawGraph/DrawGraph";
import { DatePicker, Space, Menu, Popover, Button } from "antd";
import styles from "../../styles/DashBoa.module.css";
import AntDateRangeSelectComponent from "../ReusableComponent/AntDateRangeSelectComponent";
import moment from "moment";
import arrowDown from "../../assets/images/arrow_down.svg";
import Arrow from "../../assets/images/sidebar/right_sidebar_arrow.svg";
import ArrowActive from "../../assets/images/sidebar/right_sidebar_arrow_active.svg";
import BulkInfo from "../../assets/img/ship.svg";
import UserInfo from "../../assets/img/User.svg";
import TruckInfo from "../../assets/img/truck_icon.svg";
import ShipmentIcon from "../../assets/img/shipment.svg";
import QuotesIcon from "../../assets/img/Quotes.svg";
import TotCustomer from "../../assets/images/group_total_customer.svg";
import filterIcon from "../../assets/images/sidebar/Iconly_Bulk_Filter.svg";
import money from "../../assets/images/money.svg";
import truck from "../../assets/images/truck.svg";
import handling from "../../assets/img/handling.svg";
import warehouse from "../../assets/img/warehouse.svg";
import apikey from "../../assets/img/apikey.svg";
import company from "../../assets/img/company.svg";
import logistics from "../../assets/img/logistics.svg";
import carriers from "../../assets/img/carriers.svg";
import freightclass from "../../assets/img/freightclass.svg";
import vendor from "../../assets/img/vendor.svg";
import userManagement from "../../assets/img/userManagement.svg";
import originLocation from "../../assets/img/origin location.svg";
import billingLocation from "../../assets/img/Billing Account.svg";
import accountDetails from "../../assets/img/Account Details.svg";
import { AxiosInstance } from "../../utilities";
import { clone, upperCase, upperFirst } from "lodash";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { notification } from "antd";

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];
const defaultPageSize = 5;
const initialFormState = {
  // status: true,
  date: "",
};

const daysObj = [
  { _id: 1, name: "Today", value: 0 },
  { _id: 2, name: "30 day back", value: 30 },
  { _id: 3, name: "60 day back", value: 60 },
  { _id: 4, name: "90 day back", value: 90 },
  { _id: 5, name: "120 day back", value: 120 },
  { _id: 6, name: "180 day back", value: 180 },
  { _id: 7, name: "1 year back", value: 365 },
  { _id: 8, name: "2 year back", value: 365 * 2 },
  { _id: 9, name: "3 year back", value: 365 * 3 },
  { _id: 10, name: "5 year back", value: 365 * 5 },
  { _id: 11, name: "Custom", value: "custom" },
];

const filterRecentActi = [
  { _id: 1, name: "All", value: "ALL" },
  { _id: 2, name: "Users", value: "USERS" },
  { _id: 2, name: "Quotes", value: "QUOTES" },
  { _id: 3, name: "Shipments", value: "SHIPMENTS" },
];

export default function Dashboard(props) {
  const dispatch = useDispatch();
  const today = new moment();
  const history = useHistory();

  const [barGrapg, setBarGrapg] = useState([]);
  const [pieChart1, setPieChart1] = useState([]);
  const [filterDays, setFilterDays] = useState(
    JSON.parse(sessionStorage.getItem("dashboard_days_filter")) ?? 0
  );
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [recentActivity, setRecentActivity] = useState(filterRecentActi);
  const [recentFilter, setRecentFilter] = useState("ALL");
  const [filteredDate, setFilteredDate] = useState({
    startDate:
      JSON.parse(sessionStorage.getItem("dashboard_date_filter"))?.startDate ??
      "",
    endDate:
      JSON.parse(sessionStorage.getItem("dashboard_date_filter"))?.endDate ??
      "",
  });
  const [show, setShow] = useState(false);
  const [customerCount, setCustomerCount] = useState("");
  const [quoteReq, setQuoteReq] = useState("");
  const [shipReq, setShipReq] = useState("");
  const [shipGraph, setShipGraph] = useState([]);
  const [graphValue, setGraphValue] = useState([]);
  const [graphLabel, setGraphLabel] = useState([]);
  const [dashBar, setDashBar] = useState([]);
  const [graphX, setGraphX] = useState([]);
  const [graphY, setGraphY] = useState([]);
  const [selectMenu, setSelectMenu] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [activitiesValue, setActivitiesValue] = useState([]);

  //loading
  const [isLoadingDash, setIsLoadingDash] = useState(true);
  const [isLoadingRecent, setIsLoadingRecent] = useState(true);

  /**
   * @author Prasanthu
   * COMPANY USE STATE
   */

  const [getCompanies, setGetCompanies] = useState([]);
  const [filterCompany, setfilterCompany] = useState(
    JSON.parse(sessionStorage.getItem("dashboard_company_filter")) ?? ""
  );

  const content = (
    <select
      name="recentFilter"
      value={recentFilter}
      onChange={filterOnChange}
      className={`form-control recentFilter ${styles.form_control}`}
    >
      {Array.isArray(recentActivity)
        ? recentActivity.map((date) => (
            <option key={date._id} value={date.value}>
              {date.name}
            </option>
          ))
        : null}
    </select>
  );

  useEffect(() => {
    getDashboardDetails();
    getTotalCustomer();
    getQuoteRequest();
    getShipRequest();
    getShipDestination();
    getCompaniesDetail();
  }, [filterDays, filteredDate, filterCompany]);

  useEffect(() => {
    getRecentActiveDetails();
  }, [recentFilter]);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  const logout = () => {
    dispatch(action.logout());
    window.location.href = "/login";
  };

  function filterOnChange(e) {
    const { name, value } = e.target;
    if (name === "recentFilter") {
      setRecentFilter(value);
      setIsLoadingRecent(true);
    } else if (name === "filterCompany") {
      setfilterCompany(value);
      setIsLoadingDash(true);
      sessionStorage.setItem("dashboard_company_filter", JSON.stringify(value));
    } else {
      setFilterDays(value);
      setIsLoadingDash(true);
      sessionStorage.setItem("dashboard_days_filter", JSON.stringify(value));
    }
  }
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    sessionStorage.setItem(
      "dashboard_date_filter",
      JSON.stringify(clonefilteredDate)
    );
  };

  function disabledDate(current) {
    // Can't select the days before today
    return current && current > moment().endOf("day");
  }

  /**
   * @author Prasanthu
   * GET COMPANY DETAILS
   */

  function getCompaniesDetail() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getCompanies?pageNum=1&count=20&sortField=createdAt&sortValue=DSC`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setIsLoadingDash(false);
          setGetCompanies(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get company details",
        };
        showNotification(error);
        // return er;
      });
  }

  function getRecentActiveDetails() {
    let data = {
      type: recentFilter,
      companyId: filterCompany === "" ? null : filterCompany,
    };
    // console.log(data);

    AxiosInstance(8000, "application/json")
      .post(
        `/activities/getActivities?pageNum=1&count=10&sortField=createdAt&sortValue=DSC`,
        data
      )
      .then((res) => {
        // console.log(res.data);
        if (res && res.data && res.status == "200") {
          let data = res.data.data;
          data.length = 5;
          setActivitiesValue(data);
          setIsLoadingRecent(false);
        }
      })
      .catch((er) => {
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get activities details",
        };
        showNotification(error);
        // return er;
        // setIsLoadingRecent(false);
      });
  }

  function getTotalCustomer() {
    let data = {
      filterDays: filterDays === "" ? null : +filterDays,
      companyId: filterCompany === "" ? null : filterCompany,
    };
    if (!data.filterDays) {
      data.startDate =
        filteredDate.startDate === "" ? null : filteredDate.startDate;
      data.endDate = filteredDate.endDate === "" ? null : filteredDate.endDate;
    }
    // console.log(data);

    AxiosInstance(8000, "application/json")
      .post("/customers/getCustomersCount", data)
      .then((res) => {
        // console.log(res.data);
        if (res && res.data && res.status == "200") {
          setIsLoadingDash(false);
          setCustomerCount(res.data);
        }
      })
      .catch((er) => {
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get customer count details",
        };
        showNotification(error);
        // return er;
        // setIsLoadingDash(false);
      });
  }

  function getQuoteRequest() {
    let data = {
      filterDays: filterDays === "" ? null : +filterDays,
      companyId: filterCompany === "" ? null : filterCompany,
    };
    if (!data.filterDays) {
      data.startDate =
        filteredDate.startDate === "" ? null : filteredDate.startDate;
      data.endDate = filteredDate.endDate === "" ? null : filteredDate.endDate;
    }
    // console.log(data);

    AxiosInstance(8000, "application/json")
      .post("/quotes/getQuotesCount", data)
      .then((res) => {
        // console.log(res.data);
        if (res && res.data && res.status == "200") {
          setIsLoadingDash(false);

          setQuoteReq(res.data);
        }
      })
      .catch((er) => {
        // return er;
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get quotes count details",
        };
        showNotification(error);
        // setIsLoadingDash(false);
      });
  }

  function getShipRequest() {
    let data = {
      filterDays: filterDays === "" ? null : +filterDays,
      companyId: filterCompany === "" ? null : filterCompany,
    };
    if (!data.filterDays) {
      data.startDate =
        filteredDate.startDate === "" ? null : filteredDate.startDate;
      data.endDate = filteredDate.endDate === "" ? null : filteredDate.endDate;
    }
    // console.log(data);

    AxiosInstance(8000, "application/json")
      .post("/shipments/getShipmentsCount", data)
      .then((res) => {
        // console.log(res.data);
        if (res && res.data && res.status == "200") {
          setShipReq(res.data);
          setIsLoadingDash(false);
        }
      })
      .catch((er) => {
        // return er;
        // setIsLoadingDash(false);
        showNotification({
          type: "error",
          message: "Error",
          description: "Failed to get shipment count details",
        });
      });
  }

  function getDashboardDetails() {
    let data = {
      filterDays: filterDays === "" ? null : +filterDays,
      companyId: filterCompany === "" ? null : filterCompany,
    };
    if (!data.filterDays) {
      data.startDate =
        filteredDate.startDate === "" ? null : filteredDate.startDate;
      data.endDate = filteredDate.endDate === "" ? null : filteredDate.endDate;
    }
    // console.log(data);

    AxiosInstance(8000, "application/json")
      .post("/shipments/getShipments/graph", data)
      .then((res) => {
        // console.log(res.data);
        if (res && res.data && res.status == "200") {
          setDashBar(res.data);
          setIsLoadingDash(false);
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Error",
          description: "Failed to get shipment graph details",
        });
        // return er;
        // setIsLoadingDash(false);
      });
  }

  function getShipDestination() {
    let data = {
      count: 5,
      filterDays: filterDays === "" ? null : +filterDays,
      companyId: filterCompany === "" ? null : filterCompany,
    };
    if (!data.filterDays) {
      data.startDate =
        filteredDate.startDate === "" ? null : filteredDate.startDate;
      data.endDate = filteredDate.endDate === "" ? null : filteredDate.endDate;
    }
    // console.log(data);

    AxiosInstance(8000, "application/json")
      .post("/shipments/getTopShippmentDestinations", data)
      .then((res) => {
        // console.log(res.data);
        if (res && res.data && res.status == "200") {
          setShipGraph(res.data);
          setIsLoadingDash(false);
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Error",
          description: "Failed to get shipment destination details",
        });
        // return er;
        // setIsLoadingDash(false);
      });
  }

  useEffect(() => {
    let dashBarData = dashBar?.data?.map(function (item) {
      graphY.push(Math.round(item?.totalCharge));
      let datas = item._id.split("-");
      let year = datas[0];
      let month = datas[1];
      // console.log( typeof month);
      //month bar
      if (month === "1") {
        graphX.push(`Jan (${year})`);
      } else if (month === "2") {
        graphX.push(`Feb (${year})`);
      } else if (month === "3") {
        graphX.push(`Mar (${year})`);
      } else if (month === "4") {
        graphX.push(`Apr (${year})`);
      } else if (month === "5") {
        graphX.push(`May (${year})`);
      } else if (month === "6") {
        graphX.push(`Jun (${year})`);
      } else if (month === "7") {
        graphX.push(`Jul (${year})`);
      } else if (month === 8) {
        graphX.push(`Aug (${year})`);
      } else if (month === "9") {
        graphX.push(`Sep (${year})`);
      } else if (month === "10") {
        graphX.push(`Oct (${year})`);
      } else if (month === "11") {
        graphX.push(`Nov (${year})`);
      } else if (month === "12") {
        graphX.push(`Dec (${year})`);
      }
    });
    // console.log(dashBar);
    // console.log(month, year);
    // console.log(graphX);
    // console.log(graphY);
    let valueOfShip = dashBar?.maxCharge;
    let bar = {
      x: graphX,
      y: graphY,
      // x: Array.from(new Set(graphX)),
      // y: Array.from(new Set(graphY)),
      /* x: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      y: [
        100000, 120000, 150000, 50000, 460000, 230000, 540000, 500000, 460000,
        230000, 540000, 500000,
      ], */
      type: "bar",
      hoverinfo: "y",
      valueShip: valueOfShip,
      title: "Revenue",
      width: 0.2,
      name: "Revenue",
      marker: {
        color: [
          "#4C52B8",
          "#5B81FD",
          "#4C52B8",
          "#5B81FD",
          "#4C52B8",
          "#5B81FD",
          "#4C52B8",
          "#5B81FD",
          "#4C52B8",
          "#5B81FD",
          "#4C52B8",
          "#5B81FD",
        ],
      },
    };
    let graph = drawGraph(
      [bar],
      null,
      null,
      "bar",
      null,
      null,
      640,
      250,
      null,
      null,
      null
    );
    setBarGrapg(graph);

    let graphData = shipGraph?.map(function (item) {
      // console.log("item",upperCase(item?.state));
      graphValue.push(item?.count);
      graphLabel.push(upperCase(item?.state));
    });
    // console.log(graphValue);
    let pieTrace1 = {
      // values: [60, 40],
      // labels: ["Texas", "Mexico"],
      values: graphValue,
      labels: graphLabel,
      hoverinfo: "label+text+value",
      textposition: "inside",
      // text: "value",
      textinfo: "label+value",
      insidetextorientation: "radial",
      type: "pie",
      hole: 0,
      marker: {
        colors: ["#4C52B8", "#5B81FD"],
      },
    };
    let chart = drawGraph(
      [pieTrace1],
      null,
      null,
      "ABpieChart",
      null,
      null,
      450,
      250,
      null,
      null,
      null
    );
    setPieChart1(chart);
    setGraphLabel([]);
    setGraphValue([]);
    setGraphX([]);
    setGraphY([]);
  }, [shipGraph, dashBar]);

  return isLoadingDash === true ? (
    <div
      style={{
        marginLeft: "70vh",
        marginTop: "40vh",
      }}
    >
      <tr className="bg-transparent">
        <td colSpan="100%">
          <LoadingIndicator loadingText="Fetching dashboard details..." />
        </td>
      </tr>
    </div>
  ) : (
    // <section class="admin_management">
    <div className={styles.management_contents}>
      <div className={styles.managenet_card}>
        <div className={styles.management_header_date}>
          {today.format("MMMM-DD-YYYY").split("-").join(" ")}
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className={styles.management_header}>
              <h1>
                <span className={styles.management_header_hi}>Hello !</span>
                <span className={styles.management_header_welcome}>
                  Welcome back
                </span>
              </h1>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className={styles.management_header}>
              <div
                className={` filter_width ${styles.management_header} d-flex`}
              >
                <Menu className="filter_dropdown datefilter">
                  <div className="card">
                    <div className="card_contents">
                      <div className="row no-gutters">
                        <div className="card_dropdown show col-md-2">
                          <select
                            name="filterCompany"
                            value={filterCompany}
                            onChange={filterOnChange}
                            className={`form-control ${styles.form_control}`}
                            style={{ cursor: "pointer" }}
                          >
                            <option value="">ALL</option>
                            {Array.isArray(getCompanies)
                              ? getCompanies
                                  .sort((a, b) => (a.code > b.code ? 1 : -1))
                                  .map((companies) => (
                                    <option
                                      key={companies?.code}
                                      value={companies?._id}
                                    >
                                      {companies?.code}
                                    </option>
                                  ))
                              : null}
                          </select>
                          <img src={arrowDown} className="dropDownArrow" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Menu>
                <Menu className="filter_dropdown datefilter">
                  <div className="card">
                    <div className="card_contents">
                      <div className="row no-gutters">
                        <div className="card_dropdown show col-md-6">
                          <select
                            name="filterDays"
                            value={filterDays}
                            onChange={filterOnChange}
                            className={`form-control  ${styles.form_control} `}
                            style={{ cursor: "pointer" }}
                          >
                            {Array.isArray(filterdayArr)
                              ? filterdayArr.map((date) => (
                                  <option key={date._id} value={date.value}>
                                    {date.name}
                                  </option>
                                ))
                              : null}
                          </select>
                          <img src={arrowDown} className="dropDownArrow" />
                        </div>
                        {filterDays === "custom" && (
                          <div className="card_dropdowns_dashboard show col-md-12">
                            <AntDateRangeSelectComponent
                              selectProps={{
                                placeholder: placeholder,
                                onChange: dateRangeOnchangeHandler,
                                name: "sessionDateRange",
                                defaultValue: [
                                  filteredDate?.startDate
                                    ? moment(
                                        filteredDate?.startDate
                                          ? filteredDate?.startDate
                                          : ""
                                      )
                                    : "",
                                  filteredDate?.endDate
                                    ? moment(
                                        filteredDate?.endDate
                                          ? filteredDate?.endDate
                                          : ""
                                      )
                                    : "",
                                ],
                                format: dateFormatList,
                                disabledDate: disabledDate,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <div className={`row mb-4 ${styles.dashboard_revenue_card}`}>
          <div className="col-lg-5">
            <div className={` ${styles.revenue_margin_bottom}`}>
              <div className={`${styles.managenet_card_media_contents}`}>
                <div className={`media ${styles.managenet_card_media} d-flex`}>
                  <img
                    className={`mr-3 ${styles.media_img}`}
                    src={TotCustomer}
                    alt=""
                  />
                  <div className={`media-body ${styles.media_body}`}>
                    Total Customer
                    <h5 className="mt-0">{customerCount?.count}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className={` ${styles.revenue_margin_bottom}`}>
              <div className={` ${styles.managenet_card_media_contents}`}>
                <div className={`media ${styles.managenet_card_media} d-flex`}>
                  <img
                    className={`mr-3 ${styles.media_img}`}
                    src={money}
                    alt=""
                  />
                  <p className={styles.dollar_sign}>$</p>
                  <div className={`media-body ${styles.media_body}`}>
                    No. Get Quote Request
                    <h5 className="mt-0">{quoteReq?.count}</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className={` ${styles.revenue_margin_bottom_mobile}`}>
              <div className={` ${styles.managenet_card_media_contents}`}>
                <div className={`media ${styles.managenet_card_media} d-flex`}>
                  <img
                    className={`mr-3 ${styles.media_img}`}
                    src={truck}
                    alt=""
                  />
                  <div className={`media-body ${styles.media_body}`}>
                    No. Get Shipment Request
                    <h5 className="mt-0">{shipReq?.count}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="col-lg-12 mb-6 ">
              <div className={`${styles.revenue_pie}`}>
                <h3 className={`${styles.revenue_title}`}>
                  Top 5 Shipment Destinations
                </h3>
                <div>
                  {/* {pieChart1} */}
                  {shipGraph?.length > 0 ? (
                    pieChart1
                  ) : (
                    <div className="bg-transparent">
                      <div
                        className={styles.no_data}
                        style={{ padding: "0px", textAlign: "center" }}
                      >
                        No data found !
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className={`${styles.revenue}`}>
              <h3 className={`${styles.revenue_title}`}>Total Shipment</h3>
              <div className={`${styles.revenue_value}`}>
                ${Math.round(dashBar.totalCharge)}
              </div>
              {barGrapg}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.management_contents_right_slide_bar}>
        <nav id="sidebar" className={show ? "active" : ""}>
          <div className={styles.right_slide_bar_sticky}>
            <button
              onClick={() => setShow(!show)}
              type="button"
              id="sidebarCollapse"
              className="btn"
            >
              <img src={Arrow} alt="arrow" className="arrow" />
              <img src={ArrowActive} className="arrow_active" alt="arrow" />
            </button>

            <section className="recent_activites">
              <div className="recent_activites_header">
                <h4 style={{ fontWeight: "bold" }} className="text_hide">
                  Recent Activity
                </h4>
                <Popover content={content} trigger="hover">
                  <img src={filterIcon} alt="filter" className="filterIcon" />
                </Popover>
              </div>
              {isLoadingRecent === true ? (
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching recent activity" />
                  </td>
                </tr>
              ) : Array.isArray(activitiesValue) &&
                activitiesValue.length > 0 ? (
                activitiesValue.map((recent, indx, arr) => (
                  <div className="recent_activites_values" key={recent._id}>
                    <div className="recent_activites_details">
                      <div className="recent_activites_img">
                        <img style={{borderRadius:"100px"}}
                          src={
                            recent.page === "QUOTES"
                              ? QuotesIcon
                              : recent.page === "SHIPMENTS"
                              ? ShipmentIcon
                              : recent.page === "USERS" ? UserInfo : recent.page === "ACCOUNTDETAIL"
                              ? accountDetails
                              : recent.page === "APIKEY" ? apikey
                              : recent.page === "BILLINGLOCATION" ? billingLocation
                              : recent.page === "COMPANY" ? company
                              : recent.page === "HANDLECHARGE" ? handling
                              : recent.page === "LOGISTIC" ? logistics
                              : recent.page === "CARRIER" ? carriers
                              : recent.page === "FREIGHTCLASS" ? freightclass
                              : recent.page === "ORIGINLOCATION" ? originLocation
                              : recent.page === "WAREHOUSE" && warehouse
                          }
                          alt=""
                        />
                      </div>
                      <div className="recent_activites_content text_hide">
                        <div
                          className="recent_activites_name"
                          style={{ textTransform: "capitalize" }}
                        >
                          {recent?.name}
                        </div>
                        <div className="recent_activites_status">
                          {recent?.details}
                        </div>
                        <div
                          className="recent_activites_date"
                          style={{ textTransform: "uppercase" }}
                        >
                          {new Date(Date.now()).getDate() ==
                          new Date(recent?.updatedAt).getDate()
                            ? `Today  ${moment(new Date(recent?.updatedAt))
                                .format("MM/DD/YYYY hh:mm:ss a")
                                .toLocaleString("en-IN", {
                                  timeZone: "Asia/Kolkata",
                                })}`
                            : moment(new Date(recent?.updatedAt))
                                .format("MM/DD/YYYY hh:mm:ss a")
                                .toLocaleString("en-IN", {
                                  timeZone: "Asia/Kolkata",
                                })}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="bg-transparent">
                  <div
                    className={styles.no_data}
                    style={{ padding: "0px", textAlign: "center" }}
                  >
                    No data found!
                  </div>
                </div>
              )}
            </section>
          </div>
        </nav>
      </div>
    </div>
    // </section>
  );
}
