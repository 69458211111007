import React, { useState, useEffect } from "react";
import "./LoginComponent.css";
import Logo from "../../assets/img/hgrlogo.png";
import eyeOpen from "../../assets/icons/eye_icon.svg";
import eyeClose from "../../assets/icons/eye_close.svg";
import BackGroundImage from "../../assets/img/login_banner_img.png";
// import {  } from "react-router";
import jwt from "jsonwebtoken";
import { useHistory, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { emailRegx } from "../RegularExp/RegularExp";
import * as action from "../../Store/Actions/index";
import InputComponent from "../ReusableComponent/InputComponent";
import { Button, Modal } from "antd";

import { AxiosInstance } from "../../utilities";
import { notification } from "antd";
import FormErrorText from "../FormErrorText";
import { isObject, isString, isEqual } from "lodash";
import InputFormComponent from "../ReusableComponent/InputFormComponent";

const LoginComponent = (props) => {
  const history = useHistory();
  //model
  const [visibleModel, setVisibleModel] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");

  const isAuthenticated = jwt.decode(localStorage.getItem("token"))
    ? true
    : false;

  const dispatch = useDispatch();
  const loginError = useSelector((state) => {
    return state.auth.error;
  });
  const [formError, setformError] = useState({
    user_name: "",
    password: "",
  });
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const [visible, setVisible] = useState(false);
  const [emailID, setEmailID] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [isCalled, setIsCalled]=useState(false)
  const on_password_visible = () => {
    setVisible(!visible);
  };

  const showModal = () => {
    setIsCalled(true);
    setEmailID("");
    setFormErrors({})
    setVisibleModel(true);
  };

  const handleOk = () => {
    /*setModalText(
      <>
        <div>
          <h4 className="modal_title">Forgot Password? Don't worry :)</h4>
        </div>
        <div className="sub_text">
          Just Enter your registered Email ID
        </div>
      </>
    );
    setConfirmLoading(true);
    setTimeout(() => {
      setVisibleModel(false);
      setConfirmLoading(false);
    }, 2000); */
    submit();
  };

  const handleCancel = () => {
    // console.log("Clicked cancel button");
    setVisibleModel(false);
  };

  useEffect(() => {
    setFormErrors({})
    setIsCalled(true);

    if (isAuthenticated === true) {
      window.location.href = "/settings";
    }
  }, []);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    const newformError = { ...formError };
    switch (name) {
      case "email":
        value.trim() === ""
          ? (newformError.email = "Please enter an email")
          : !emailRegx.test(value.trim())
          ? (newformError.email = "Please enter valid email address")
          : (newformError.email = "");
        break;
      case "password":
        value === ""
          ? (newformError.password = "Please enter a password")
          : (newformError.password = "");
        break;
      default:
        break;
    }
    setformError(newformError);
    setLoginForm({ ...loginForm, [name]: value });
  };
  const onLogin = (e) => {
    setIsCalled(false);
    const loginData = {
      email: loginForm.email,
      password: loginForm.password,
    };
    e.preventDefault();
    const newformError = { ...formError };
    const loginFormNew = { ...loginForm };
    if (loginFormNew.email === "" && loginFormNew.password === "") {
      newformError.email = "* Please enter an email address";
      newformError.password = "* Please enter a password";
    } else if (loginFormNew.email === "" && loginFormNew.password !== "") {
      newformError.email = "* Please enter an email address";
      newformError.password = "";
    } else if (loginFormNew.email !== "" && loginFormNew.password === "") {
      if (!emailRegx.test(loginFormNew.email)) {
        newformError.email = "* Please enter valid email address";
      } else {
        newformError.email = "";
      }
      newformError.password = "* Please enter a password";
    } else if (
      loginFormNew.email !== "" &&
      !emailRegx.test(loginFormNew.email)
    ) {
      newformError.email = "* Please enter valid email address";
      newformError.password = "";
    } else {
      newformError.email = "";
      newformError.password = "";
    }
    setformError(newformError);
    if (newformError.email === "" && newformError.password === "") {
      dispatch(action.LoginActions(loginData, history));
    }
  };

  function forgotPassTab() {
    history.push(`/forgot`);
  }

  function submit(e) {
    let datass = {
      email: emailID,
    };

    let data = JSON.parse(JSON.stringify(datass));

    if (data.email) {
      if (!emailRegx.test(data.email) || !data.email === "") {
        setFormErrors({
          ...formErrors,
          emailID: "Please enter valid email",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, emailID: "" });
      }
    }

    if (formErrors.emailID !== "" || emailID === "") {
      setFormErrors({ ...formErrors, emailID: "Please enter valid email" });
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }
    setLoading({ ...loading, update: true });

    AxiosInstance(8000, "application/json")
      .post(`users/sendOtp`, data)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          // setEnable(true);

          showNotification({
            type: "success",
            message: "Success",
            description: "OTP sent successfully",
          });
          localStorage.setItem("email", emailID);

          history.push({
            pathname: `/verify`,
            // email: emailID,
          });
        } else if (res.status === 400) {
          showNotification({
            type: "error",
            message: "User not found",
            description: "Error occured",
          });
        }
      })
      .catch((error) => {
        showNotification({
          type: "error",
          message: "User failed",
          description: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className="login_bg row m-0">
      <div className="col-lg-4 p-0">
        <div className="login">
          <div className="logo_img">
            <img src={Logo} alt="logo" />
          </div>
          <h1 className="title_text">Sign In</h1>
          <p className="welcome_text">
            Welcome back! Please login to your account.
          </p>
          {loginError !== "" && isCalled ===false ? (
            <p
              style={{
                color: "red",
              }}
              className="error_text active"
            >
              {loginError}
            </p>
          ) : (
            ""
          )}
          <form onSubmit={onLogin} data-test="login_form">
            <div className="form-group form_group text-center">
              <InputComponent
                formData={{
                  id: "user_email",
                  placeholder: "Email*",
                  onChange: onInputChange,
                  name: "email",
                  className: "form-control form_control",
                  type: "text",
                  "aria-describedby": "user_email",
                  value: loginForm.email.trim(),
                }}
              />
              <span className="error_text active" data-test="username_error">
                {formError && formError.email !== "" ? formError.email : ""}
              </span>
            </div>
            <div className="form-group form_group password_icon text-center">
              <InputComponent
                formData={{
                  placeholder: "Password*",
                  onChange: onInputChange,
                  className: "form-control form_control",
                  name: "password",
                  type: visible ? "text" : "password",
                  "data-test": "user_password",
                  value: loginForm.password,
                }}
              />
              <img
                src={visible ? eyeClose : eyeOpen}
                alt="eye-icon"
                onClick={on_password_visible}
              />
              <span className="error_text active" data-test="username_error">
                {formError && formError.password !== ""
                  ? formError.password
                  : ""}
              </span>
            </div>
            <button className="btn_login">LOGIN</button>
          </form>
          <div className="forgot_password">
            <a onClick={showModal}>Forgot password</a>
          </div>
          <Modal
            title={
              <>
                <div>
                  <h4 className="modal_title">
                    Forgot password? don't worry :)
                  </h4>
                </div>
                <div className="sub_text">
                  Just enter your registered email id
                </div>
              </>
            }
            visible={visibleModel}
            // onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={[
              <Button key="OTP" onClick={handleOk}>
                Send OTP
              </Button>,
            ]}
          >
            <div className="form-group form_group text-center">
              <InputFormComponent
                className="form-control form-control-lg mb-3"
                error={formErrors.emailID}
                formControl={{
                  placeholder: "Type the email",
                  name: "emailID",
                  value: emailID,
                  onChange: (e) => {
                    if (
                      !e.target.value.match(emailRegx) ||
                      !e.target.value === ""
                    ) {
                      setFormErrors({
                        ...formErrors,
                        emailID: "Please enter valid email",
                      });
                    } else {
                      setFormErrors({ ...formErrors, emailID: "" });
                    }
                    setEmailID(e.target.value);
                  },
                }}
              />
              <div
                style={{
                  marginTop: "0px",
                }}
              >
                <FormErrorText error={formErrors.emailID} />
              </div>
            </div>
          </Modal>

          <div className="privacy_policy">
            Terms & condition | Privacy Policy
          </div>
        </div>
      </div>
      <div className="col-lg-8 p-0">
        <div className="login_banner_img">
          <img src={BackGroundImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default withRouter(LoginComponent);
