import styles from "./Sidebar.module.css";
import "./Sidebar.module.css";
import logouticon from "../../assets/icons/logout_icon.svg";
import { useDispatch } from "react-redux";
import * as action from "../../Store/Actions/index";



export default function FooterComponent() {
  const dispatch = useDispatch();
  const logout = () => {
    sessionStorage.clear();
    dispatch(action.logout());
    window.location.href = "/login";
  };
  return (
    <div className={styles.admin_sidebar_footer}>
      {/* <img
         src={logouticon}
         onClick={() => logout()}
         alt="logout"
         title="Logout"
         width={40}
         height={40}
         align="center"
         style={{ cursor: "pointer" ,marginLeft:"45px"}}></img> */}
            <span>© 2009-2022 HGR Industrial Surplus. <br></br>All rights reserved.</span>
          </div>
  );
}
