import React, { useState, useEffect } from "react";
import styles from "../../styles/Warehouse.module.css";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification } from "antd";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import FormErrorText from "../FormErrorText";
import { userNameWithSpace } from "../RegularExp/RegularExp";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import validationRules from "../../validationRules";
import { isObject, isString } from "lodash";
import { useParams } from "react-router-dom";
import { emailRegx, passwordReg } from "../../RegularExp";
import SelectComponent from "../ReusableComponent/SelectComponent";

const initialFormState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  company: "",
  edit_password: "",
  role: "",
};
const role_value = [
  // { _id: 0, name: "Select option", value: "Select_option" },
  { _id: 1, name: "SUPER_ADMIN", value: "SUPER_ADMIN" },
  { _id: 2, name: "ADMIN", value: "ADMIN" },
];

export default function AddUserComponent() {
  const history = useHistory();
  const { id } = useParams();
  const [getRole, setGetRole] = useState(role_value);
  const [role_drop, setRoleDrop] = useState();
  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const company = JSON.parse(localStorage.getItem("companydata"));
  const onlyNumber1 = /^(?:0?[1-9]\d*(?:\.\d*[0-9]*)?)|(:?^0.[1-9]+0)$/;
  const [getCompany, setGetCompany] = useState([]);
  const [new_password, setNewPassword] = useState([]);
  const roledata = JSON.parse(localStorage.getItem("roledata"));
  const [companyID, setCompanyID] = useState("");

  const rules = {
    firstName: [validationRules.required],
    lastName: [validationRules.required],
    email: [validationRules.required],
    password: [validationRules.required],
    //company: [validationRules.required],
    role: [validationRules.required],
  };

  useEffect(() => {
    getCompanyDetail();
    if (id) {
      getUserDetails(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  function getCompanyDetail() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getCompanies/settingsSection?pageNum=1&count=20&sortField=createdAt&sortValue=DSC`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetCompany(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get company details",
        };
        showNotification(error);
        // return er;
      });
  }

  function getUserDetails(id) {
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`users/getUsers/${id}`)
      .then((res) => {
        if (res && res?.data && res?.status == "200") {
          const val = res.data;
          // console.log(val)
          setEditForm(val);
          if (id) {
            setRoleDrop(val?.role?.userGroup);
          }
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    let { name, value } = e.target;
    //console.log(name, value);

    if (name === "role") {
      setRoleDrop(value);
    }

    if (name === "company") {
      getCompany.map((company) => {
        if (value === company.code) {
          setCompanyID(company._id);
        }
      });
    }


    if (name === "firstName") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 3 and maximum 30)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "lastName") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 3 and maximum 30)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "password") {
      if (!e.target.value.match(passwordReg) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]:
            "At least 1 number, upper & lower case and special character and minimum 8 characters",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "edit_password") {
      if (!e.target.value.match(passwordReg) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]:
            "At least 1 number, upper & lower case and special character and minimum 8 characters",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "email") {
      // value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(emailRegx) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Please enter valid email",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }

    const updatedValues = { ...editForm };
    updatedValues[name] = value;
    setEditForm(updatedValues);
  }

  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  // console.log(editForm);

  function submit(e) {
    let data1 = JSON.parse(JSON.stringify(editForm));
    delete data1.createdAt;
    delete data1.updatedAt;
    delete data1.__v;
    delete data1.deleted;
    const result = validate(undefined, data1);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }
    let data = {
      firstName: editForm.firstName,
      lastName: editForm.lastName,
      email: editForm.email,
      password: editForm.password,
      companyId: editForm?.company?._id ? editForm?.company?._id : companyID,
      role: editForm?.role,
    };
    //console.log(editForm, "ccccccc");
    let new_data = {
      firstName: editForm.firstName,
      lastName: editForm.lastName,
      email: editForm.email,
      password: editForm.edit_password,
      companyId: editForm?.company?._id ? editForm?.company?._id : companyID,
      role: editForm?.role?.userGroup
        ? editForm?.role?.userGroup
        : editForm?.role,
    };
    //console.log(new_data);
    if (data.role === "ADMIN") {
      if (data.companyId === "") {
        setFormErrors({
          ...formErrors,
          company: "Required",
        });
        return false;
      }
    }
    if (data.role === "") {
      setFormErrors({
        ...formErrors,
        role: "Required",
      });
      return false;
    }
    if (data.password) {
      if (!passwordReg.test(data.password) || !data.password === "") {
        setFormErrors({
          ...formErrors,
          password:
            "At least 1 number, upper & lower case and special character and minimum 8 characters",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, password: "" });
      }
    }
    if (new_data.password) {
      if (!passwordReg.test(new_data.password) || !new_data.password === "") {
        setFormErrors({
          ...formErrors,
          password:
            "At least 1 number, upper & lower case and special character and minimum 8 characters",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, password: "" });
      }
    }
    if (data.email) {
      if (!emailRegx.test(data.email) || !data.email === "") {
        setFormErrors({
          ...formErrors,
          email: "Please enter valid email",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, email: "" });
      }
    }
    if (data.firstName) {
      if (!userNameWithSpace.test(data.firstName) || !data.firstName === "") {
        setFormErrors({
          ...formErrors,
          firstName: "Enter only alphabets (minimum 3 and maximum 30)",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, firstName: "" });
      }
    }
    if (data.lastName) {
      if (!userNameWithSpace.test(data.lastName) || !data.lastName === "") {
        setFormErrors({
          ...formErrors,
          lastName: "Enter only alphabets (minimum 3 and maximum 30)",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, lastName: "" });
      }
    }
    setLoading({ ...loading, update: true });

    if (id) {
      AxiosInstance(8000, "application/json")
        .put(`/users/updateUser/${id}`, new_data)
        .then((res) => {
          //   console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "User updated successfully",
            });
            history.push(`/settings/user`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Editing User failed",
              description: "Error occured",
            });
          }
        })
        .catch((er) => {
          if (er.response.status === 409) {
            //console.log(er.response.data);
            showNotification({
              type: "error",
              message: er.response.data.message,
              description: "Error occured",
            });
          } else {
            showNotification({
              type: "error",
              message: "User failed",
              description: "Error occured",
            });
          }
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    } else {
      AxiosInstance(8000, "application/json")
        .post(`/users/addUser`, data)
        .then((res) => {
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "User added successfully",
            });
            history.push(`/settings/user`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding User failed",
              description: "Error occured",
            });
          }
        })
        .catch((er) => {
          if (er.response.status === 409) {
            showNotification({
              type: "error",
              message: er.response.data.message,
              description: "Error occured",
            });
          } else {
            showNotification({
              type: "error",
              message: "Adding User failed",
              description: "Error occured",
            });
          }
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt="Back Arrow"
            className="cursor-pointer"
            onClick={() => history.push("/settings/user")}
          />
          {id ? "Edit User" : "Add User"}
        </h1>
      </div>
      {isLoading === true ? (
        // <tr className="bg-transparent d-flex justify-content-center">
        //   <td colSpan="100%">
        //     <LoadingIndicator loadingText="Fetching User detail..." />
        //   </td>
        // </tr>
        <span className="bg-transparent d-flex justify-content-center">
          <span colSpan="100%">
            <LoadingIndicator loadingText="Fetching User details..." />
          </span>
        </span>
      ) : (
        <div className={styles.management_details}>
          <div className={styles.management_details_contents}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  First Name
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.firstName}
                  formControl={{
                    placeholder: "Type the Building Name",
                    name: "firstName",
                    value: editForm?.firstName,
                    onChange: handleFormInput,
                  }}
                />

                <div
                
                >
                  <FormErrorText error={formErrors.firstName} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  Last Name
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.lastName}
                  formControl={{
                    placeholder: "Type the Last Name",
                    name: "lastName",
                    value: editForm?.lastName,
                    onChange: handleFormInput,
                  }}
                />
                <div
                
                >
                  <FormErrorText error={formErrors.lastName} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  Email
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.email}
                  formControl={{
                    placeholder: "Type the email",
                    name: "email",
                    value: editForm?.email,
                    onChange: handleFormInput,
                  }}
                />
                <div
                
                >
                  <FormErrorText error={formErrors.email} />
                </div>
              </div>
              {/*</div>
             <div className="row"> */}
              {id ? (
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                  <label className="theme-form-label">
                    Update Password
                    {/* <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span> */}
                  </label>
                  <InputFormComponent
                    className="form-control form-control-lg mb-3"
                    error={formErrors.edit_password}
                    formControl={{
                      placeholder: "Type the Password",
                      name: "edit_password",
                      value: editForm?.edit_password,
                      onChange: handleFormInput,
                    }}
                  />
                  <div
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <FormErrorText error={formErrors.edit_password} />
                  </div>
                </div>
              ) : (
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                  <label className="theme-form-label">
                    Password
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </label>
                  <InputFormComponent
                    className="form-control form-control-lg mb-3"
                    error={formErrors.password}
                    formControl={{
                      placeholder: "Type the Password",
                      name: "password",
                      value: editForm?.password,
                      onChange: handleFormInput,
                    }}
                  />
                  <div
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <FormErrorText error={formErrors.password} />
                  </div>
                </div>
              )}
              {/* </div>
            <div className="row"> */}
              {roledata?.userGroup === "SUPER_ADMIN" && (
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                  <label>
                    Role
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </label>
                  {/* <SelectComponent
                  error={formErrors.role}
                  className="form-control-md mb-3"
                  attributes={{
                    name: "role",
                    value: editForm?.role?.userGroup,
                    onChange: (e) => {
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                    Select option
                  </option>
                  {Array.isArray(getRole)
                    ? getRole.map((role) => (
                        <option key={role?.name} value={role?.name}>
                          {role?.name}
                        </option>
                      ))
                    : null}
                </SelectComponent> */}

                  <SelectComponent
                    error={formErrors.role}
                    className="form-control-md mb-3"
                    attributes={{
                      name: "role",
                      value: editForm?.role?.userGroup,
                      onChange: handleFormInput,
                    }}
                  >
                    <option value="">Select option</option>
                    {Array.isArray(getRole)
                      ? getRole.map((role) => (
                          <option key={role?.name} value={role?.name}>
                            {role?.name}
                          </option>
                        ))
                      : null}
                  </SelectComponent>
                  <FormErrorText error={formErrors.role} />
                </div>
              )}
              {/* {console.log(role_drop)} */}
              {(role_drop === "ADMIN" || roledata?.userGroup === "ADMIN") && (
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                  <label>
                    Site
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </label>
                  <SelectComponent
                    error={formErrors.company}
                    className="form-control-md mb-3"
                    attributes={{
                      name: "company",
                      value: editForm.company?.code,
                      onChange: (e) => {
                        handleFormInput(e);
                      },
                    }}
                  >
                    <option value="">Select option</option>
                    {Array.isArray(getCompany)
                      ? getCompany.map((company) => (
                          <option key={company?.code} value={company?.code}>
                            {company?.code}
                          </option>
                        ))
                      : null}
                  </SelectComponent>
                  <FormErrorText error={formErrors.company} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={styles.add_page_save_button}>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={submit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
