import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import handling from "../../assets/img/handling.svg";
import warehouse from "../../assets/img/warehouse.svg";
import apikey from "../../assets/img/apikey.svg";
import company from "../../assets/img/company.svg";
import logistics from "../../assets/img/logistics.svg";
import carriers from "../../assets/img/carriers.svg";
import freightclass from "../../assets/img/freightclass.svg";
import originLocation from "../../assets/img/origin location.svg";
import billingLocation from "../../assets/img/Billing Account.svg";
import accountDetails from "../../assets/img/Account Details.svg";
import { useHistory } from "react-router-dom";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";

const VendorAccountsComponent = (props) => {
  const history = useHistory();
  const roledata = JSON.parse(localStorage.getItem("roledata"));

  return (
    <section className="quote-details">
      <div className="">
        <div className="row d-flex">
          <h1 className="heading">
          <img
            src={BlackLeftArrow}
            alt="Back Arrow"
            className="cursor-pointer"
            onClick={() => history.push("/settings")}
           
          />{" "}Vendor Accounts</h1>
        </div>
      </div>
     
      <div className="settings-bg-wrapper">
        <div>
         
            <div className="row settings_margin_bottom">
                <div className="col-lg-4 ">
              <div className="settings-container d-flex text-center">
                <img
                  src={originLocation}
                  onClick={() => history.push("/settings/listOriginLocation")}
                  alt=""
                  className="settings-icon"
                  title="Vendor - Origin Location"
                  
                />
                <div className="customer-primary-description">
                  <h6
                    className="cursor-pointer"
                    style={{
                        marginTop: "0px", marginBottom: "0px !important"
                    }}
                    onClick={() => history.push("/settings/listOriginLocation")}
                  >
                    Vendor - Origin Location
                  </h6>
                  <div> <p className="description">
                  Add and Remove Origin Location
                  </p></div>
              </div>
                </div>
              
            </div>
            <div className="col-lg-4 ">
              <div className="settings-container d-flex text-center">
                <img
                  src={billingLocation}
                  onClick={() => history.push("/settings/listBillingLocation")}
                  alt=""
                  className="settings-icon"
                  title="Vendor - Billing Account"
                 
                />
                <div className="customer-primary-description">
                  <h6
                    className="cursor-pointer"
                    style={{
                        marginTop: "0px", marginBottom: "0px !important"
                    }}
                    onClick={() => history.push("/settings/listBillingLocation")}
                  >
                    Vendor - Billing Location
                  </h6>
                  <div> <p className="description">
                  Add and Remove Billing Account
                  </p></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="settings-container d-flex text-center">
                <img
                  src={accountDetails}
                  onClick={() => history.push("/settings/listAccountDetails")}
                  alt=""
                  className="settings-icon"
                  title="Vendor - Account Details"
                  
                />
                <div className="customer-primary-description">
                  <h6
                    className="cursor-pointer"
                    style={{
                        marginTop: "0px", marginBottom: "0px !important"
                    }}
                    onClick={() => history.push("/settings/listAccountDetails")}
                  >
                    Vendor - Account Details
                  </h6>
                  <div> <p className="description">
                  Add and Remove Account Details
                  </p></div>
                </div>
              </div>
            </div>
           
              
             
            </div>
        
        </div>
      </div>
    </section>
  );
};
export default withRouter(VendorAccountsComponent);
