import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import handling from "../../assets/img/handling.svg";
import warehouse from "../../assets/img/warehouse.svg";
import apikey from "../../assets/img/apikey.svg";
import company from "../../assets/img/company.svg";
import logistics from "../../assets/img/logistics.svg";
import carriers from "../../assets/img/carriers.svg";
import freightclass from "../../assets/img/freightclass.svg";
import vendor from "../../assets/img/vendor.svg";
import userManagement from "../../assets/img/userManagement.svg";
import { useDispatch } from "react-redux";
import logouticon from "../../assets/img/logout_icon.svg";
import * as action from "../../Store/Actions/index";

import { useHistory } from "react-router-dom";

const SettingComponent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const logout = () => {
    sessionStorage.clear();
    dispatch(action.logout());
    window.location.href = "/login";
  };
  const roledata = JSON.parse(localStorage.getItem("roledata"));

  return (
    <section className="quote-details">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex">
          <h1 className="heading">Settings</h1>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex flex-row-reverse logout">
          <img
         src={logouticon}
         onClick={() => logout()}
         alt="logout"
         title="Logout"
         width={25}
         height={25}
         align="center"
         style={{ cursor: "pointer" ,marginLeft:"45px"}}></img>
        </div>
        
      </div>

      <div className="settings-bg-wrapper">
        <div className="row ">
          <div className="col-lg-4 settings_margin_bottom ">
            <div className="settings-container">
              <div className=" d-flex text-left">
                <img
                  src={company}
                  onClick={() => history.push("/settings/company")}
                  alt=""
                  className="settings-icon"
                  title="Site"
                />
                <div className="customer-primary-description">
                  <h6
                    className="settings-heading"
                    onClick={() => history.push("/settings/company")}
                    style={{
                      marginTop: "10px",
                      marginBottom: "0px !important",
                    }}
                  >
                    Site
                  </h6>
                  <div>
                    {" "}
                    <p className="description">
                      Add and Remove Sites from the Shipping Service
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 settings_margin_bottom ">
            <div className="settings-container d-flex text-left">
              <img
                src={warehouse}
                onClick={() => history.push("/settings/warehouse")}
                alt=""
                className="settings-icon"
                title="Warehouse Collection"
              />
              <div className="customer-primary-description">
                <h6
                  className="settings-heading"
                  onClick={() => history.push("/settings/warehouse")}
                  style={{
                    marginTop: "10px",
                    marginBottom: "0px !important",
                  }}
                >
                  Warehouse Collection
                </h6>
                <div>
                  {" "}
                  <p className="description">
                    Add and Remove Warehouses by Site and Company
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 settings_margin_bottom ">
            <div className="settings-container d-flex text-left">
              <img
                src={apikey}
                onClick={() => history.push("/settings/apikey")}
                alt=""
                className="settings-icon"
                title="Cart API Key"
              />
              <div className="customer-primary-description">
                <h6
                  className="settings-heading"
                  onClick={() => history.push("/settings/apikey")}
                  style={{
                    marginTop: "10px",
                    marginBottom: "0px !important",
                  }}
                >
                  Cart API Key
                </h6>
                <div>
                  {" "}
                  <p className="description">API Key Generation</p>
                </div>
              </div>
            </div>
          </div>
      
       
          {roledata?.userGroup === "SUPER_ADMIN" && (
            <div className=" col-lg-4 settings_margin_bottom ">
              <div className="settings-container d-flex text-left">
                <img
                  src={logistics}
                  onClick={() => history.push("/settings/logistics")}
                  alt=""
                  className="settings-icon"
                  title="Logistics"
                />
                <div className="customer-primary-description">
                  <h6
                    className="settings-heading"
                    onClick={() => history.push("/settings/logistics")}
                    style={{
                      marginTop: "10px",
                      marginBottom: "0px !important",
                    }}
                  >
                    Logistics
                  </h6>
                  <div>
                    {" "}
                    <p className="description">
                      Add and Remove Logistics Vendors
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className=" col-lg-4 settings_margin_bottom ">
            <div className="settings-container d-flex text-left">
              <img
                src={vendor}
                onClick={() => history.push("/settings/vendorAccounts")}
                alt=""
                className="settings-icon"
                title="Vendor Accounts"
              />
              <div className="customer-primary-description">
                <h6
                  className="settings-heading"
                  onClick={() => history.push("/settings/vendorAccounts")}
                  style={{
                    marginTop: "10px",
                    marginBottom: "0px !important",
                  }}
                >
                  Vendor Accounts
                </h6>
                <div>
                  {" "}
                  <p className="description">
                    Vendor Account Settings and Vendor Management
                  </p>
                </div>
              </div>
            </div>
          </div>
          {roledata?.userGroup === "SUPER_ADMIN" && (
            <div className="col-lg-4 settings_margin_bottom ">
              <div className="settings-container d-flex text-left">
                <img
                  src={carriers}
                  onClick={() => history.push("/settings/carriers")}
                  alt=""
                  className="settings-icon"
                  title="Carriers"
                />
                <div className="customer-primary-description">
                  <h6
                    className="settings-heading"
                    onClick={() => history.push("/settings/carriers")}
                    style={{
                      marginTop: "10px",
                      marginBottom: "0px !important",
                    }}
                  >
                    Carriers
                  </h6>
                  <div>
                    {" "}
                    <p className="description">
                      Add and Remove Carriers and Service Codes
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
       
 
      
          <div className=" col-lg-4 ">
            <div className="settings-container d-flex text-left">
              <img
                src={handling}
                onClick={() => history.push("/settings/handling-charge")}
                alt=""
                className="settings-icon"
                title="Handling Charges"
              />
              <div className="customer-primary-description">
                <h6
                  className="settings-heading"
                  onClick={() => history.push("/settings/handling-charge")}
                  style={{
                    marginTop: "10px",
                    marginBottom: "0px !important",
                  }}
                >
                  Handling Charges
                </h6>
                <div>
                  {" "}
                  <p className="description">
                    Add and Remove Handling Fees for Parcel and Freight
                  </p>
                </div>
              </div>
            </div>
          </div>
          {roledata?.userGroup === "SUPER_ADMIN" && (
            <div className=" col-lg-4">
              <div className="settings-container d-flex text-left">
                <img
                  src={freightclass}
                  onClick={() => history.push("/settings/listFreightClass")}
                  alt=""
                  className="settings-icon"
                  title="Freight Class"
                />
                <div className="customer-primary-description">
                  <h6
                    className="settings-heading"
                    style={{
                      marginTop: "10px",
                      marginBottom: "0px !important",
                    }}
                    onClick={() => history.push("/settings/listFreightClass")}
                  >
                    Freight Class
                  </h6>
                  <div>
                    {" "}
                    <p className="description">
                      Add and Remove Freight Classes
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-lg-4">
            <div className="settings-container d-flex text-left">
              <img
                src={userManagement}
                onClick={() => history.push("/settings/user")}
                alt=""
                className="settings-icon"
                title="User"
              />
              <div className="customer-primary-description">
                <h6
                  className="settings-heading"
                  style={{
                    marginTop: "10px",
                    marginBottom: "0px !important",
                  }}
                  onClick={() => history.push("/settings/user")}
                >
                  User Management
                </h6>
                <div>
                  {" "}
                  <p className="description">Add and Remove Users</p>
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    </section>
  );
};
export default withRouter(SettingComponent);
