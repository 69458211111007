import React, { useState, useEffect } from "react";
import styles from "../../styles/Warehouse.module.css";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification } from "antd";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import SelectComponent from "../ReusableComponent/SelectComponent";
import FormErrorText from "../FormErrorText";
import { twoDigitWithFloat } from "../RegularExp/RegularExp";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import validationRules from "../../validationRules";
import { isObject, isString } from "lodash";
import { useParams } from "react-router-dom";

const initialFormState = {
  logisticCode: "",
  class: "",
  minDensity: "",
  maxDensity: "",
};

export default function AddFreightClassComponent() {
  const history = useHistory();
  const { id } = useParams();

  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [logistic, setLogistic] = useState([]);
  const company = JSON.parse(localStorage.getItem("companydata"));
  // const onlyNumbers = /^(?:0?[1-9]\d*(?:\.\d*[0-9]*)?)|(:?^0.[1-9]+0)$/;

  const rules = {
    logisticCode: [validationRules.required],
    class: [validationRules.required],
    minDensity: [validationRules.required],
    maxDensity: [validationRules.required],
  };

  useEffect(() => {
    getLogistics();
    if (id) {
      getFreightClassDetails(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  /**
   * @author Subhashree
   * GET LOGISTIC DETAILS
   */
  function getLogistics() {
    AxiosInstance(8000, "application/json")
      .get("logistics/getLogistics")
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setLogistic(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      });
  }

  function getFreightClassDetails(id) {
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`logistics/getFreightClass/${id}`)
      .then((res) => {
        //   console.log(res);
        if (res && res?.data && res?.status == "200") {
          const val = res.data;
          // console.log(val)
          setEditForm(val);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    let { name, value } = e.target;
    console.log(name, value);
    if (name === "logisticCode") {
      if (!value && value === "") {
        formErrors.logisticCode = "Required";
      } else {
        formErrors.logisticCode = "";
      }
    }
    if (name === "class") {
      value = value.trim();
      if (!value && value === "") {
        formErrors.class = "Required";
      } else if (!twoDigitWithFloat.test(value.trim()) && value.trim() !== "") {
        formErrors.class = "Please enter a valid number";
      } else {
        formErrors.class = "";
      }
    }
    if (name === "minDensity") {
      value = value.trim();
      if (!value && value === "") {
        formErrors.minDensity = "Required";
      } else if (!twoDigitWithFloat.test(value.trim()) && value.trim() !== "") {
        formErrors.minDensity = "Please enter a valid number";
      } else {
        formErrors.minDensity = "";
      }
    }
    if (name === "maxDensity") {
      value = value.trim();
      if (!value && value === "") {
        formErrors.maxDensity = "Required";
      } else if (!twoDigitWithFloat.test(value.trim()) && value.trim() !== "") {
        formErrors.maxDensity = "Please enter a valid number";
      } else {
        formErrors.maxDensity = "";
      }
    }

    const updatedValues = { ...editForm };
    updatedValues[name] = value;
    setEditForm(updatedValues);
  }

  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  // console.log(editForm);

  function submit(e) {
    //let data = JSON.parse(JSON.stringify(editForm));
    let data = {
      logisticCode: editForm?.logisticCode,
      class: Number(editForm?.class),
      minDensity: Number(editForm?.minDensity),
      maxDensity: Number(editForm?.maxDensity),
    };
    let data1 = [
      {
        class: Number(editForm.class),
        minDensity: Number(editForm?.minDensity),
        maxDensity: Number(editForm?.maxDensity),
      },
    ];
    delete data.createdAt;
    delete data.updatedAt;
    delete data.__v;
    delete data.deleted;
    const result = validate(undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }
    if (data.class) {
      if (!twoDigitWithFloat.test(data.class) || !data.class === "") {
        setFormErrors({
          ...formErrors,
          class: "Please enter a valid number",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, class: "" });
      }
    }
    if (data.minDensity) {
      if (!twoDigitWithFloat.test(data.minDensity) || !data.minDensity === "") {
        setFormErrors({
          ...formErrors,
          minDensity: "Please enter a valid number",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, minDensity: "" });
      }
    }
    if (data.maxDensity) {
      if (!twoDigitWithFloat.test(data.maxDensity) || !data.maxDensity === "") {
        setFormErrors({
          ...formErrors,
          maxDensity: "Please enter a valid number",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, maxDensity: "" });
      }
    }

    const isEmpty = Object.values(formErrors).every(
      (x) => x === null || x === ""
    );
    if (isEmpty) {
      setLoading({ ...loading, update: true });
      // console.log(data)
      // console.log(data1)
      //return
      if (id) {
        AxiosInstance(8000, "application/json")
          .put(`/logistics/updateFreightClass/${id}`, data)
          .then((res) => {
            //   console.log(res);
            if (res.status === 200) {
              showNotification({
                type: "success",
                message: "Success",
                description: "Freight Class updated successfully",
              });
              history.push(`/settings/listFreightClass`);
            } else if (res.status === 400) {
              showNotification({
                type: "error",
                message: "Editing Freight Class failed",
                description: "Error occured",
              });
            }
          })
          .catch((er) => {
            if (er.response.status === 409) {
              //console.log(er.response.data);
              showNotification({
                type: "error",
                message: er.response.data.message,
                description: "Error occured",
              });
            } 
            else{
            showNotification({
              type: "error",
              message: "Freight Class failed",
              description: "Error occured",
            });
          }
          })
          .finally(() => {
            setLoading({ details: false, update: false });
          });
      } else {
        AxiosInstance(8000, "application/json")
          .post(
            `/logistics/addFreightClassesToLogistic/${editForm?.logisticCode}`,
            data1
          )
          .then((res) => {
            //   console.log(res);
            if (res.status === 200) {
              showNotification({
                type: "success",
                message: "Success",
                description: "Freight Class added successfully",
              });
              history.push(`/settings/listFreightClass`);
            } else if (res.status === 400) {
              showNotification({
                type: "error",
                message: "Adding Freight Class failed",
                description: "Error occured",
              });
            }
          })
          .catch((er) => {
            if (er.response.status === 409) {
              //console.log(er.response.data);
              showNotification({
                type: "error",
                message: er.response.data.message,
                description: "Error occured",
              });
            } 
            else{
            showNotification({
              type: "error",
              message: "Adding Freight Class failed",
              description: "Error occured",
            });
          }
          })
          .finally(() => {
            setLoading({ details: false, update: false });
          });
      }
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt="Back Arrow"
            className="cursor-pointer"
            onClick={() => history.push("/settings/listFreightClass")}
          />
          {id ? "Edit Freight Class" : "Add Freight Class"}
        </h1>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching Freight Class detail..." />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>
          <div className={styles.management_details_contents}>
            <div className="row">
              <div className="col-lg-6 mb-4">
                <label>
                  Logistics Name
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors?.logisticCode}
                  className="form-control-md mb-3"
                  aria-label="logisticCode"
                  attributes={{
                    name: "logisticCode",
                    value: editForm?.logisticCode,
                    onChange: (e) => {
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                    Select option
                  </option>
                  {Array.isArray(logistic)
                    ? logistic.map((logi) => (
                        <option key={logi._id} value={logi.code}>
                          {logi.code}
                        </option>
                      ))
                    : null}
                </SelectComponent>
                <div>
                  <FormErrorText error={formErrors.logisticCode} />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="theme-form-label">
                  Freight Class
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.class}
                  aria-label="freightclass"
                  formControl={{
                    placeholder: "Type the Freight Class",
                    name: "class",
                    value: editForm?.class,
                    onChange: handleFormInput,
                  }}
                />
                <div>
                  <FormErrorText error={formErrors.class} />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="theme-form-label">
                  Minimum Density
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.minDensity}
                  formControl={{
                    placeholder: "Type the Minimum Density",
                    name: "minDensity",
                    "aria-label": "minDensity",
                    value: editForm?.minDensity,
                    onChange: handleFormInput,
                  }}
                />
                <div>
                  <FormErrorText error={formErrors.minDensity} />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="theme-form-label">
                  Maximum Density
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3 "
                  error={formErrors.maxDensity}
                  formControl={{
                    placeholder: "Type the Maximum Density",
                    name: "maxDensity",
                    value: editForm?.maxDensity,
                    "aria-label": "maxDensity",
                    onChange: handleFormInput,
                  }}
                />
                <div>
                  <FormErrorText error={formErrors.maxDensity} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.add_page_save_button}>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={submit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
