import React from "react";
import commonstyles from "../../styles/SettingsTable.module.css";
import deleteIcon from "../../assets/img/delete.svg";
import editIcon from "../../assets/img/edit.svg";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { AxiosInstance } from "../../utilities";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import { Modal, notification } from "antd";
import "antd/dist/antd.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import BackArrow from "../../assets/icons/back_arrow.svg";
import SelectComponent from "../ReusableComponent/SelectComponent";
import arrowDown from "../../assets/images/arrow_down.svg";
import Switch from "react-switch";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

function CarrierList() {
  const history = useHistory();
  const [regionList, setRegionList] = useState([]);
  // const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setpagination] = useState({});
  const [IsLoading, setIsLoading] = useState(false);
  const [logisticCode, setLogisticCode] = useState("");
  const company = JSON.parse(localStorage.getItem("companydata"));
  const { confirm } = Modal;
  const [logistic, setLogistic] = useState([]);
  const [checked, setChecked] = useState(false);
  const [toggle_status,setToggleStatus] = useState(false);
  const [columns, setColumns] = useState([
    /* {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    }, */
    {
      title: "Logistic",
      visible: false,
      key: "logisticCode",
      render: (text, record, index) => (
        <span>{record?.logisticCode ? record?.logisticCode : "-"}</span>
      ),
    },
    {
      title: "Carrier Name",
      visible: false,
      key: "serviceName",
      render: (text, record, index) => (
        <span>{record?.serviceName ? record?.serviceName : "-"}</span>
      ),
    },
    {
      title: "Carrier Method",
      visible: false,
      key: "serviceMethod",
      render: (text, record, index) => (
        <span>{record?.serviceMethod ? record?.serviceMethod : "-"}</span>
      ),
    },
    {
      title: "Code",
      visible: false,
      key: "serviceCode    ",
      render: (text, record, index) => (
        <span>{record?.serviceCode ? record?.serviceCode : "-"}</span>
      ),
    },
    {
      title: "Status",
      visible: false,
      key: "status",
      render: (text, record, index) => (
        <div>
      <Switch
          checked={record?.status === false ? checked : "disabled"}
          onChange={(e) => handleToggle(e, record)}    
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={16}
          width={35}
          className="react-switch"
          uncheckedIcon={false}
          checkedIcon={false}
  />
    </div>
    
      ),
    },
    // {
    //   title: "Action",
    //   visible: false,
    //   key: "Action",
    //   render: (text, record, index) => (
    //     <>
    //       <NavLink to={`/settings/editCarriers/${record._id}`}>
    //         <img
    //           src={editIcon}
    //           className={`${commonstyles.admin_table_edit_img} mr-2`}
    //           onClick={() => editShip(record)}
    //           alt="Edit Carriers Details"
    //           title="Edit carriers"
    //           style={{ cursor: "pointer" }}
    //           data-testid="editIcon"
    //         />
    //       </NavLink>
    //       <img
    //         src={deleteIcon}
    //         className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
    //         onClick={() => showDeleteConfirm(record._id)}
    //         alt="Delete Carriers"
    //         title="Delete carriers"
    //         data-testid="deleteIcon"
    //       />
    //     </>
    //   ),
    // },
  ]);

  const handleToggle = (e, record) => {
    setChecked(e);
    let data={
      logisticCode: record.logisticCode,
    serviceName: record.serviceName,
    serviceMethod: record.serviceMethod,
    serviceCode: record.serviceCode,
      status: e,
    }
    if (record._id) {
    AxiosInstance(8000, "application/json")
        .put(`logistics/updateCarrier/${record._id}`, data)
        .then((res) => {
          //console.log(res.data);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Carrier Status updated successfully",
            });
            //getCarriersList(pageSize, nextPage, "",true);
            setToggleStatus(true);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding Carrier Status failed",
              description: "Error occured",
            });
            setToggleStatus(false);
          }
        })
        .catch((error) => {
          showNotification({
            type: "error",
            message: "Carrier Status failed",
            description: error?.response?.data?.message,
          });
          setToggleStatus(false);
        })
      }
  };

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }
  // useEffect(() => {
  //   getLogistics();
  // }, []);
  // useEffect(() => {
  //   getCarriersList(pageSize, nextPage, "");
  //     localStorage.removeItem("selectedIds");
  //     setIsLoading(true);
  //     CarrierListTestData?.data.forEach((item, indx) => {
  //       item["key"] = indx + 1;
  //       item["isChecked"] = false;
  //     });
  //     localStorage.setItem(
  //       "regionList",
  //       JSON.stringify(CarrierListTestData?.data)
  //     );
  //     setRegionList(CarrierListTestData?.data);
  //     setpagination(CarrierListTestData?.pagination);
  //     setIsLoading(false);

  // }, [CarrierListTestData]);

  useEffect(() => {
    getLogistics();
    getCarriersList(pageSize, nextPage, "");
    localStorage.removeItem("selectedIds");

    return () => {
      localStorage.removeItem("regionList");
      localStorage.removeItem("selectedIds");
    };
  }, []);

  useEffect(() => {
    if(toggle_status && nextPage)
    {
      getCarriersList(pageSize, nextPage, logisticCode,true);
      setToggleStatus(false);
    }
    
  }, [nextPage,toggle_status]);

  function getLogistics() {
    AxiosInstance(8000, "application/json")
      .get("logistics/getLogistics")
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setLogistic(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      });
  }

  // *** FETCH REGION LIST *** //
  function getCarriersList(pageSize, nextPage, filterarr, type) {
    // setIsLoading(false);
    //console.log("get",nextPage);
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };
    const company = JSON.parse(localStorage.getItem("companydata"));
    AxiosInstance(8000, "application/json")
      .get(
        `logistics/getCarriers?pageNum=${nextPage}&count=${pageSize}&sortField=createdAt&sortValue=DSC&logisticCode=${filterarr}`,
        filterOptions
      )
      .then((res) => {
        // console.log(filterOptions);
        if (res && res.data && res.status == "200") {
          if (res.data.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // console.log("sssssssssss",res.data,nextPage)
            localStorage.setItem("regionList", JSON.stringify(res.data.data));
            setRegionList(res.data.data);
            setpagination(res.data.pagination);
          } else {
            setRegionList([]);
            setpagination({});
          }
        }
      })
      .catch((er) => {
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get Carriers",
        };
        showNotification(error);
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }

  function addCarriers() {
    history.push(`/settings/addCarriers`);
  }

  // *** EDIT Carriers FUNCTION *** //
  function editShip(record) {
    // console.log(record);
    history.push({
      pathname: `/settings/editCarriers/${record._id}`,
      state: record,
    });
  }

  // DELETE Carriers
  function showDeleteConfirm(id) {
    // console.log(id);
    confirm({
      title: "Are you sure want to delete this ?",
      icon: <ExclamationCircleOutlined />,
      // content: "Some descriptions",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        deleteCarriers(id);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  }

  function deleteCarriers(id) {
    // console.log(id);
    setIsLoading(true);
    AxiosInstance(8000, "application/json")
      .delete(`logistics/removeCarrier/${id}`)
      .then((res) => {
        // console.log(res);
        if (res && res?.data && res?.status == "200") {
          showNotification({
            type: "success",
            message: "Success",
            description: "Carriers deleted successfully",
          });
          setIsLoading(false);
          getCarriersList(pageSize, nextPage, "");
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Failed to delete Carriers",
          });
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function debounceSearch(filter) {
    // console.log(filter);
    setIsLoading(true);
    setNextPage(1);
    getCarriersList(pageSize, 1, filter);
  }

  function handlepaginationChanges(value, type) {
    let filter = "";
    // console.log("pagina",value,type,logisticCode);
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getCarriersList(value, 1, logisticCode);
    } else if (type === "nextPage") {
      setNextPage(value);
      getCarriersList(pageSize, value, logisticCode);
    }
  }

  function handleFormInput(e) {
    let { name, value } = e.target;
    // const updatedValues = { ...logisticCode };
    // updatedValues[name] = value;
    setLogisticCode(value);
    getCarriersList(pageSize, 1, value);
    setNextPage(1);
  }
  return (
    <div className={commonstyles.management_contents}>
      <div className="">
        <div className="row d-flex">
          <div className="col-lg-6 back_arrow">
            <img
              src={BackArrow}
              onClick={() => history.push("/settings")}
              alt=""
              className="cursor-pointer"
              title="back"
              width={"25px"}
              height={"21px"}
            />
            <h1 className="heading">Carriers</h1>
          </div>
          <div className="col-lg-6 d-flex flex-row-reverse">
            <div className="ant-dropdown-link">
              <SelectComponent
                className="form-control-md mb-3 theme-form-control-filter"
                attributes={{
                  name: "logisticCode",
                  value: logisticCode,
                  onChange: (e) => {
                    handleFormInput(e);
                  },
                }}
              >
                <option value="">All</option>
                {Array.isArray(logistic)
                  ? logistic
                      .sort((a, b) => (a.code > b.code ? 1 : -1))
                      .map((logi) => (
                        <option key={logi._id} value={logi.code}>
                          {logi.code}
                        </option>
                      ))
                  : null}
              </SelectComponent>
              <img src={arrowDown} className={commonstyles.dropDownArrowList} />
            </div>
          </div>
        </div>
      </div>
      <div className={commonstyles.management_header}>
        <div className="">
          <div className="">
            {/* <button
              type="button"
              className="btn btn-outline-primary"
              onClick={addCarriers}
              data-testid="add_button"
            >
              Add Carriers
            </button> */}
          </div>
        </div>
      </div>
      <div>
        <div
          className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
        ></div>
        <div className={`${commonstyles.management_table} table-responsive`}>
          {IsLoading === true ? (
            <div
              style={{
                marginLeft: "70vh",
                marginTop: "10vh",
              }}
            >
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching Carriers Details..." />
                </td>
              </tr>
            </div>
          ) : (
            <AntTableComponent
              className="order_table"
              tableProps={{
                columns: columns,
                dataSource: regionList,
                pagination: false,
              }}
            />
          )}
        </div>
      </div>
      {!IsLoading.list && Array.isArray(regionList) && regionList.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          handlepaginationChanges={handlepaginationChanges}
        />
      ) : null}
    </div>
  );
}

export default CarrierList;
