import React, { useState, useEffect } from "react";
import styles from "../../styles/Warehouse.module.css";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification } from "antd";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import FormErrorText from "../FormErrorText";
import {
  passwordReg,
  userNameWithSpace,
  allCharWithSpaceInbt,
} from "../RegularExp/RegularExp";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import validationRules from "../../validationRules";
import { isObject, isString, isEqual } from "lodash";
import { Link, useParams } from "react-router-dom";
import eyeOpen from "../../assets/icons/eye_icon.svg";
import eyeClose from "../../assets/icons/eye_close.svg";
import SelectComponent from "../ReusableComponent/SelectComponent";

const initialFormState = {
  username: "",
  password: "",
  accountNo: "",
  apiKey: "",
  displayName: "",
  companyId: "",
  logisticCode: "",
};

export default function AddAccountDetails() {
  const history = useHistory();
  const { id } = useParams();
  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [getCompany, setGetCompany] = useState([]);
  const [getLogistic, setGetLogistic] = useState([]);
  // const [getlogisticData, setGetLogisticData] = useState("");
  const [companyIDCode, setCompanyIDCode] = useState("");
  // const company = JSON.parse(localStorage.getItem("companydata"));

  const rules = {
    username: [validationRules.required],
    password: [validationRules.required],
    accountNo: [validationRules.required],
    apiKey: [validationRules.required],
    displayName: [validationRules.required],
    companyId: [validationRules.required],
    logisticCode: [validationRules.required],
  };

  useEffect(() => {
    getCompanyDetail();
    getLogistics();
    if (id) {
      getAccountDetails(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getLogistics();
  }, [editForm?.companyId]);

  
  function getAccountDetails(id) {
    //  console.log(id);
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`companies/getAccountDetail/${id}`)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          const val = res.data;
          console.log(val?.company?.code, "log");
          // setEditForm(val);
          // setGetLogisticData(val?.companyId?.code);
          setEditForm({
            username: val?.username,
            password: val?.password,
            accountNo: val?.accountNo,
            apiKey: val?.apiKey,
            displayName: val?.displayName,
            companyId: val?.company?.code,
            logisticCode: val?.logisticCode,
          });
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  function getLogistics(_id) {
    // var companyCode = e.target.value
    // console.log(getlogisticData);
    AxiosInstance(8000, "application/json")
      .get(`companies/getCompanyLogistics/${editForm?.companyId}`)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetLogistic(res.data);
          // console.log(res.data);
        }
      })
      .catch((er) => {
        // return er;
      });
  }

  // ***FORM INPUT HANDLE CHANGES *** //
  function handleFormInput(e) {
    let { name, value } = e.target;
    // console.log(name, value);


    if (name === "companyId") {
      getCompany.map((company) => {
        if (value === company.code) {
          console.log(company?._id);
          setCompanyIDCode(company?._id);
        }
      });
      if (!value && value === "") {
        formErrors.companyId = "Required";
      } else {
        formErrors.companyId = "";
      }

      setTimeout(() => {
        // setEditForm([]);
        setEditForm({
          username: editForm?.username,
            password: editForm?.password,
            accountNo: editForm?.accountNo,
            apiKey: editForm?.apiKey,
            displayName: editForm?.displayName,
            companyId: value,
            logisticCode: "" || null,
        });

        // setIsLoading(false);
      }, 1000);
    }else if (name === "username") {
      value = value.trim();
      if (e.target.value.length > 0 && e.target.value.length < 3) {
        setFormErrors({
          ...formErrors,
          [name]: "Enter minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }else if (name === "password") {
      if (e.target.value.length > 0 && e.target.value.length < 3) {
        setFormErrors({
          ...formErrors,
          [name]: "Enter minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }else if (name === "accountNo") {
      value = value.trim();
      if (e.target.value.length > 0 && e.target.value.length < 3) {
        setFormErrors({ ...formErrors, [name]: "Enter minimum 3" });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }else if (name === "apiKey") {
      value = value.trim();
      if (e.target.value.length > 0 && e.target.value.length < 3) {
        setFormErrors({ ...formErrors, [name]: "Enter minimum 3" });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }else if (name === "displayName") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 3 and maximum 30)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }else if (name === "logisticCode") {
      if (!value && value === "") {
        formErrors.logisticCode = "Required";
      } else {
        formErrors.logisticCode = "";
        getLogistics(value);
      }
    }

    const updatedValues = { ...editForm };
    updatedValues[name] = value;
    setEditForm(updatedValues);
  }

  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    data = data || {};
    const result = {};

    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation here
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  function submit(e) {
    let data1 = JSON.parse(JSON.stringify(editForm));

    const result = validate(undefined, data1);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }

    let data = {
      username: editForm.username,
      password: editForm.password,
      accountNo: editForm.accountNo,
      apiKey: editForm.apiKey,
      displayName: editForm.displayName,
      companyId: editForm?.companyId?._id ?editForm?.companyId?._id : companyIDCode,
      logisticCode: editForm.logisticCode,
    };
    console.log(editForm);

    console.log(data);
    if (data.username) {
      // console.log(data.username.length);
      if (data.username.length > 0 && data.username.length < 3) {
        setFormErrors({
          ...formErrors,
          username: "Enter minimum 3",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, username: "" });
      }
    }

    if (data.password) {
      if (data.password.length > 0 && data.password.length < 3) {
        setFormErrors({
          ...formErrors,
          password: "Enter minimum 3",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, password: "" });
      }
    }

    if (data.accountNo) {
      if (data.accountNo.length > 0 && data.accountNo.length < 3) {
        setFormErrors({ ...formErrors, accountNo: "Enter minimum 3" });
        return false;
      } else {
        setFormErrors({ ...formErrors, accountNo: "" });
      }
    }

    if (data.apiKey) {
      if (data.apiKey.length > 0 && data.apiKey.length < 3) {
        setFormErrors({ ...formErrors, apiKey: "Enter minimum 3" });
        return false;
      } else {
        setFormErrors({ ...formErrors, apiKey: "" });
      }
    }
    if (data.displayName) {
      if (
        !userNameWithSpace.test(data.displayName) ||
        !data.displayName === ""
      ) {
        setFormErrors({
          ...formErrors,
          displayName: "Enter only alphabets (minimum 3 and maximum 30)",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, displayName: "" });
      }
    }
    if (data.companyId) {
      if (
        !allCharWithSpaceInbt.test(data.companyId) ||
        !data.companyId === ""
      ) {
        setFormErrors({ ...formErrors, companyId: "Required" });
        return false;
      } else {
        setFormErrors({ ...formErrors, companyId: "" });
      }
    }
    if (data.logisticCode) {
      if (
        !allCharWithSpaceInbt.test(data.logisticCode) ||
        !data.logisticCode === ""
      ) {
        setFormErrors({ ...formErrors, logisticCode: "Required" });
        return false;
      } else {
        setFormErrors({ ...formErrors, logisticCode: "" });
      }
    }

    setLoading({ ...loading, update: true });

    // console.log(result);
    //return
    if (id) {
      AxiosInstance(8000, "application/json")
        .put(`companies/updateAccountDetail/${id}`, data)
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "account updated successfully",
            });
            history.push(`/settings/listAccountDetails`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding account failed",
              description: "Error occured",
            });
          }
        })
        .catch((error) => {
          showNotification({
            type: "error",
            message: "Account failed",
            description: error?.response?.data?.message,
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    } else {
      AxiosInstance(8000, "application/json")
        .post(`companies/addAccountDetail`, data)
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "account added successfully",
            });

            history.push(`/settings/listAccountDetails`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding account failed",
              description: "Error occured",
            });
          }
        })
        .catch((error) => {
          showNotification({
            type: "error",
            message: "Account failed",
            description: error?.response?.data?.message,
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    }
  }

  function getCompanyDetail() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getCompanies/settingsSection?pageNum=1&count=20&sortField=createdAt&sortValue=DSC`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetCompany(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get company details",
        };
        showNotification(error);
        // return er;
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt="Back Arrow"
            className="cursor-pointer"
            onClick={() => history.push("/settings/listAccountDetails")}
          />
          {id ? "Edit Account" : "Add Account"}
        </h1>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching Account detail..." />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>
          <div className={styles.management_details_contents}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label>
                  Site
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.companyId}
                  className="form-control-md mb-3"
                  data-testid="companyOption"
                  aria-label="companyOption"
                  attributes={{
                    name: "companyId",
                    value: editForm?.companyId,
                    onChange: handleFormInput,
                  }}
                >
                  <option value="">Select option</option>
                  {Array.isArray(getCompany)
                    ? getCompany.map((company) => (
                        <option key={company?.code} value={company?.code}>
                          {company?.code}
                        </option>
                      ))
                    : null}
                </SelectComponent>
                <div>
                  <FormErrorText error={formErrors.companyId} />
                </div>
              </div>
              {editForm?.companyId && (
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                  <label>
                    Logistics Name
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </label>
                  <SelectComponent
                    error={formErrors.logisticCode}
                    className="form-control-md mb-3"
                    attributes={{
                      name: "logisticCode",
                      value: editForm.logisticCode,
                      onChange: (e) => {
                        handleFormInput(e);
                      },
                    }}
                  >
                    <option value="">Select option</option>
                    {Array.isArray(getLogistic)
                      ? getLogistic.map((logistic) => (
                          <option
                            key={logistic?.code}
                            value={logistic?.code}
                          >
                            {/* {console.log(logistic)} */}
                            {logistic?.code}
                          </option>
                        ))
                      : null}
                  </SelectComponent>
                  <FormErrorText error={formErrors.logisticCode} />
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  User Name
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.username}
                  formControl={{
                    placeholder: "Type the user name",
                    name: "username",
                    value: editForm?.username,
                    onChange: handleFormInput,
                  }}
                />
                <div data-testid="username_err">
                  <FormErrorText error={formErrors.username} />
                </div>
                {/* <div>
                  <FormErrorText error={formErrors.username} />
                </div> */}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  Password
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.password}
                  formControl={{
                    type: isRevealPwd ? "text" : "password",
                    placeholder: "Type the password",
                    name: "password",
                    value: editForm?.password,
                    onChange: handleFormInput,
                  }}
                />
                <img
                  title={isRevealPwd ? "Hide password" : "Show password"}
                  src={isRevealPwd ? eyeClose : eyeOpen}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  alt="eye_icon"
                  className={styles.password_eye_icon}
                />
                <div data-testid="password_err">
                  <FormErrorText error={formErrors.password} />
                </div>
                {/* <div>
                  <FormErrorText error={formErrors.password} />
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  Account Number
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.accountNo}
                  formControl={{
                    placeholder: "Type the account number",
                    name: "accountNo",
                    value: editForm?.accountNo,
                    onChange: handleFormInput,
                  }}
                />
                <div data-testid="accountNo_err">
                  <FormErrorText error={formErrors.accountNo} />
                </div>
                {/* <div>
                  <FormErrorText error={formErrors.accountNo} />
                </div> */}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  API Key
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.apiKey}
                  formControl={{
                    placeholder: "Type the Cart API Key",
                    name: "apiKey",
                    value: editForm?.apiKey,
                    onChange: handleFormInput,
                  }}
                />
                <div data-testid="apiKey_err">
                  <FormErrorText error={formErrors.apiKey} />
                </div>
                {/* <div>
                  <FormErrorText error={formErrors.apiKey} />
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  Description
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.displayName}
                  formControl={{
                    placeholder: "Type the Display Name",
                    name: "displayName",
                    value: editForm?.displayName,
                    onChange: handleFormInput,
                  }}
                />
                <div data-testid="displayName_err">
                  <FormErrorText error={formErrors.displayName} />
                </div>
                {/* <div>
                  <FormErrorText error={formErrors.displayName} />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.add_page_save_button}>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={submit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
