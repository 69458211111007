import React, { useState, useEffect } from "react";
import styles from "../../styles/Warehouse.module.css";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification } from "antd";
import FormErrorText from "../FormErrorText";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import validationRules from "../../validationRules";
import { isObject, isString, isEqual } from "lodash";
import { Link, useParams } from "react-router-dom";
import SelectComponent from "../ReusableComponent/SelectComponent";

const initialFormState = {
  code: "",
};

export default function GenarateNewKey() {
  const history = useHistory();
  const { id } = useParams();
  const [editForm, setEditForm] = useState(initialFormState);
  const [getCompany, setGetCompany] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);

  const rules = {
    code: [validationRules.required],
  };

  useEffect(() => {
      getCompanyDetail();
      setIsLoading(false);
  }, []);

  /**
   * @author Prasanthu
   * GET COMPANY DETAILS
   */
   function getCompanyDetail() {
    AxiosInstance(8000, "application/json")
      .get(`companies/getCompanies/settingsSection?pageNum=1&count=20&sortField=createdAt&sortValue=DSC`)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetCompany(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
            type: "error",
            message: "Error",
            description: "Failed to get company details",
          };
          showNotification(error);
        // return er;
      });
  }

  // ***FORM INPUT HANDLE CHANGES *** //
  function handleFormInput(e) {
    let { name, value } = e.target;
    // console.log(name, value);
    if (name === "code") {
        if (!value && value === "") {
          formErrors.code = "Required";
        } else {
          formErrors.code = "";
        }
      }

    const updatedValues = { ...editForm };
    updatedValues[name] = value;
    setEditForm(updatedValues);
  }

  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    data = data || {};
    const result = {};

    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation here
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  function submit(e) {
    let data = JSON.parse(JSON.stringify(editForm));
    // console.log(data);
    // console.log(editForm);

    const result = validate(undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }

    setLoading({ ...loading, update: true });

      AxiosInstance(8000, "application/json")
        .post(`apikeys/generateNewApiKey/${editForm.code}`)
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "New Cart API Key generated successfully",
            });

            history.push(`/settings/apikey`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "New Cart API Key failed",
              description: "Error occured" || res.message,
            });
          }
        })
        .catch((error) => {
          showNotification({
            type: "error",
            message: "Cart Api key failed",
            description: error?.response?.data?.message,
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    }
  

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt="Back Arrow"
            className="cursor-pointer"
            onClick={() => history.push("/settings/apikey")}
          />
          Generate New Key
        </h1>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching Cart API Key detail..." />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>
          <div className={styles.management_details_contents}>
            <div className="row">
            <div className="col-lg-6 mb-4">
                <label>
                 Site
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.code}
                  className="form-control-md mb-3"
                  attributes={{
                    name: "code",
                    value: editForm.code,
                    onChange: (e) => {
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                    Select site
                  </option>
                  {Array.isArray(getCompany)
                    ? getCompany.map((company) => (
                        <option key={company.code} value={company.code}>
                          {company.code}
                        </option>
                      ))
                    : null}
                </SelectComponent>
                <div
                
                >
                  <FormErrorText error={formErrors.code} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.add_page_save_button}>
        <button type="button" className="btn btn-outline-primary" onClick={submit}>
          Generate key
        </button>
      </div>
    </div>
  );
}
