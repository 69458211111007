import React from "react";
import commonstyles from "../../styles/SettingsTable.module.css";
import deleteIcon from "../../assets/img/delete.svg";
import editIcon from "../../assets/img/edit.svg";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { AxiosInstance } from "../../utilities";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import { Modal, notification } from "antd";
import "antd/dist/antd.css";
import {
  ExclamationCircleOutlined,
  FastBackwardFilled,
} from "@ant-design/icons";
import BackArrow from "../../assets/icons/back_arrow.svg";
import SelectComponent from "../ReusableComponent/SelectComponent";
import arrowDown from "../../assets/images/arrow_down.svg";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

function FreightClassList({ freightListTestData }) {
  const history = useHistory();
  const [regionList, setRegionList] = useState([]);
  // const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setpagination] = useState({});
  const [IsLoading, setIsLoading] = useState(false);
  const [logistic, setLogistic] = useState([]);
  const company = JSON.parse(localStorage.getItem("companydata"));
  const { confirm } = Modal;
  const [logisticCode, setLogisticCode] = useState([]);

  const [columns, setColumns] = useState([
    /* {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    }, */
    {
      title: "Logistics Name",
      visible: false,
      key: "logisticCode",
      render: (text, record, index) => (
        <span>{record?.logisticCode ? record?.logisticCode : "-"}</span>
      ),
    },
    {
      title: "Freight Class",
      visible: false,
      key: "class",
      render: (text, record, index) => (
        <span>{record?.class ? record?.class : "-"}</span>
      ),
    },
    {
      title: "Minimum Density",
      visible: false,
      key: "minDensity",
      render: (text, record, index) => (
        <span>{record?.minDensity ? record?.minDensity : "-"}</span>
      ),
    },
    {
      title: "Maximum Density",
      visible: false,
      key: "maxDensity",
      render: (text, record, index) => (
        <span>{record?.maxDensity ? record?.maxDensity : "-"}</span>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) => (
        <>
          {/* <NavLink to={`/settings/editFreightClass/${record._id}`}> */}
          <img
            src={editIcon}
            className={`${commonstyles.admin_table_edit_img} mr-2`}
            onClick={() => editShip(record)}
            alt="View Freight Class details"
            title="Edit freight class"
            style={{ cursor: "pointer" }}
            data-testid="editIcon"
          />
          {/* <div data-testid="editIcon">{formErrors?"Clickable":""}</div> */}
          {/* </NavLink> */}
          <img
            src={deleteIcon}
            className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
            onClick={() => showDeleteConfirm(record._id)}
            aria-label="deleteIcon"
            alt="Delete Freight Class"
            title="Delete freight class"
            data-testid="deleteIcon"
          />
        </>
      ),
    },
  ]);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  // useEffect(() => {
  //   getLogistics();
  //   getFreightClasss(pageSize, nextPage, "");
  //   localStorage.removeItem("selectedIds");
  //   return () => {
  //     localStorage.removeItem("regionList");
  //     localStorage.removeItem("selectedIds");
  //   };
  // }, []);

  // *** THIS FUNCTION WILL TRIGGER WHITE RUNNING UNIT TEST *** //
  useEffect(() => {
    setIsLoading(true);
    freightListTestData?.data.forEach((item, indx) => {
      item["key"] = indx + 1;
      item["isChecked"] = false;
    });
    localStorage.setItem(
      "freightList",
      JSON.stringify(freightListTestData?.data)
    );
    setRegionList(freightListTestData?.data);
    setpagination(freightListTestData?.pagination);
    setIsLoading(false);
  }, [freightListTestData]);

  useEffect(() => {
    // *** TO RESTRICT THIS WHILE RUNNING UNIT TEST *** //
    if (!freightListTestData) {
      getLogistics(pageSize, nextPage, "");
      getFreightClasss(pageSize, nextPage, "");
      localStorage.removeItem("selectedIds");
    }
    return () => {
      localStorage.removeItem("freightList");
      localStorage.removeItem("selectedIds");
    };
  }, []);

  /**
   * @author Subhashree
   * GET LOGISTIC DETAILS
   */
  function getLogistics() {
    AxiosInstance(8000, "application/json")
      .get("logistics/getLogistics")
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setLogistic(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      });
  }

  // *** FETCH Freight Class LIST *** //
  function getFreightClasss(pageSize, nextPage, filterarr, type) {
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };

    AxiosInstance(8000, "application/json")
      .get(
        `logistics/getFreightClasses?pageNum=${nextPage}&count=${pageSize}&sortField=createdAt&sortValue=DSC&logisticCode=${filterarr}`,
        filterOptions
      )
      .then((res) => {
        // console.log(filterOptions);
        if (res && res.data && res.status == "200") {
          // console.log(res.data);
          if (res?.data?.data.length > 0) {
            res?.data?.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // console.log(res?.data?.data)
            localStorage.setItem("regionList", JSON.stringify(res?.data?.data));
            setRegionList(res?.data?.data);
            setpagination(res.data.pagination);
          } else {
            setRegionList([]);
            setpagination({});
          }
        }
      })
      .catch((er) => {
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get Freight Class",
        };
        showNotification(error);
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }
  /* ADD Freight Class */
  function addFreightClass() {
    history.push(`/settings/addFreightClass`);
  }

  // *** EDIT origin FUNCTION *** //
  function editShip(record) {
    // console.log(record);
    history.push({
      pathname: `/settings/editFreightClass/${record._id}`,
      state: record,
    });
  }

  // DELETE Freight Class
  function showDeleteConfirm(id) {
    // console.log(id);
    confirm({
      title: "Are you sure want to delete this ? ",
      icon: <ExclamationCircleOutlined />,
      // content: "Some descriptions",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        deleteFreightClass(id);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  }

  function deleteFreightClass(id) {
    // console.log(id);
    setIsLoading(true);
    AxiosInstance(8000, "application/json")
      .delete(`logistics/removeFreightClass/${id}`)
      .then((res) => {
        // console.log(res);
        if (res && res?.data && res?.status == "200") {
          showNotification({
            type: "success",
            message: "Success",
            description: "Freight Class deleted successfully",
          });
          setIsLoading(false);
          getFreightClasss(pageSize, nextPage, "");
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Failed to delete Freight Class",
          });
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function debounceSearch(filter) {
    // console.log(filter);
    setIsLoading(true);
    setNextPage(1);
    getFreightClasss(pageSize, 1, filter);
  }

  function handlepaginationChanges(value, type) {
    let filter = "";
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getFreightClasss(value, 1, filter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getFreightClasss(pageSize, value, filter);
    }
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    let { name, value } = e.target;
    // const updatedValues = { ...logisticCode };
    // updatedValues[name] = value;
    setLogisticCode(value);
    getFreightClasss(pageSize, nextPage, value);
  }

  return (
    <div className={commonstyles.management_contents}>
      <div className="row d-flex">
        <div className="back_arrow">
          <img
            src={BackArrow}
            onClick={() => history.push("/settings")}
            alt=""
            className="cursor-pointer"
            title="back"
            width={"25px"}
            height={"21px"}
          />
          <h1 className="heading" aria-label="heading">
            Freight Class
          </h1>
        </div>
      </div>
      <div className={commonstyles.management_header}>
      
          <div className="row">
            <div className="col-lg-6">
              <button
                type="button"
                className="btn btn-outline-primary"
                aria-label="logisticCode"
                onClick={addFreightClass}
              >
                Add Freight Class
              </button>
            </div>
            <div className="col-lg-6 d-flex flex-row-reverse">
              <div className="ant-dropdown-link">
                <SelectComponent
                  className="form-control-md mb-3 theme-form-control-filter"
                  attributes={{
                    name: "logisticCode",
                    value: logisticCode,
                    onChange: (e) => {
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="">ALL</option>
                  {Array.isArray(logistic)
                    ? logistic.sort((a, b) => (a.code > b.code ? 1 : -1)).map((logi) => (
                        <option key={logi._id} value={logi.code}>
                          {logi.code}
                        </option>
                      ))
                    : null}
                </SelectComponent>
                <img
                  src={arrowDown}
                  className={commonstyles.dropDownArrowList}
                />
              
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
        ></div>
        <div className={`${commonstyles.management_table} table-responsive`}>
          {IsLoading === true && (
            <div
              style={{
                marginLeft: "0vh",
                marginTop: "30vh",
              }}
            >
              {/* <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching Freight Class details..." />
                </td>
              </tr> */}
              <span className="bg-transparent">
                <span colSpan="100%">
                  <LoadingIndicator loadingText="Fetching Freight Class details..." />
                </span>
              </span>
            </div>
          )}
          {!IsLoading && regionList.length > 0 && (
            <AntTableComponent
              className="order_table"
              tableProps={{
                columns: columns,
                dataSource: regionList,
                pagination: false,
              }}
            />
          )}
        </div>
      </div>
      {!IsLoading.list && Array.isArray(regionList) && regionList.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          handlepaginationChanges={handlepaginationChanges}
        />
      ) : null}
    </div>
  );
}

export default FreightClassList;
