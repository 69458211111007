import * as actionTypes from "../Actions/ActionTypes";
import { UpdateObjects } from "../../Components/ReusableComponent/UpdateObject";

const initialState = {
  token: null,
  data: null,
  error: null,
  loading: false,
};

const loginRequest = (state, action) => {
  return UpdateObjects(state, { error: null, loading: true });
};

const loginSuccess = (state, action) => {
  return UpdateObjects(state, {
    error: null,
    loading: false,
    token: action.token,
    data: action.data,
  });
};

const loginFailure = (state, action) => {
  return UpdateObjects(state, {
    error: action.error,
    loading: false,
  });
};

const logout = (state, action) => {
  return UpdateObjects(state, {
    token: null,
    data: null,
  });
};

const LoginReducers = (state = initialState, action = null) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
      return loginRequest(state, action);
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.LOGIN_FAILURE:
      return loginFailure(state, action);
    case actionTypes.LOGOUT:
      return logout(state, action);
    default:
      return state;
  }
};
export default LoginReducers;
