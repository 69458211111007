import Logo from "../../assets/img/hgrlogo.png";
import DashboardIconActive from "../../assets/icons/Active_Dashboard.svg";
import Dashboard_In from "../../assets/icons/Dashboard.svg";
import quote from "../../assets/icons/Active_Quote.svg";
import shipment from "../../assets/icons/Active_Shipment.svg";
import customer from "../../assets/icons/Active_Customer.svg";
import setting from "../../assets/icons/Active_Setting.svg";
import quote_In from "../../assets/icons/Quote.svg";
import shipment_In from "../../assets/icons/Shipment.svg";
import customer_In from "../../assets/icons/Customer.svg";
import setting_In from "../../assets/icons/Setting.svg";
import styles from "./Sidebar.module.css";

export const menus = [
  {
    id: "null",
    title: "Dashboard",
    route: "/dashboard",
    img: Dashboard_In,
    imgActive: DashboardIconActive,
    imgAlt: "",
    className: `${styles.side_menu_img}`,
  },
  {
    id: "null",
    title: "Customer",
    route: "/customer",
    img: customer_In,
    imgActive: customer,
    imgAlt: "",
  },
  {
    id: "null",
    title: "Shipment",
    route: "/ship",
    img: shipment_In,
    imgActive: shipment,
    imgAlt: "",
  },

  {
    id: "null",
    title: "Quote",
    route: "/quotes",
    img: quote_In,
    imgActive: quote,
    imgAlt: "",
    
    child: [
    { title: "Abandoned", route: `/abandoned` } ],
  },
  {
    id: "null",
    title: "Settings",
    route: "/settings",
    img: setting_In,
    imgAlt: "",
    imgActive: setting,
   // child: [{ title: "Logout", route: `/logout` }],
  },
];
