import React from "react";
import commonstyles from "../../styles/Management_Common.module.css";
import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { notification } from "antd";
import { AxiosInstance } from "../../utilities";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import { Link, useParams } from "react-router-dom";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import moment from "moment";

const ViewShipLog = ({ Detail, setDetails }) => {
  const { id } = useParams();
  const history = useHistory();
  const [editState, setEditState] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState({ details: false, update: false });
  const [enquiryDetail, setEnquiryDetail] = useState([]);

  useEffect(() => {
    if (id) {
      getEnquiryDetails(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function getEnquiryDetails(id) {
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`shipments/getShipment/${id}`)
      .then((res) => {
        // console.log(res);
        if (res && res.data && res.status == "200") {
          // console.log(res.data);
          const val = res.data;
          setEnquiryDetail(val);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldn't fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  return (
    <>
      {isLoading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "40vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching shipping log details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div className={commonstyles.management_contents}>
          {/* Header */}
          {/* <button onClick={gotoServices}>click </button> */}
          <div className={commonstyles.management_header}>
            <h1 className={commonstyles.management_header_heading}>
              <img
                src={BlackLeftArrow}
                onClick={() => history.push("/ship")}
                alt=""
                className="cursor-pointer"
                title="back"
              />
              Shipment Log
            </h1>
          </div>
          <div className={commonstyles.profile_display}>
            <div className={`row p-5`}>
              <div
                className={`col-lg-6 col-md-6 pl-5 pr-5 pb-1 ${commonstyles.userView}`}
              >
                <h4 className="heading-main">Request Details</h4>
                <div className={commonstyles.border_line}>
                  <pre style={{ color: "black" }}>
                    {JSON.stringify(enquiryDetail?.reqBody, null, 2)}
                  </pre>
                </div>
              </div>

              <div
                className={`col-lg-6 col-md-6 pl-5 pr-5 pb-1 ${commonstyles.userView}`}
              >
                <h4 className="heading-main">Response</h4>
                <div>
                  <pre style={{ color: "black" }}>
                    {JSON.stringify(enquiryDetail?.resBody, null, 2)}
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewShipLog;
