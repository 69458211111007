import React, { useState, useEffect } from "react";
import styles from "../../styles/Warehouse.module.css";
import "./LoginComponent.css";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import Logo from "../../assets/img/hgrlogo.png";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification } from "antd";
import FormErrorText from "../FormErrorText";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import validationRules from "../../validationRules";
import { isObject, isString, isEqual } from "lodash";
import { Link, useParams } from "react-router-dom";
import SelectComponent from "../ReusableComponent/SelectComponent";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import OtpInput from "react-otp-input-rc-17";
import eyeOpen from "../../assets/icons/eye_icon.svg";
import eyeClose from "../../assets/icons/eye_close.svg";
import { passwordReg, emailRegx, otpRegx } from "../RegularExp/RegularExp";
// import BackGroundImage from "../../assets/img/login_bg_img_rotated.png";
import BackGroundImage from "../../assets/img/login_banner_img.png";

export default function ForgotPassword() {
  const history = useHistory();
  const { id } = useParams();
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [enable, setEnable] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwd2, setIsRevealPwd2] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  useEffect(() => {
    setIsLoading(false);
  }, []);

  //   const { email } = history.location;
  const email = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : null;
  const otp = localStorage.getItem("otp") ? localStorage.getItem("otp") : null;

  if (
    email === undefined ||
    email === "" ||
    email == "" ||
    email == null ||
    otp === undefined ||
    otp === "" ||
    otp == "" ||
    otp == null
  ) {
    showNotification({
      type: "error",
      message: "Error",
      description: "Email or OTP not found",
    });
    history.push(`/login`);
  }

  const onVerifyOtp = (e) => {
    e.preventDefault();
    if (password === "") {
      formErrors.password = "Required";
    } else if (password === undefined) {
      formErrors.password = "Required";
    } else if (!passwordReg.test(password) && password !== "") {
      formErrors.password =
        "At least 1 number, upper & lower case and special character and minimum 8 characters";
    } else {
      formErrors.password = "";
    }

    if (confirm === "") {
      setFormErrors({ ...formErrors, confirm: "Required" });
    } else if (password === undefined) {
      setFormErrors({ ...formErrors, confirm: "Required" });
    } else if (password !== confirm) {
      setFormErrors({
        ...formErrors,
        confirm: "Confirm password should be same as new password",
      });
    } else if (!passwordReg.test(confirm) && confirm !== "") {
      setFormErrors({
        ...formErrors,
        confirm:
          "At least 1 number, upper & lower case and special character and minimum 8 characters",
      });
    } else {
      setFormErrors({ ...formErrors, confirm: "" });
    }

    if (
      //   otpError.trim() === "" &&
      otp.trim() !== "" &&
      formErrors.password === "" &&
      formErrors.confirm === "" &&
      password !== "" &&
      confirm !== "" &&
      password === confirm &&
      email !== undefined &&
      email !== ""
    ) {
      let data = {
        email: email,
        otp: otp,
        password: confirm,
      };
      submitResetPasswordWithOtp(data);
    }
  };

  function submitResetPasswordWithOtp(data) {
    setLoading({ ...loading, update: true });

    AxiosInstance(8000, "application/json")
      .post(`/users/resetPasswordWithOtp`, data)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setEnable(true);
          showNotification({
            type: "success",
            message: "Success",
            description: "New password created successfully",
          });
          history.push(`/login`);
          localStorage.clear();
        } else if (res.status === 400) {
          showNotification({
            type: "error",
            message: "Wrong OTP or User not found",
            description: "Error occured" || res.message,
          });
        }
      })
      .catch((error) => {
        showNotification({
          type: "error",
          message: "Error occured",
          description: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className="login_bg row m-0">
      <div className="col-lg-4 p-0">
        <div className="login">
          <div className="logo_img">
            <img src={Logo} alt="logo" />
          </div>
          <h3 className="title_text1">Create New Password</h3>
          {/* <p className="welcome_text">
            Forgot Password
          </p> */}
          <form onSubmit={onVerifyOtp} data-test="login_form">
            <div className="form-group form_group password_icon1 text-left">
              <label className="theme-form-label">
                New Password
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>
              <InputFormComponent
                className="form-control form-control-lg"
                error={formErrors.password}
                formControl={{
                  type: isRevealPwd ? "text" : "password",
                  placeholder: "Type the password",
                  name: "password",
                  value: password,
                  onChange: (e) => {
                    if (
                      !e.target.value.match(passwordReg) ||
                      !e.target.value === ""
                    ) {
                      setFormErrors({
                        ...formErrors,
                        password:
                          "At least 1 number, upper & lower case and special character and minimum 8 characters",
                      });
                    } else {
                      setFormErrors({ ...formErrors, password: "" });
                    }
                    setPassword(e.target.value);
                  },
                }}
              />
              <img
                title={isRevealPwd ? "Hide password" : "Show password"}
                src={isRevealPwd ? eyeClose : eyeOpen}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
                alt="eye_icon"
              />

              <div>
                <FormErrorText error={formErrors.password} />
              </div>
            </div>
            <div className="form-group form_group password_icon1 text-left">
              <label className="theme-form-label">
                Confirm Password
                <span className="text-danger" style={{ fontSize: "20px" }}>
                  *
                </span>
              </label>
              <InputFormComponent
                className="form-control form-control-lg mb-3"
                error={formErrors.confirm}
                formControl={{
                  type: isRevealPwd2 ? "text" : "password",
                  placeholder: "Type the confirm password",
                  name: "confirm",
                  value: confirm,
                  onChange: (e) => {
                    if (
                      !e.target.value.match(passwordReg) ||
                      (!e.target.value === "" && password !== e.target.value)
                    ) {
                      setFormErrors({
                        ...formErrors,
                        confirm:
                          "Confirm password should be same as new password",
                      });
                    } else if (password !== e.target.value) {
                      formErrors.confirm =
                        "Confirm password should be same as new password";
                    } else {
                      formErrors.confirm = "";
                    }
                    setConfirm(e.target.value);
                  },
                }}
              />
              <img
                title={isRevealPwd2 ? "Hide password" : "Show password"}
                src={isRevealPwd2 ? eyeClose : eyeOpen}
                onClick={() => setIsRevealPwd2((prevState) => !prevState)}
                alt="eye_icon"
               
              />

              <FormErrorText error={formErrors.confirm} />
            </div>
            <button className="btn_login">CREATE</button>
      
            <div className="para-text text-left" >
            <p > Your Password is a easily guessable, You can do better.</p>
            </div>
            <div className="para text-left" >
            <p>At least 1 Upper Case Letter (A-Z)</p>
            <p>At least 1 number (0-9)</p>
            <p>At least 8 characters</p></div>
          </form>
        </div>
      </div>
      <div className="col-lg-8 p-0">
        <div className="login_banner_img">
          <img src={BackGroundImage} alt="" />
        </div>
      </div>
    </div>
  );
}
