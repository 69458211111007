import React from "react";
import commonstyles from "../../styles/Management_Common.module.css";
// import styles from "../../styles/ZoneList.module.css";
import arrowDown from "../../assets/img/arrow_down.svg";
// import deleteIcon from "../../assets/img/trash.svg";
import dots from "../../assets/img/column_dots.svg";
import exportIcon from "../../assets/img/export_icon.svg";
import excel from "../../assets/img/excel.svg";
import eyeView from "../../assets/img/action_view.svg";
import { useHistory } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { AxiosInstance /* , PORTS */ } from "../../utilities";
import AntDateRangeSelectComponent from "../ReusableComponent/AntDateRangeSelectComponent";
import { notification, Dropdown, Menu, Select } from "antd";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Search from "../../assets/img/search_icon.svg";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import ButtonComponent from "../ReusableComponent/ButtonComponent";
import moment from "moment";
import { NavLink } from "react-router-dom";
import "antd/dist/antd.css";
import Img from "../../assets/img/img-about.png";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Quote Date", value: "createdAt" },
  { _id: 3, status: 1, name: "Quote Count", value: "countMerged" },
  { _id: 4, status: 1, name: "Customer Name", value: "fullName" },
  { _id: 5, status: 1, name: "Customer Email", value: "email" },
  { _id: 6, status: 1, name: "Item Price", value: "price" },
  { _id: 7, status: 1, name: "Destination", value: "destination" },
  { _id: 8, status: 1, name: "View Quotes", value: "Action" },
];
const filters = [
  // { id: 1, isChecked: false, name: "Region", value: "region" },
  { id: 2, isChecked: false, name: "Date", value: "date" },
  { id: 3, isChecked: false, name: "Shipment Type", value: "ShipmentType" },
  { id: 4, isChecked: false, name: "Order Status", value: "successShipment" },
  { id: 5, isChecked: false, name: "Quote Status", value: "successQuote" },
];

const shipType = [
  { _id: 1, name: "Show All", value: "ALL" },
  { _id: 2, name: "Freight", value: "FREIGHT " },
  { _id: 3, name: "Parcel", value: "PARCEL" },
];

const orderStatus = [
  { _id: 1, name: "Show All", value: "" },
  { _id: 2, name: "Completed Orders", value: "true" },
  { _id: 3, name: "Incomplete Orders", value: "false" },
];

const quoteStatus = [
  { _id: 1, name: "Show All", value: "" },
  { _id: 2, name: "Failure Quote", value: "false" },
  { _id: 3, name: "Success Quote", value: "true" },
];

const bulkAction = [
  { _id: 1, isChecked: true, name: "Enable", value: 1 },
  { _id: 2, isChecked: false, name: "Disable", value: 2 },
];

const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "30 day back", value: 30 },
  { _id: 3, name: "60 day back", value: 60 },
  { _id: 4, name: "90 day back", value: 90 },
  { _id: 5, name: "120 day back", value: 120 },
  { _id: 6, name: "180 day back", value: 90 },
  { _id: 7, name: "1 year back", value: 365 },
  { _id: 8, name: "2 year back", value: 2 },
  { _id: 9, name: "3 year back", value: 3 },
  { _id: 10, name: "5 year back", value: 5 },
  { _id: 11, name: "Custom", value: "custom" },
];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

function Abandoned() {
  const history = useHistory();
  const [regionList, setRegionList] = useState([]);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [isLoader, setIsLoader] = useState(true);
  const [IsLoading, setIsLoading] = useState(false);
  const [childComponentVisibility, setChildComponentVisibility] = useState({
    bulkUpload: false,
  });
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [filterDays, setFilterDays] = useState("");
  const [filteredResult, setFilteredResult] = useState("");
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [shipmentType, setShipmentType] = useState("");
  const [regionId, setRegionId] = useState("");
  const [shippingType, setshippingType] = useState(shipType);
  const [orderingType, setorderingType] = useState(orderStatus);
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [filterShipName, setFilterShipName] = useState("Show All");
  const [filterOrderName, setFilterOrderName] = useState("Show All");
  const [orderType, setOrderType] = useState("");
  const [regions, setRegions] = useState([]);
  const [sortOrder, setSortorder] = useState("DSC");
  const [sort_value, setSortValue] = useState("");
  const [successShipment, setSuccessShipment] = useState("");
  const [getCompany, setGetCompany] = useState([]);
  const [filterCompany, setfilterCompany] = useState("");
  const timeoutId = useRef();
  const [firstTime, setFirstTime] = useState(true);
  const [quoteType, setQuoteType] = useState(quoteStatus);
  const [successQuote, setSuccessQuote] = useState("");
  const [filterQuoteName, setFilterQuoteName] = useState("Show All");

  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },

    {
      title: "Quote Date",
      visible: false,
      key: "createdAt",
      render: (text, record, index) => (
        <NavLink to={`/quotes/getQuotes/${record?._id}`}>
          <div>
            <span>
              {record.createdAt
                ? moment(record.createdAt).format("MMM DD, YYYY h:mm:ss A")
                : "-"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: (a, b) => false,
    },
   
    
    {
        title: "Quote Count",
        visible: false,
        key: "countMerged",
        render: (text, record, index) => (
          <NavLink to={`/quotes/getQuotes/${record?._id}`}>
            <div>
              <span>
                {record?.countMerged
                  ? record?.countMerged
                  : "-"}
              </span>
            </div>
          </NavLink>
        ),
        sorter: (a, b) => false,
      },
      {
        title: "Customer Name",
        visible: false,
        key: "fullName",
        render: (text, record, index) => (
          <NavLink to={`/quotes/getQuotes/${record?._id}`}>
            <div>
              <span>
                {record?.customer?.fullName
                  ? record?.customer?.fullName
                  : "-"}
              </span>
            </div>
          </NavLink>
        ),
        //sorter: (a, b) => a.fullName - b.fullName,
      },
      {
        title: "Customer Email",
        visible: false,
        key: "email",
        render: (text, record, index) => (
          <NavLink to={`/quotes/getQuotes/${record?._id}`}>
            <div>
              <span>
                {record?.customer?.email
                  ? record?.customer?.email
                  : "-"}
              </span>
            </div>
          </NavLink>
        ),
        //sorter: (a, b) => a.email - b.email,
      },
   
      {
        title: "Item Price",
        visible: false,
        key: "price",
        render: (text, record, index) => (
          <NavLink to={`/quotes/getQuotes/${record?._id}`}>
            <div>
              <span>
                {record?.product?.price
                  ?`$${parseFloat(record?.product?.price).toFixed(2)}`
                  : "-"}
                 
              </span>{" "}
            </div>
          </NavLink>
        ),
        sorter: (a, b) => false,
      },
    {
      title: "Destination",
      visible: false,
      key: "destination",
      render: (text, record, index) => (
        <NavLink to={`/quotes/getQuotes/${record?._id}`}>
          <div>
            <span>
              {record?.destination?.state
                ? record?.destination?.state + ", " + record?.destination?.city
                : "-"}
            </span>
          </div>
        </NavLink>
      ),
    },

   
   
   
  ]);

  function capitalize(str) {
    return str
      .split(" ")
      .map((s, i, arr) => (arr.length == 1 || i % 2 ? s.toUpperCase() : s))
      .join(" ");
  }

  function onChange(pagination, filters, sorter, extra) {
    //console.log('params', sorter);
    let sortValue = sorter.order === "ascend" ? "ASC" : "DSC";
    //console.log(filters);
    setSortorder(sortValue);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      sortOrder: sorter.order === "ascend" ? "ASC" : "DSC",
      shipmentType: shipmentType,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      successShipment: successShipment,
      success: successQuote,
    };
    setNextPage(1);
    setSortValue(sorter)
    getZoneList(pageSize, 1, updatedFilter, true, true,filters,sorter);
  }

  const [selectedOrder, setSelectedOrder] = useState([]);
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("regionList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });

    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("regionList", JSON.stringify(cloneOrderList));
    setRegionList(cloneOrderList);
    //setSelectedOrder(cloneSelectedOrder);

    const cloneSelectFilter = [...selectedOrder];
    cloneOrderList.forEach((item) => {
      if (item.isChecked === true) {
        cloneSelectFilter.push(item._id);
      }
    });
    setSelectedOrder(cloneSelectFilter);
  };
  const parentCheckbox = (e) => {
    console.log("parent");
    localStorage.removeItem("selectedOrder");
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("regionList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        cloneOrderList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }

    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("regionList", JSON.stringify(cloneOrderList));
    setRegionList(cloneOrderList);
    // console.log(cloneSelectedOrder);
    // const cloneSelectFilter = [...selectedOrder];
    // cloneOrderList.forEach((item) => {
    //   if (item.isChecked === true) {
    //     cloneSelectFilter.push(item._id)
    //   }
    // });
    setSelectedOrder(cloneSelectedOrder);
  };
  useEffect(() => {
    getCompanyDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    set_new_header(headers);
    let filter_value = JSON.parse(sessionStorage.getItem("quote_filter"));
    // console.log(filter_value);
    setPageSize(filter_value?.pagesize ?? 10);
    setNextPage(filter_value?.nextpage ?? 1);
    //setSearch(filter_value?.search ?? "")
    const filter = {
      filterDays: filter_value?.filterDays ?? "",
      shipmentType: filter_value?.shipmentType ?? "",
      startDate: filter_value?.startDate ?? "",
      endDate: filter_value?.endDate ?? "",
    };
    getZoneList(
      filter_value?.pagesize ?? 10,
      filter_value?.nextpage ?? 1,
      "",
      false,
      false,
      filter
    );
    localStorage.removeItem("selectedIds");
    return () => {
      localStorage.removeItem("regionList");
      localStorage.removeItem("selectedIds");
    };
  }, [filterCompany]);
  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function getCompanyDetail() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getCompanies?pageNum=1&count=20&sortField=createdAt&sortValue=DSC`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetCompany(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get company details",
        };
        showNotification(error);
        // return er;
      });
  }

  // *** FETCH REGION LIST *** //
  function getZoneList(pageSize, nextPage, filterarr, type, first, filter,sorter) {
    // setIsLoading(false);
    //console.log(sorter);
    if (!type) {
      setIsLoading(true);
    }
    var filterOptions;
    if (filter) {
      filterOptions = {
        pagesize: pageSize,
        nextpage: nextPage,
        companyId: filterCompany,
        //search: "",
        shipmentType: filter.shipmentType,
        filterDays: filter.filterDays,
        //successShipment:"",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
    } else {
      filterOptions = {
        pagesize: pageSize,
        nextpage: nextPage,
        companyId: filterCompany,
        //search: "",
        shipmentType: "",
        filterDays: "",
        //successShipment:"",
        startDate: "",
        endDate: "",

      };
    }

    /* const sortBy ={
          sortOrder: sortOrder
        } */
    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else if (search !== "") {
      if (filterarr && filterarr.search !== "") {
        filterOptions.search = search;
      }
    }
    if (filterarr && filterarr.ShipmentType) {
      filterOptions.shipmentType = filterarr.ShipmentType;
    } else if (shipmentType !== "") {
      if (filterarr && filterarr.ShipmentType !== "") {
        filterOptions.shipmentType = shipmentType;
      }
    }
    if (filterarr && filterarr.successShipment) {
      filterOptions.successShipment = filterarr.successShipment;
    } else if (successShipment !== "") {
      if (filterarr && filterarr.successShipment !== "") {
        filterOptions.successShipment = successShipment;
      }
    }
    if (filterarr && filterarr.success) {
      filterOptions.success = filterarr.success;
    } else if (successQuote !== "") {
      if (filterarr && filterarr.success !== "") {
        filterOptions.success = successQuote;
      }
    }
    if (filterarr && filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filterarr && filterarr.filterDays !== "") {
        filterOptions.filterDays = Number(filteredDate.filterDays);
      }
    }

    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    // console.log(filterOptions, "ship");
    // let newDataFilter = {
    //   companyId: filterCompany,
    //   nextpage: nextPage,
    //   pagesize: pageSize,
    //   search: "",
    //   sortOrder:filterarr?.sortOrder,
    //   filterOptions:filterOptions,
    // };
    //console.log('sort',sorter?.columnKey)
    var data = {...filterOptions,
        "success": true,
        "successShipment": false
      }

    if (!firstTime || first)
      sessionStorage.setItem("quote_filter", JSON.stringify(filterOptions));
    AxiosInstance(8000, "application/json")
      .post(
        `quotes/getQuotes?pageNum=${nextPage}&count=${pageSize}&sortField=${sorter?.columnKey ? sorter.columnKey:"createdAt"}&sortValue=${filterarr?.sortOrder ? filterarr.sortOrder : "DSC"}`,
        data
      )
    
      .then((res) => {
        if (res && res.data && res.status === 200) {
          // console.log(res.data.data);
          if (res.data.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // setIsLoader(false);
            // console.log(res.data)
            localStorage.setItem("regionList", JSON.stringify(res.data.data));
            setRegionList(res.data.data);
            setPagination(res.data.pagination);
          } else {
            setRegionList([]);
            setPagination({});
          }
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }

  // *** ADD ZONE *** //
  function addZone() {
    history.push(`/site-settings/zone/addzone`);
  }

  /* useEffect(() => {
    getZoneList(pageSize, nextPage);
    // getRegionList();
  }, [search, nextPage, pageSize]); */

  // Row click redirect
  // function onRowClick(e, record) {
  //   console.log(e.target);
  //   console.log(e.target?.src);
  //   // console.log(record);
  //   if (!e.target?.src) {
  //     history.push({
  //       pathname: `/quotes/getQuote/${record._id}`,
  //       state: record,
  //     });
  //   }
  // }

  // *** EDIT SHIPPING FUNCTION *** //
  function editShip(record) {
    // console.log(region);
    history.push({
      pathname: `/quoteLog/${record._id}`,
      state: record,
    });
  }

  function handleInput(e) {
    const { value } = e.target;
    if (value.trim() !== "") {
      const updatedFilter = {
        search: e.target?.value || "",
        nextpage: 1,
        shipmentType: shipmentType,
        filterDays: filterDays,
        sortOrder: sortOrder,
        successShipment: successShipment,
        success: successQuote,
      };
      setSearch(e.target.value);
      debounceSearch(updatedFilter);
    } else if (search) {
      const updatedFilter = {
        search: "",
        nextpage: 1,
        shipmentType: shipmentType,
        filterDays: filterDays,
        sortOrder: sortOrder,
        successShipment: successShipment,
        success: successQuote,
      };
      setSearch("");
      debounceSearch(updatedFilter);
    }
  }

  function debounceSearch(filter) {
    // console.log(filter);
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      setIsLoading(true);
      setNextPage(1);
      getZoneList(pageSize, 1, filter, false, true);
      setFilteredResult(filter);
    }, 500);
  }

  // function debouncedSearch(filter) {
  //   clearTimeout(timeoutId.current);
  //   timeoutId.current = setTimeout(() => {
  //     filterDecors(filter);
  //   }, 500);
  // }

  /*  function getRegionList() {
    AxiosInstance(8000, "application/json")
        .get("/admin/region/getRegionList")
        .then((res) => {
            if (res.status === 200 && res.data.status === 200) {
              let obj = { name: "All Region", value: "", _id: "" }
              res.data?.daaata?.unshift(obj);
              setRegions(res.data.daaata);
            }
        })
        .catch((er) => {
            // console.log(er);
            return er
        });
  } */

  function handlepaginationChanges(value, type) {
    let filter = {
      search: search,
      nextpage: 1,
      shipmentType: shipmentType,
      filterDays: filterDays,
      sortOrder: sortOrder,
      successShipment: successShipment,
      success: successQuote,
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getZoneList(value, 1, filter, false, true,filter,sort_value);
    } else if (type === "nextPage") {
      setNextPage(value);
      getZoneList(pageSize, value, filter, false, true,filter,sort_value);
    }
  }
  function filterRegionChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      shipmentType: shipmentType,
      filterDays: filterDays,
      sortOrder: sortOrder,
      successShipment: successShipment,
      success: successQuote,
    };
    /* if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    } */
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    if (name === "ShipmentType") {
      // console.log(value);
      updatedFilter.ShipmentType = value.value;
      setShipmentType(value.value);
      setFilterShipName(value.name);
    }
    if (name === "successShipment") {
      //console.log(value.name);
      updatedFilter.successShipment = value.value;
      setSuccessShipment(value.value);
      setFilterOrderName(value.name);
    }
    if (name === "successQuote") {
      //console.log(value.name);
      updatedFilter.success = value.value;
      setSuccessQuote(value.value);
      setFilterQuoteName(value.name);
    }
    debounceSearch(updatedFilter);
  }

  // *** DATE RANGE ONCHANGE FUNCTION *** //
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      shipmentType: shipmentType,
      successShipment: successShipment,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
    };
    debounceSearch(updatedFilter);
  };

  //bulk
  /*  if (childComponentVisibility.bulkUpload) {
    return (
      <BulkUploadComponent
        region="region"
        handleClose={() =>
          {setChildComponentVisibility({
            ...childComponentVisibility,
            bulkUpload: false,
          })
          getZoneList(pageSize, nextPage);
        }
        }
      />
    );
  } */
  //TO SHOW TABLE HEADERS BASED ON STATUS
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  // * TO SHOW TABLE DATA BASED ON HEADER STATUS
  const renderBody = (props) => {
    // console.log(columns);
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No results found!</td>
        </tr>
      );
    }
  };

  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  /* Export here */
  const exportToCSV = async (e) => {
    const filterOptions = {
      search: search,
      selectedRows: selectedOrder,
    };

    if (filteredResult && filteredResult.search) {
      filterOptions.search = filteredResult.search;
    } else if (search !== "") {
      if (filteredResult && filteredResult.search !== "") {
        filterOptions.search = search;
      }
    }
    if (filteredResult && filteredResult.ShipmentType) {
      filterOptions.shipmentType = filteredResult.ShipmentType;
    } else if (shipmentType !== "") {
      if (filteredResult && filteredResult.ShipmentType !== "") {
        filterOptions.shipmentType = shipmentType;
      }
    }
    if (filteredResult && filteredResult.successShipment) {
      filterOptions.successShipment = filteredResult.successShipment;
    } else if (successShipment !== "") {
      if (filteredResult && filteredResult.successShipment !== "") {
        filterOptions.successShipment = successShipment;
      }
    }
    if (filteredResult && filteredResult.filterDays) {
      filterOptions.filterDays = Number(filteredResult.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filteredResult && filteredResult.filterDays !== "") {
        filterOptions.filterDays = Number(filteredDate.filterDays);
      }
    }

    if (filteredResult && filteredResult.startDate) {
      filterOptions.startDate = filteredResult.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filteredResult && filteredResult.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filteredResult && filteredResult.endDate) {
      filterOptions.endDate = filteredResult.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filteredResult && filteredResult.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }

    //console.log(sortOrder);
    //return
    AxiosInstance(8000, "application/json")
      .post(
        `/quotes/getQuotes/download?sortField=${sort_value?.columnKey ? sort_value.columnKey:"createdAt"}&sortValue=${sortOrder}`,
        filterOptions
      )
      .then((resp) => {
        // console.log(resp);
        const response = resp && resp.data;
        if (resp && (resp.status === "200" || resp.status === 200)) {
          var blob = new Blob([response], { type: "text/csv;charset=utf-8" });
          var url = URL.createObjectURL(blob);

          // Create a link to download it
          var pom = document.createElement("a");
          pom.href = url;
          pom.setAttribute("download", "Quote.csv");
          pom.click();
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // return [];
        }
      })
      .catch((err) => {
        // console.log(err);
        return err;
      });
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewOrder(items);
  };

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder: sortOrder,
      shipmentType: shipmentType,
      successShipment: successShipment,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        /* if (item.value === "region") {
              updatedFilter.regionId = "";
              setRegionId("");
              setRegionName("All Region");
          } */
        /*  if (item.value === "CategoryId") {
              updatedFilter.CategoryId = "";
              setCategoryId("");
          } */
        // console.log(item.value);
        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
          setFilterDaysName("Date");
        }
        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
        }
        if (item.value === "ShipmentType") {
          updatedFilter.ShipmentType = "";
          setShipmentType("");
          setFilterShipName("Show All");
        }
        if (item.value === "successShipment") {
          updatedFilter.successShipment = "";
          setSuccessShipment("");
          setFilterOrderName("Show All");
        }
        if (item.value === "successQuote") {
          updatedFilter.successShipment = "";
          setSuccessQuote("");
          setFilterQuoteName("Show All");
        }
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter{" "}
                <img alt="" src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                      <span key={filter._id}>
                        <input
                          type="checkbox"
                          checked={filter.isChecked}
                          onChange={(e) => commonFilterHandler(e, filter)}
                        ></input>
                        <label>{filter.name}</label>
                      </span>
                    ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                          <span
                            key={day._id}
                            onClick={() =>
                              filterRegionChange("filterDays", day)
                            }
                          >
                            <label>{day.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "ShipmentType") ===
                "ShipmentType" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterShipName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(shippingType)
                      ? shippingType.map((ship) => (
                          <span
                            key={ship._id}
                            onClick={() =>
                              filterRegionChange("ShipmentType", ship)
                            }
                          >
                            <label>{ship.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {/* {console.log(selectedFilters, "1111111")} */}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "successShipment") ===
                "successShipment" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterOrderName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(orderingType)
                      ? orderingType.map((order) => (
                          <span
                            key={order._id}
                            onClick={() =>
                              filterRegionChange("successShipment", order)
                            }
                          >
                            <label>{order.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "successQuote") ===
                "successQuote" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterQuoteName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(quoteType)
                      ? quoteType.map((quote) => (
                          <span
                            key={quote._id}
                            onClick={() =>
                              filterRegionChange("successQuote", quote)
                            }
                          >
                            <label>{quote.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );
  function filterOnChange(e) {
    const { name, value } = e.target;
    // console.log(value);
    setfilterCompany(value);
    // setIsLoading(true);
  }
  return (
    <div className={commonstyles.management_contents}>
      {/* <SearchComponent/> */}
      <div className={commonstyles.management_header}>
        <div className="row">
          <div className="col-lg-2">
            <h1 className={commonstyles.management_header_heading}>Abandoned</h1>
          </div>
          <div className={` col-lg-6 ${commonstyles.customer_dropdown} d-flex`}>
            {/* <div className={` filter_width ${commonstyles.dropdown}`}>
              <Menu className={`filter_dropdown datefilter`}>
                <div className="card">
                  <div className="card_contents">
                    <div className="row no-gutters">
                      <div className="card_dropdown show col-md-6">
                        <select
                          name="filterCompany"
                          value={filterCompany}
                          onChange={filterOnChange}
                          className={`form-control  ${commonstyles.form_control} `}
                          style={{ cursor: "pointer" }}
                        >
                          <option value="">ALL</option>
                          {Array.isArray(getCompany)
                            ? getCompany.map((company) => (
                                <option
                                  key={company?.code}
                                  value={company?._id}
                                >
                                  {company?.code}
                                </option>
                              ))
                            : null}
                        </select>
                        <img src={arrowDown} className="dropDownArrow" />
                      </div>
                    </div>
                  </div>
                </div>
              </Menu>
            </div> */}
          </div>
          <div className="col-lg-4 d-flex">
            <div className={commonstyles.export}>
              <span
                className={commonstyles.management_header_downloads_text}
                style={{ cursor: "pointer" }}
                onClick={(e) => exportToCSV(e)}
              >
                <img className={commonstyles.export_icon} src={excel} />
                Export
                <img
                  src={exportIcon}
                  alt="icon"
                  className={commonstyles.export_right_icon}
                  height={"13px"}
                  width={"13px"}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div /* className={styles.administrator} */>
        <div
          className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
        >
          <div
            className={`${commonstyles.management_contents_search_bar} input-group`}
          >
            <input
              type="search"
              className={`${commonstyles.form_control} form-control`}
              aria-label="Search"
              placeholder="Search by Quote ID and Destination"
              aria-describedby="search-addon"
              value={search}
              onChange={handleInput}
            />
            <img src={Search} alt="" />
          </div>
          <Dropdown
            overlay={filterMenu}
            className="usermanage_table_filter_columns ml-3"
          >
            <div className="ant-dropdown-link">
              Filter <img src={arrowDown} width={10} height={20} />
            </div>
          </Dropdown>
          <div className={commonstyles.dropdown}>
            <Menu className={`filter_dropdown datefilter`}>
              <div className="card">
                <div className="card_contents">
                  <div className="row no-gutters">
                    <div className="card_dropdown show col-md-6">
                      <select
                        name="filterCompany"
                        value={filterCompany}
                        onChange={filterOnChange}
                        className={`form-control  ${commonstyles.form_control} `}
                        style={{ cursor: "pointer" }}
                      >
                        <option value="">ALL</option>
                        {Array.isArray(getCompany)
                          ? getCompany.map((company) => (
                              <option key={company?.code} value={company?._id}>
                                {company?.code}
                              </option>
                            ))
                          : null}
                      </select>
                      <img src={arrowDown} className="dropDownArrow" />
                    </div>
                  </div>
                </div>
              </div>
            </Menu>
          </div>
          {/* <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Columns <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown> */}
        </div>
        <div className={`${commonstyles.management_table} table-responsive`}>
          {!IsLoading && regionList.length > 0 && (
            <input
              type="checkbox"
              className={`${commonstyles.parentCheckbox}`}
              onChange={parentCheckbox}
            ></input>
          )}
          {IsLoading === true ? (
            <div
              style={{
                marginLeft: "70vh",
                marginTop: "20vh",
              }}
            >
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching Abandoned details..." />
                </td>
              </tr>
            </div>
          ) : (
            <AntTableComponent
              className="order_table"
              tableProps={{
                columns: columns,
                dataSource: regionList,
                pagination: false,
                onChange: onChange,
              }}
            />
          )}
        </div>
      </div>
      {!IsLoading.list && Array.isArray(regionList) && regionList.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          // handlePaginationChanges={handlePaginationChanges}
          handlepaginationChanges={(value, type) => {
            setFirstTime((prev) => !prev);
            handlepaginationChanges(value, type);
          }}
        />
      ) : null}
    </div>
  );
}

export default Abandoned;
