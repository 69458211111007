import React from "react";
import commonstyles from "../../styles/Management_Common.module.css";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { notification } from "antd";
import { AxiosInstance } from "../../utilities";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import { NavLink, useParams } from "react-router-dom";
import eyeView from "../../assets/img/action_view.svg";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import moment from "moment";
import Img from "../../assets/img/img-about.png";

const AbandonedDetails = ({ Detail, setDetails }) => {
  const { id } = useParams();
  const history = useHistory();
  const [editState, setEditState] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState({ details: false, update: false });
  const [enquiryDetail, setEnquiryDetail] = useState([]);

  useEffect(() => {
    if (id) {
      getEnquiryDetails(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function getEnquiryDetails(id) {
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`quotes/getAbandedQuotes/${id}`)
      .then((res) => {
        // console.log(res);
        if (res && res.data && res.status == "200") {
          // console.log(res.data);
          const val = res.data;
          setEnquiryDetail(val);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldn't fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  return (
    <>
      {isLoading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "40vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching Abandoned details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div className={commonstyles.management_contents}>
          {/* Header */}
          {/* <button onClick={gotoServices}>click </button> */}
          <div className={commonstyles.management_header}>
            <h1 className={commonstyles.management_header_heading}>
              <img
                src={BlackLeftArrow}
                onClick={() => history.push("/abandoned")}
                alt=""
                className="cursor-pointer"
                title="back"
              />
              Abandoned Quotes
            </h1>
          </div>
          <div className={commonstyles.profile_display}>
            <div className={`row ptl-4`}>
              <div className={`col-lg-3 col-md-3  ${commonstyles.userView}`}>
                <h4 className="abandodedDetail">Customer Name</h4>
                <p>
                  {enquiryDetail?.customer?.fullName
                    ? enquiryDetail?.customer?.fullName
                    : "-"}
                </p>
              </div>

             
              <div className={`col-lg-3 col-md-3  ${commonstyles.userView}`}>
                <h4 className="abandodedDetail">Customer Email</h4>
                <p>
                  {enquiryDetail?.customer?.email
                    ? enquiryDetail?.customer?.email
                    : "-"}
                </p>
              </div> 
              <div className={`col-lg-3 col-md-3  ${commonstyles.userView}`}>
                <h4 className="abandodedDetail">Customer Phone</h4>
                <p>
                  {enquiryDetail?.customer?.phone
                    ? enquiryDetail?.customer?.phone
                    : "-"}
                </p>
              </div>
              <div className={`col-lg-3 col-md-3  ${commonstyles.userView}`}>
                <h4 className="abandodedDetail">Customer Address</h4>
                <p>
                  {enquiryDetail?.customer?.address1
                    ? enquiryDetail?.customer?.address1
                    : "-"}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 padding-abandonded">
                <div className="product_details-container_details">
                  <div className="back_arrow">
                    <h1 className="heading-detail">Abandoned Quote Details</h1>
                  </div>
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Quote Date</th>
                        <th scope="col">Image </th>
                        <th scope="col">Product ID</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Product Price</th>
                        <th scope="col">Lowest Quote</th>
                        <th scope="col">Origin</th>
                        <th scope="col">Shipment</th>
                        <th scope="col">Destination</th>
                        <th scope="col">View Logs</th>
                      </tr>
                    </thead>
                    {enquiryDetail?.quotes &&
                      enquiryDetail?.quotes.length > 0 &&
                      enquiryDetail?.quotes.map((data) => (
                        <tbody>
                          <tr>
                            <td>
                              {data?.createdAt
                                ? moment(data.createdAt).format(
                                    "MMM DD, YYYY h:mm:ss A"
                                  )
                                : "-"}
                            </td>
                            <td>
                              {" "}
                              {data?.product?.image ? (
                                // record?.product?.length > 0 && (
                                <img
                                  className="img_about1"
                                  alt="productImg"
                                  src={data?.product?.image}
                                ></img>
                              ) : (
                                // )
                                <img
                                  className="img_about1"
                                  alt="dummyImg"
                                  src={Img}
                                ></img>
                              )}
                               <div className="hide">
                {data?.product?.image ? <img alt="" src={data?.product?.image} /> : ""}
              </div>
                            </td>
                            <td>
                              <div style={{ color: "#4C52B8 " }}>
                                <a
                                  href={`https://hgrinc.com/productDetail/h/gr/${data?.product?.code.replaceAll(
                                    "-",
                                    ""
                                  )}`}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >{`${
                                  data?.product?.code
                                    ? data?.product?.code
                                    : "-"
                                } `}</a>
                                {/* <span style={{ textTransform: "capitalize" }}>
              {`${record?.product?.code}`}
            </span> */}
                              </div>
                            </td>
                            <td>{data?.product?.name}</td>

                            <td>
                              {" "}
                              {data?.product?.price
                                ? `$${parseFloat(data?.product?.price).toFixed(
                                    2
                                  )}`
                                : "-"}
                            </td>
                            <td>
                              {data?.product?.carriers &&
                              data?.product?.carriers.length > 0
                                ? `$${parseFloat(
                                    data?.product?.carriers[0]?.totalCharge
                                  ).toFixed(2)}`
                                : "-"}
                            </td>
                            <td>
                              {" "}
                              {data?.product?.originLoc
                                ? data?.product?.originLoc
                                : "-"}
                            </td>
                            <td>
                              {data?.shipmentType ? data?.shipmentType : "-"}
                            </td>
                            <td>
                              {" "}
                              {data?.destination?.state
                                ? data?.destination?.state +
                                  ", " +
                                  data?.destination?.city
                                : "-"}
                            </td>
                            <td>
                              {" "}
                              <NavLink to={`/quoteLog/${id}`}>
                                <img
                                  src={eyeView}
                                  className={`${commonstyles.admin_table_edit_img} mr-2`}
                                  // onClick={() => editShip(record)}
                                  alt="View quotes details"
                                  title="View quotes details"
                                  style={{ cursor: "pointer" }}
                                />
                              </NavLink>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    {enquiryDetail?.carriers &&
                      enquiryDetail?.carriers.length === 0 && (
                        <p className="nodata text-left">No Data Found !</p>
                      )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AbandonedDetails;
