import React, { useState, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import BackArrow from "../../assets/icons/back_arrow.svg";
import tickIcon from "../../assets/images/tickIcon.svg";
import "./QuoteDetail.css";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import { AxiosInstance } from "../../utilities";
import moment from "moment";
import shipment from "../../assets/icons/Active_Shipment.svg";
import arrowDown from "../../assets/images/arrow_down.svg";
import arrowUp from "../../assets/img/arrow_Up.svg";
import eyeView from "../../assets/img/action_view.svg";
import Img from "../../assets/img/img-about.png";

const QuoteDetail = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const path = props.location.pathname.split("/")[3];
  const [dataSource, setDataSource] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState({ details: false, update: false });
  const [productId, setProductId] = useState("");
  const toggleReadMore = (pId) => {
    if (pId === productId) {
      setProductId("");
    } else {
      setProductId(pId);
    }
  };

  useEffect(() => {
    if (path) {
      getQuotedDetails(path);
    } else {
      setIsLoading(false);
    }
  }, []);

  const getQuotedDetails = (path) => {
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`/quotes/getQuote/${path}`)
      .then((resp) => {
        const response = resp && resp;
        // console.log(resp, "resp");
        if (response && response.status === 200) {
          let data = response.data;
          // console.log(data, "data");
          setDataSource(data);
          setIsLoading(false);
        }
        if (response && response.status === 400) {
          setDataSource();
          setIsLoading(false);
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  };

  return (
    <section className="quote-details">
      <div className="">
        <div className="row d-flex">
          <div className="back_arrow">
            <img
              src={BackArrow}
              onClick={() => history.push("/quotes")}
              alt=""
              className="cursor-pointer"
              title="back"
              width={"25px"}
              height={"21px"}
            />
            <h1 className="heading">Quote Details</h1>
          </div>
        </div>

        {IsLoading === true ? (
          <div
            style={{
              marginLeft: "70vh",
              marginTop: "40vh",
            }}
          >
            <tr className="bg-transparent">
              <td colSpan="100%">
                <LoadingIndicator loadingText="Fetching Quote details..." />
              </td>
            </tr>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6 ">
                <div className="row">
                  <div className="col-lg-4 text-right"></div>
                  <div className="col-lg-8 text-right d-flex">
                    <h4 className="heading_sub">
                      Quote ID :{" "}
                      <span className="customer-primary">
                        {dataSource?._id}
                      </span>
                    </h4>
                    <div className=" eye_icon ">
                      <NavLink to={`/quoteLog/${path}`}>
                        <img
                          src={eyeView}
                          alt="View quotes details"
                          title="View quotes details"
                          style={{ cursor: "pointer" }}
                        />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="customer-details-bg-wrapper">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="row">
                    <h4 className="heading-main">Quote Details</h4>
                    <div className="col-lg-4">
                      <p className="customer-primary">Quote Date</p>
                      <p className="customer-primary-sub">
                        {" "}
                        {moment(dataSource?.createdAt).format(
                          "MMM DD, YYYY h:mm:ss A"
                        )}
                      </p>

                      <p className="customer-primary">Destination</p>
                      <p className="customer-primary-sub">
                        {dataSource?.destination?.city}
                        {", "}
                        {dataSource?.destination?.state}
                        {", "}
                        {dataSource?.destination?.postalCode}
                      </p>

                      <p className="customer-primary">Failure Status</p>
                      <p className="customer-primary-sub" role="detail5">
                        {dataSource?.resBody?.ok == false
                          ? dataSource?.resBody?.details
                            ? dataSource?.resBody?.details ===
                              "Larger Product Cannot Ship"
                              ? "LPCS"
                              : dataSource?.resBody?.details ===
                                "Missing Product Dimensions"
                              ? "MPD"
                              : dataSource?.resBody?.details ===
                                "Larger Product Can Not Ship"
                              ? "LPCS"
                              : dataSource?.resBody?.details
                            : "-"
                          : "-"}
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <p className="customer-primary">Shipment Type</p>
                      <p className="customer-primary-sub">
                        {dataSource?.shipmentType
                          ? dataSource?.shipmentType
                          : "-"}
                      </p>
                      <p className="customer-primary">Order Status</p>
                      <div className="customer-primary-sub-green">
                        <h6
                          style={
                            dataSource?.successShipment === true
                              ? {
                                  color: "green",
                                  backgroundColor: "#00800035",
                                  padding: "5px 20px",
                                  borderRadius: "16px",
                                  width: "120px",
                                }
                              : {
                                  color: "red",
                                  background: "#ff000045",
                                  padding: "5px 20px",
                                  borderRadius: "16px",
                                  width: "100px",
                                }
                          }
                        >
                          {dataSource?.successShipment === true
                            ? "Completed"
                            : "Pending"}
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <p className="customer-primary">Quote Status</p>
                      <p className="customer-primary-sub">
                        {dataSource?.success === true ? "Success" : "Failure"}
                      </p>
                      <p className="customer-primary">Shipment Details</p>
                      <p className="customer-primary-sub">
                        {dataSource?.shipment ? (
                          <NavLink to={`/ship/${dataSource?.shipment?._id}`}>
                            <img
                              alt=""
                              src={shipment}
                              className="cursor-pointer"
                              onClick={() =>
                                history.push(
                                  `/ship/${dataSource?.shipment?._id}`
                                )
                              }
                              width={30}
                              height={30}
                            />
                          </NavLink>
                        ) : (
                          "-"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <h4 className="heading-main">Customer Details</h4>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                  <div className="customerDetails-boder-line">
                    <p className="customer-primary">Customer Name</p>
                    <p
                      className="nav_link"
                      onClick={() =>
                        history.push(`/customer/${dataSource?.customer?._id}`)
                      }
                    >
                      {dataSource?.customer?.firstName
                        ? dataSource?.customer?.firstName
                        : "-"}{" "}
                      {dataSource?.customer?.lastName}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                  <div className="customerDetails-boder-line">
                    <p className="customer-primary">Customer Phone</p>
                    <p className="customer-primary-sub">
                      {dataSource?.customer?.phone
                        ? dataSource?.customer?.phone
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                  <div className="customerDetails-boder-line">
                    <p className="customer-primary">Customer Email</p>
                    <p className="customer-primary-sub">
                      {dataSource?.customer?.email
                        ? dataSource?.customer?.email
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="customer-details">
              <div className="row">
                <div className="back_arrow">
                  <h1 className="heading_details">Product Details</h1>
                </div>
              </div>
              <div className="customer-details-bg-wrapper-customer-order-history">
                {dataSource?.productsWithDetails &&
                  dataSource?.productsWithDetails.length > 0 &&
                  dataSource?.productsWithDetails.map((item) => (
                    <>
                      <div className="customer-details-wrapper">
                        <div className="row d-flex">
                          <div className="col-lg-2 col-sm-3 col-md-3 col-xs-2">
                          {item?.image ? (
                              item?.length > 0 && (
                                <img
                                  className="img_about2"
                                  alt="productImg"
                                  src={item?.image}    
                                ></img>
                              )
                            ) : (
                              <img
                                className="img_about2"
                                alt="dummyImg"
                                src={Img}
                              ></img>
                            )}

                            <div className="hide2">
                              {item?.image ? (
                                <img alt="" src={item?.image} />
                              ) : (
                                ""
                              )}
                            </div>
                           
                          </div>
                          <div className="col-lg-9 col-sm-8 col-md-8 col-xs-9">
                            <h4 className="customer-primary-sub-quote">
                              Inventory Number -
                            </h4>
                            <p
                              className="customer-primary-sub-quote"
                              style={{
                                color: "#1890ff",
                                textDecoration: "underline",
                              }}
                            >
                              <a
                                href={`https://hgrinc.com/productDetail/h/gr/${item?.code?.replaceAll(
                                  "-",
                                  ""
                                )}`}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                {item?.code ? "#" + item?.code : "-"}
                              </a>
                              {/* #{item?.code} */}
                            </p>
                            <p className="customer-primary-sub-quote">
                              Weight - {item?.weight ? item?.weight : "-"}
                            </p>
                            <p className="customer-primary-sub-quote">
                              L x W x H - {item?.length} x {item?.width} x{" "}
                              {item?.height}
                            </p>
                            <p className="customer-primary-sub-quote">
                              Quantity - {item?.quantity ? item?.quantity : "-"}
                            </p>
                            <p className="customer-primary-sub-quote">
                              Price -{" "}
                              {item?.price
                                ? parseFloat(item?.price).toFixed(2)
                                : "-"}
                            </p>
                            <p className="customer-primary-sub-quote-location">
                              Origin Location -{" "}
                              {item?.originLoc ? item?.originLoc : "-"}
                            </p>
                          </div>
                          <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1">
                            <p className="customer-primary-sub-quote-toggle">
                              {productId === item.code ? (
                                <img
                                  src={arrowUp}
                                  alt="arrow"
                                  style={{ cursor: "pointer" }}
                                  height={"10px"}
                                  width={"10px"}
                                  onClick={() => toggleReadMore(item?.code)}
                                />
                              ) : (
                                <img
                                  src={arrowDown}
                                  alt="arrow"
                                  style={{ cursor: "pointer" }}
                                  height={"10px"}
                                  width={"10px"}
                                  onClick={() => toggleReadMore(item?.code)}
                                />
                              )}
                            </p>
                          </div>
                        </div>

                        {productId === item.code && (
                          <div className="product_details-container_details">
                            <div className="back_arrow">
                              <h1 className="heading-detail">Carriers</h1>
                            </div>
                            <table className="table table-borderless">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    {dataSource?.shipmentType === "PARCEL"
                                      ? "Shipping Method"
                                      : "Carrier"}{" "}
                                  </th>
                                  <th scope="col">Shipping Quote</th>
                                  <th scope="col">Handling Quote</th>
                                  <th scope="col">Total Quote</th>
                                  <th scope="col">Customer Selected</th>
                                </tr>
                              </thead>
                              {item?.carriers &&
                                item?.carriers.length > 0 &&
                                item?.carriers.map((data) => (
                                  <tbody>
                                    <tr>
                                      <td>
                                        {data?.service_name
                                          ? data?.service_name
                                          : "-"}
                                      </td>
                                      <td>${data?.price.toLocaleString()}</td>
                                      <td>
                                        ${data?.handlingCharge.toLocaleString()}
                                      </td>
                                      <td>
                                        ${data?.totalCharge.toLocaleString()}
                                      </td>
                                      <td>
                                        {data?.userSelected === true ? (
                                          <img
                                            alt=""
                                            src={tickIcon}
                                            width={30}
                                            height={30}
                                          />
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              {item?.carriers &&
                                item?.carriers.length === 0 && (
                                  <p className="nodata text-left">
                                    No Data Found !
                                  </p>
                                )}
                            </table>

                            <div className="back_arrow">
                              <h1 className="heading-detail">
                                Shipping Details
                              </h1>
                            </div>

                            <table className="table table-borderless">
                              <thead>
                                <tr>
                                  <th scope="col">Shipping box(L x W x H)</th>
                                  <th scope="col">Total Items</th>
                                  <th scope="col">Tracking Number</th>
                                </tr>
                              </thead>

                              {item?.orderDetail &&
                                item?.orderDetail.length > 0 &&
                                item?.orderDetail.map((data) => (
                                  <tbody>
                                    <tr>
                                      <td>
                                        {data?.matchedBox
                                          ? data?.matchedBox?.length +
                                            " x " +
                                            data?.matchedBox?.height +
                                            " x " +
                                            data?.matchedBox?.width
                                          : "-"}
                                      </td>
                                      <td>
                                        {data?.matchedBox
                                          ? data?.matchedBox?.totalItems
                                          : "-"}
                                      </td>
                                      <td>
                                        {data?.TrackingNumber ? (
                                          <a
                                            href={`https://www.ups.com/track?trackNums=${data?.TrackingNumber}&requester=ST/trackdetails`}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                            className="nav-link"
                                          >
                                            {" "}
                                            {data?.TrackingNumber}{" "}
                                          </a>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              {item?.orderDetail &&
                                item?.orderDetail.length === 0 && (
                                  <p className="nodata text-left">
                                    No Data Found !
                                  </p>
                                )}
                            </table>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
export default QuoteDetail;
