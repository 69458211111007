export const emailRegx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordReg =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[(!@#\$%\^&\*])(?=.{8,})/;
export const phoneRegx =
  /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const urlRegx =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
// export const userName = /^[a-zA-Z,'. ]{3,50}$/;
export const userName = /^[a-zA-Z][a-zA-Z ]{3,}$/;
export const userNameWithSpace = /[a-zA-Z][a-zA-Z ]{2,10}/; //ex: user name
// export const price = /^(?!0+$)\d{1,6}(\.\d{1,2})?$/;
export const price = /^(?!0+$)\d{4,6}(\.\d{1,2})?$/;
export const note = /\S\w*/;
export const quantity = /^(?!0+$)\d{1,3}$/;
export const floatingZero = /^(?:0?[1-9]\d*(?:\.\d*[0-9]*)?)|(:?^0.[1-9]+0)$/;
export const zipcodeRegx = /^(?!0{5})\d{5,6}(?:[-\s]\d{4})?$/;
export const addressRegx = /^[#/.0-9a-zA-Z\s,-;'@(:)_]+$/;
export const companyNameRegx =
  /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?@&.!]{2,}$/;
export const otpRegx = /^\d{6}$/;
// export const alphaNum=/^[A-Za-z0-9]*$/;
export const alphaNum = /^[a-zA-Z0-9]{3,50}$/;
export const minChar = /^.{3,50}$/;
export const minChar10Limit = /^.{10,}$/;
export const minCharWithNoMaxLimit = /^.{3,}$/;
export const minCharLimit = /[a-zA-Z][a-zA-Z0-9 $&+,:;=?@#|'<>.^*()%!-]{1,}$/;
export const minCharLimit1 = /[a-zA-Z][a-zA-Z0-9 $&+,:;=?@#|'<>.^*()%!-]{0,}$/;
export const sku = /^[a-z0-9A-Z]{1,20}$/;
export const gst = /^([1-9]|[1-9][0-9]|[1-9][0-9][0-9])$/;
export const onlyNumbers = /^[0-9]*$/;
export const onlyAlphabets = /^[A-Za-z]+$/;
export const onlyAlphabetsWithSpace = /^[a-zA-Z ]*$/;
export const alphaNumwithSpace = /^[a-z\d\-_\s]+$/i;
export const alphanumericwithdashHyphenspace = /^[a-z\d\-_\s]+$/i;
export const alphanumericwithdashHyphen = /^[a-zA-Z0-9-_]+$/;
export const onlyNumbersWithLimit = /^[0-9]{1,2}$/;
export const alphanumericwithdashHyphenWithLimit = /^[a-zA-Z0-9-_]{3,}$/;
export const checkForHexRegExp = /(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
export const Saleswithletters = /Sales*/;
export const Catalogwithletters = /Catalog*/;
export const Discountwithletters = /Discount*/;
export const Customerwithletters = /Customer*/;
export const telephoneNumber =
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
export const onlyThreeNumbers = /^.{0,3}$/;
export const noSpace = /^\s+$/;
export const phoneNumber = /^[6-9]\d{9}$/;
export const minCharwithnospacecount = /\s*(?:[\w\.]\s*){3,10}$/;
export const onlyFiveNumbers = /^.{0,5}$/;
export const onlyTenNumbers = /^[0-9]{0,15}$/;
export const onlyAlphabetsWithSpaceMinThree = /^[a-zA-Z ]{3,50}$/;
