import LayoutComponent from "./Components/LayoutComponent/LayoutComponent";
import React, { useEffect } from "react";
import { Route, Router, withRouter, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/fonts/stylesheet.css";
import "./App.css";
import { useDispatch } from "react-redux";
import LoginComponent from "./Components/LoginComponent/LoginComponent";
import AuthGuard from "./AuthGuard";
import DashboardComponent from "./Components/DashboardComponent/DashboardComponent";
import * as action from "./Store/Actions/index";
import ListQuoteManagement from "./Components/QuoteManagementComponent/ListQuoteManagement";
import QuoteDetail from "./Components/QuoteManagementComponent/QuoteDetail";
import "bootstrap/dist/css/bootstrap.min.css";
import ShippingComponent from "./Components/ShippingManagement/ZoneList";
import viewShipping from "./Components/ShippingManagement/ViewZone";
import ViewShipLog from "./Components/ShippingManagement/ViewShipLog";
import "./styles/globals.css";
import ViewLog from "./Components/QuoteManagementComponent/ViewLog";
import CustomerComponent from "./Components/CustomerManagement/CustomerList";
import ViewCustomer from "./Components/CustomerManagementComponent/ViewCustomer";
import WarehouseComponent from "./Components/WarehouseManagement/WarehouseList"
import SettingComponent from "./Components/SettingComponent/SettingComponent"
import VendorAccountsComponent from "./Components/SettingComponent/VendorAccountsComponent"
import ApiKeyComponent from "./Components/ApiKeyManagement/ViewAPI"
import TabComponent from "./Components/WarehouseManagement/TabManagement"
import AddWarehouseComponent from "./Components/WarehouseManagement/AddWarehouse"
import AddAccountComponent from "./Components/WarehouseManagement/AddAccount";
import HandlingChargeComponent from "./Components/HandlingChargeComponent/HandlingChargeComponent"
import AddBillingLocationComponent from "./Components/WarehouseManagement/AddBillingLocationComponent"
import AddOriginLocationComponent from "./Components/WarehouseManagement/AddOriginLocationComponent"
import CompanyList from "./Components/CompanyManagement/CompanyList"
import AddCompanyComponent from "./Components/CompanyManagement/AddCompany"
import EditCompany from "./Components/CompanyManagement/EditCompany"

import LogisticsComponent from "./Components/LogisticsManagementComponent/LogisticsList"
import AddLogisticsComponent from "./Components/LogisticsManagementComponent/AddLogistics"
import AddHandlingChargeComponent from "./Components/HandlingChargeComponent/AddHandlingChargeComponent"
import CarriersList from "./Components/CarriersComponent/CarriersList";
import ListFreightClassComponent from "./Components/FreightClassComponent/ListFreightClassComponent"
import AddFreightClass from "./Components/FreightClassComponent/AddFreightClassComponent"
import ApikeyList from "./Components/ApiKeyManagement/ApiKeyList";
import AddApikey from "./Components/ApiKeyManagement/GenerateNewKey";
import AddCarriers from "./Components/CarriersComponent/AddCarriers";
import ListOriginComponent from "./Components/WarehouseManagement/OriginLocationList";
import ListBillingComponent from "./Components/WarehouseManagement/BillingLocationList";
import ListAccountCompopnent from "./Components/WarehouseManagement/AccountDetailsList";
import ForgotPasswordComponent from "./Components/LoginComponent/ForgotPassword";
import ListUserComponent from "./Components/UserComponent/ListUserComponent"
import AddUserComponent from "./Components/UserComponent/AddUserComponent"
import WelcomeComponent from "./Components/WelcomeManagement/WelcomeComponent";
import OtpVerificationComponent from "./Components/LoginComponent/OtpVerification";
import AbandonedComponent from "./Components/Abandoned/abandonedMangement";
import AbandonedDetailsComponent from "./Components/QuoteComponent/AbandonedQuoteDetails"

const App = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action.authCheckState());
  }, []);

  return (
    <Router history={props.history}>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/dashboard" />
        </Route>
        <Route path="/login" exact component={LoginComponent} />
        <Route path="/verify" exact component={OtpVerificationComponent} />
        <Route path="/forgot" exact component={ForgotPasswordComponent} />
        <Route path="/monitor" exact component={WelcomeComponent} />

        <LayoutComponent>
          <Route path="/dashboard" exact component={DashboardComponent} />
          <Route path="/quotes" exact component={ListQuoteManagement} />
          <Route path="/abandoned" exact component={AbandonedComponent} />
          <Route path="/quoteLog/:id" exact component={ViewLog} />
          <Route
            path="/quotes/getQuote/:id"
            exact
            component={QuoteDetail}
          />
            <Route
            path="/quotes/getQuotes/:id"
            exact
            component={AbandonedDetailsComponent}
          />
          <Route path="/ship" exact component={ShippingComponent} />
          <Route path="/ship/:id" exact component={viewShipping} />
          <Route path="/shipLog/:id" exact component={ViewShipLog} />
          <Route path="/customer" exact component={CustomerComponent} />
          <Route path="/customer/:id" exact component={ViewCustomer} />
          <AuthGuard path="/settings/handling-charge" exact component={HandlingChargeComponent} />
          <AuthGuard path="/settings/carriers" exact component={CarriersList} />
          <AuthGuard path="/settings/addCarriers" exact component={AddCarriers} />
          <AuthGuard path="/settings/editCarriers/:id" exact component={AddCarriers} />
		  {/* setting  */}
          <AuthGuard path="/settings" exact component={SettingComponent} />
          <AuthGuard path="/settings/vendorAccounts" exact component={VendorAccountsComponent} />
          {/* <AuthGuard path="/settings/apikey" exact component={ApiKeyComponent} /> */}
		  
          <AuthGuard path="/settings/addAccount" exact component={AddAccountComponent} />
          <AuthGuard path="/settings/editAccount/:id" exact component={AddAccountComponent} />

          <AuthGuard path="/settings/addWarehouse" exact component={AddWarehouseComponent} />
          <AuthGuard path="/settings/editWarehouse/:id" exact component={AddWarehouseComponent} />
          <AuthGuard path="/settings/warehouse" exact component={WarehouseComponent} />
          <AuthGuard path="/settings/addBillingLocation" exact component={AddBillingLocationComponent} />
          <AuthGuard path="/settings/editBillingLocation/:id" exact component={AddBillingLocationComponent} />
          <AuthGuard path="/settings/originLocation/addOriginLocation" exact component={AddOriginLocationComponent} />
          <AuthGuard path="/settings/editOriginLocation/:id" exact component={AddOriginLocationComponent} />
          <AuthGuard path="/settings/AddHandlingCharge" exact component={AddHandlingChargeComponent} />
          <AuthGuard path="/settings/EditHandlingCharge/:id" exact component={AddHandlingChargeComponent} />
          <AuthGuard path="/settings/listFreightClass" exact component={ListFreightClassComponent} />
          <AuthGuard path="/settings/addFreightClass" exact component={AddFreightClass} />
          <AuthGuard path="/settings/editFreightClass/:id" exact component={AddFreightClass} />

          <AuthGuard path="/settings/addCompany" exact component={AddCompanyComponent} />
          <AuthGuard path="/settings/editCompany/:id" exact component={EditCompany} />
          <AuthGuard path="/settings/company" exact component={CompanyList} />
          <AuthGuard path="/settings/addLogistics" exact component={AddLogisticsComponent} />
          <AuthGuard path="/settings/editLogistics/:id" exact component={AddLogisticsComponent} />
          <AuthGuard path="/settings/logistics" exact component={LogisticsComponent} />
          <AuthGuard path="/settings/addNewKey" exact component={AddApikey} />
          <AuthGuard path="/settings/apikey" exact component={ApikeyList} />
          <AuthGuard path="/settings/listOriginLocation" exact component={ListOriginComponent} />
          <AuthGuard path="/settings/listBillingLocation" exact component={ListBillingComponent} />
          <AuthGuard path="/settings/listAccountDetails" exact component={ListAccountCompopnent} />
          <AuthGuard path="/settings/addUser" exact component={AddUserComponent} />
          <AuthGuard path="/settings/editUser/:id" exact component={AddUserComponent} />
          <AuthGuard path="/settings/user" exact component={ListUserComponent} />


        </LayoutComponent>
      </Switch>
    </Router>
  );
};

export default withRouter(App);
