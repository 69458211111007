import * as actionTypes from "./ActionTypes";
import { AxiosInstance } from "../../utilities";
import jwt from "jsonwebtoken";

export const loginRequest = () => {
  return {
    type: actionTypes.LOGIN_REQUEST,
  };
};

export const loginSuccess = (token, data) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    token: token,
    data: data,
  };
};

export const loginFailure = (error) => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userdata");
  localStorage.removeItem("id");
  return {
    type: actionTypes.LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
    }, expirationTime * 1000);
  };
};

export const LoginActions = (loginForm, history = undefined) => {
  const historyExists = !!history;
  return (dispatch) => {
    dispatch(loginRequest());
    AxiosInstance(8000, "application/json")
      .post("/users/login", loginForm)
      .then((response) => {
        const {
          token,
          data,
          firstName,
          lastName,
          email,
          message,
          roleDetails,
          companyDetails,
        } = response.data;
        const userdetails = {
          firstName,
          lastName,
          email,
        };
        if (response.status === "200" || response.status === 200) {
          let istoken = token ? true : false;
          localStorage.setItem("istoken", istoken);
          console.log(JSON.stringify(companyDetails))
          if (true) {
            localStorage.setItem("token", token);
            localStorage.setItem("userdata", userdetails);
            //localStorage.setItem("roledata", roleDetails);
            localStorage.setItem('roledata', JSON.stringify(roleDetails));
            localStorage.setItem('companydata', JSON.stringify(companyDetails));
            // localStorage.setItem("companydata", companyDetails);
            historyExists ? history.push("/settings") : (window.location.href = "/settings");
          } else {
            localStorage.setItem("id", data.id);
          }
        } else {
          dispatch(loginFailure(message));
        }
      })
      .catch((e) => {
        let msg = e.response.data.message
        dispatch(loginFailure(msg));
      });
  };
};
const company = JSON.parse(localStorage.getItem("companydata"))
console.log("company",company)
export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    let decode = jwt.decode(token);
    if (!jwt.decode(token)) {
      dispatch(logout());
    } else {
      let dec = parseInt(decode.exp);
      if (dec <= (Date.now() / 1000).toFixed(0)) {
        dispatch(logout());
      } else {
        const data = localStorage.getItem("userdata");
        dispatch(loginSuccess(token, data));
      }
    }
  };
};
