export default function FormErrorText({ error, attributes }) {
  return error
    ? (console.log(error, attributes),
      (
        <div
          {...attributes}
          className="theme-txt-sm theme-error-txt text-right px-1 mr-1 mt-1"
        >
          {error}
        </div>
      ))
    : null;
}
