import React from 'react';
import "./DrawGraph.css";
import createPlotlyComponent from 'react-plotly.js/factory';
// const Plotly = window.Plotly;
// const Plot = createPlotlyComponent(Plotly)
import Plot from 'react-plotly.js';

export const drawGraph = (data, x, y, chartType, title, connectFunc, cWidth, cHeight, color, xtitle, ytitle) => {
    // console.log(data);
    // console.log(data[0]?.type);

    switch (chartType) {
        case 'pie':
            return (
                [<div id="chart" className="graph-layer ">
                    <Plot
                        // data={[{
                        //     values: y,
                        //     labels: x,
                        //     type: 'pie',
                        //     insidetextorientation: "radial",
                        // }]}
                        data={data}
                        layout={{
                            // grid: { rows: 0, columns: 0 },
                            // plot_bgcolor: '#30373c',
                            // paper_bgcolor: '#30373c',
                            font: {size: 9},
                            width: cWidth,
                            height: cHeight,
                            title: title,
                            hoverlabel: { bgcolor: "rgba(0, 0, 0, 0.87);" },
                            margin: { l: 10, r: 10, b: 10, t: 10, pad: 0 },
                            legend: { 
                                "orientation": "v",
                            },
                            annotations: [
                                {
                                  font: {
                                    size: 10
                                  },
                                  showarrow: false,
                                  text: data[0].total,
                                }
                            ],
                        }}
                        config={{ displayModeBar: false, responsive: true, automargin: true, }}
                        onClick={(e) => connectFunc ? connectFunc(e) : null}
                    />
                </div>]
            );
            case 'ABpieChart':
            return (
                [<div id="chart" className="graph-layer ">
                    <Plot
                        // data={[{
                        //     values: y,
                        //     labels: x,
                        //     type: 'pie',
                        //     insidetextorientation: "radial",
                        // }]}
                        data={data}
                        layout={{
                            // grid: { rows: 0, columns: 0 },
                            // plot_bgcolor: '#30373c',
                            // paper_bgcolor: '#30373c',
                            font: {size: 9},
                            width: cWidth,
                            height: cHeight,
                            title: title,
                            hoverlabel: { bgcolor: "rgba(0, 0, 0, 0.87)"},
                            margin: { l: 10, r: 10, b: 10, t: 10, pad: 0 },
                            legend: { 
                                "orientation": "h",
                            },
                        }}
                        config={{ displayModeBar: false, responsive: true, automargin: true, }}
                        onClick={(e) => connectFunc ? connectFunc(e) : null}
                    />
                </div>]
            );
        case 'grpBar':
            return (
                [<div id="chart" className="graph-layer">
                    <Plot
                        data={data}
                        layout={{
                            responsive: true,
                            width: cWidth,
                            height: cHeight,
                            margin: {l: 10, r: 10, b: 10, t: 25, pad: 0},
                            font: {size: 9},
                            automargin: true,
                            title: {
                                text: title,
                                font: {
                                    size: 15
                                },
                                x: 0.01
                            },
                            // showlegend: false,
                            legend: {
                                x: 0.75,
                                y: 1.0,
                                // bgcolor: 'rgba(255, 255, 255, 0)',
                                // bordercolor: 'rgba(255, 255, 255, 0)'
                            },
                            barmode: 'group',
                            xaxis: { title: x, showgrid: false, autorange: true, fixedrange: true},
                            yaxis: { title: y, showgrid: false, automargin:true, fixedrange: true}
                        }}
                        config={{ scrollZoom: false, displayModeBar: false, responsive: true }}
                        onClick={(e) => connectFunc ? connectFunc(e) : null}
                    />
                </div>, x, y]
            );
        case 'bar':
            return (
                [<div id="chart" className="graph-layer">
                    <Plot
                        data={data}
                        layout={{
                            family: `JosefinSans`,
                            responsive: true,
                            width: cWidth,
                            height: cHeight,
                            hoverlabel: { bgcolor: "rgba(0, 0, 0, 0.87);" },
                            margin: {l: 10, r: 10, b: 10, t: 10, pad: 0},
                            font: {size: 9},
                            // automargin: true,
                            // showlegend: false,
                            legend: {
                                // x: 1,
                                // xanchor: 'right',
                                // y: 1
                                "orientation": "h"
                            },
                            xaxis: { title: x, autorange: true, fixedrange: true},
                            yaxis: { title: y, tickformat: '$', automargin:true, range: [0, data[0]?.valueShip], fixedrange: true}
                        }}
                        config={{ scrollZoom: false, displayModeBar: false, responsive: true }}
                        onClick={(e) => connectFunc ? connectFunc(e) : null}
                    />
                </div>, x, y]
            );
            default:
                break;
    }
}