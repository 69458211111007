export const emailRegx = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[(!@#\$%\^&\*])(?=.{8,})/;
export const phoneRegx = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
export const userNameWithSpace = /^[A-Za-z][A-Za-z ]{2,30}$/; //ex: user name
export const twodigit = /^[A-Za-z][A-Za-z ]{1,30}$/; //ex: user name
export const userName = /^[a-zA-Z][a-zA-Z ]{3,}$/;
export const allCharWithSpaceInbt=/[A-Za-zÀ-ȕ0-9(),-_.,]{3,10}/;
export const alphaNum = /^[A-Za-z0-9][A-Za-z0-9 ]{2,30}$/;
export const postalCode = /^(?=.*[A-Za-z0-9])[A-Za-z0-9-_]{2,6}$/;
export const numbersAlphabetsWithSpecialCharactersSpace = /^(?=.*[A-Za-z0-9])[A-Za-z0-9-_/*@#$. ]{2,30}$/;
export const otpRegx = /^\d{6}$/;
export const onlyNumbers = /^[0-9]{2,30}$/; //1234
export const onlyNumbersWithFloat = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/; // Freight class ( please change this)
export const price = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/; // price 
export const onlyAlphabetsWithSpace = /^[A-Za-z][A-Za-z ]{2,30}$/;
export const onlyAlphabetsWithSpaceSpecialCharacter = /^[A-Za-z][A-Za-z-_/*@#$. ]{2,30}$/;
export const numbersAlphabetsWithoutSpace = /^[A-Za-z0-9]{2,30}$/;
export const onlyAlphabetsWithoutSpace = /^[A-Za-z]{2,30}$/;   
export const onlyUppercaseAlphabetsWithoutSpace = /^[A-Za-z]{3,6}$/; 
// export const twoDigitWithFloat = /^(?:\d*\.\d{1,2}|\d+)$/
export const twoDigitWithFloat = /^(\d{0,4}|-)(\.\d{1,2})?$/;