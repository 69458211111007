import React from "react";
import commonstyles from "../../styles/SettingsTable.module.css";
import eyeView from "../../assets/img/action_view.svg";
import { useHistory } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { AxiosInstance } from "../../utilities";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import "antd/dist/antd.css";
import deleteIcon from "../../assets/img/delete.svg";
import editIcon from "../../assets/img/edit.svg";
import { NavLink } from "react-router-dom";
import { Modal, Button, Space, notification, Menu } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import arrowDown from "../../assets/images/arrow_down.svg";
import BackArrow from "../../assets/icons/back_arrow.svg";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

function WarehouseList() {
  const history = useHistory();
  const [regionList, setRegionList] = useState([]);
  // const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setpagination] = useState({});
  const [IsLoading, setIsLoading] = useState(false);
  const company = JSON.parse(localStorage.getItem("companydata"));
  const { confirm } = Modal;
  const [getCompany, setGetCompany] = useState([]);
  const [filterCompany, setfilterCompany] = useState("");
  const [logisticCode, setLogisticCode] = useState("");
  const [logistic, setLogistic] = useState([]);

  const [columns, setColumns] = useState([
    /* {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    }, */
    {
      title: "Company name",
      visible: false,
      key: "company",
      render: (text, record, index) => <span>{record?.company?.code}</span>,
    },
    {
      title: "Logistic",
      visible: false,
      key: "logisticCode",
      render: (text, record, index) => <span>{record?.logisticCode ? record?.logisticCode : "-"}</span>,
    },
    {
      title: "Warehouse name",
      visible: false,
      key: "warehouse",
      render: (text, record, index) => (
        <span>{record?.name ? record?.name : "-"}</span>
      ),
    },
    /* {
      title: "Logistic Name",
      visible: false,
      key: "logisticCode",
      render: (text, record, index) => (
        <span>{record?.logisticCode ? record?.logisticCode : "-"}</span>
      ),
    }, */
    {
      title: "Origin Location",
      visible: false,
      key: "originLocation",
      render: (text, record, index) => (
        <span>
          {record?.originLocation?.displayName  ? record?.originLocation?.displayName  : "-"}
        </span>
      ),
    },
    {
      title: "Billing Location",
      visible: false,
      key: "billingLocation",
      render: (text, record, index) => (
        <span>
          {record?.billingLocation?.displayName  ? record?.billingLocation?.displayName  : "-"}
        </span>
      ),
    },
    {
      title: "Account",
      visible: false,
      key: "accountDetail",
      render: (text, record, index) => (
        <span>
          {record?.accountDetail?.displayName 
            ? record?.accountDetail?.displayName  
            : "-"}
        </span>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) => (
        <>
          <NavLink to={`/settings/editWarehouse/${record._id}`}>
            <img
              src={editIcon}
              className={`${commonstyles.admin_table_edit_img} mr-2`}
              alt="Edit  warehouse details"
              title="Edit  warehouse"
              style={{ cursor: "pointer" }}
            />
          </NavLink>
          <img
            src={deleteIcon}
            className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
            onClick={() => showDeleteConfirm(record._id)}
            // onClick={showDeleteConfirm}
            alt="Delete warehouse"
            title="Delete warehouse"
          />
        </>
      ),
    },
  ]);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }
  useEffect(() => {
    getCompanyDetail();
    getLogistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getWarehouse(pageSize, nextPage, "");
    localStorage.removeItem("selectedIds");
    return () => {
      localStorage.removeItem("regionList");
      localStorage.removeItem("selectedIds");
    };
  }, [filterCompany,logisticCode]);

  function getLogistics() {
    AxiosInstance(8000, "application/json")
      .get("logistics/getLogistics")
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setLogistic(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      });
  }

  function getCompanyDetail() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getCompanies/settingsSection?pageNum=1&count=20&sortField=createdAt&sortValue=DSC`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetCompany(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get company details",
        };
        showNotification(error);
        // return er;
      });
  }
  // *** FETCH WAREHOUSE LIST *** //
  function getWarehouse(pageSize, nextPage, filterarr, type) {
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };

    AxiosInstance(8000, "application/json")
      .get(
        `companies/getWarehouses?pageNum=${nextPage}&count=${pageSize}&sortField=createdAt&sortValue=DSC&companyId=${filterCompany}&logisticCode=${logisticCode}`,
        filterOptions
      )
      .then((res) => {
        // console.log(filterOptions);
        if (res && res.data && res.status == "200") {
          // console.log(res.data);
          if (res?.data?.data.length > 0) {
            res?.data?.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // console.log(res?.data?.data)
            localStorage.setItem("regionList", JSON.stringify(res?.data?.data));
            setRegionList(res?.data?.data);
            setpagination(res.data.pagination);
          } else {
            setRegionList([]);
            setpagination({});
          }
        }
      })
      .catch((er) => {
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get warehouse",
        };
        showNotification(error);
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }
  /* ADD WAREHOUSE */
  function addWarehouse() {
    history.push(`/settings/addWarehouse`);
  }

  // DELETE WAREHOUSE
  function showDeleteConfirm(id) {
    // console.log(id);
    confirm({
      title: "Are you sure want to delete this ?",
      icon: <ExclamationCircleOutlined />,
      // content: "Some descriptions",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        deleteWarehouse(id);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  }

  function deleteWarehouse(id) {
    // console.log(id);
    setIsLoading(true);
    AxiosInstance(8000, "application/json")
      .delete(`companies/removeWarehouse/${id}`)
      .then((res) => {
        // console.log(res);
        if (res && res?.data && res?.status == "200") {
          showNotification({
            type: "success",
            message: "Success",
            description: "Warehouse deleted successfully",
          });
          setIsLoading(false);
          getWarehouse(pageSize, nextPage, "");
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Failed to delete warehouse",
          });
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function debounceSearch(filter) {
    // console.log(filter);
    setIsLoading(true);
    setNextPage(1);
    getWarehouse(pageSize, 1, filter);
  }
  //PAGINATION
  function handlepaginationChanges(value, type) {
    let filter = {};
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getWarehouse(value, 1, filter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getWarehouse(pageSize, value, filter);
    }
  }
  function filterOnChange(e) {
    const { name, value } = e.target;
    // console.log(value);
    setfilterCompany(value);
    // setIsLoading(true);
  }
  function handleFormInput(e) {
    let { name, value } = e.target;
    setLogisticCode(value);
  }
  //RETURN
  return (
    <div className={commonstyles.management_contents}>
      <div className="">
        <div className="row d-flex">
          <div className="back_arrow">
            <img
              src={BackArrow}
              onClick={() => history.push("/settings")}
              alt=""
              className="cursor-pointer"
              title="back"
              width={"25px"}
              height={"21px"}
            />
            <h1 className="heading">Warehouse</h1>
          </div>
        </div>
      </div>
      <div className={commonstyles.management_header}>
      <div className="row">
          <div className="col-lg-6">
          <button
          type="button"
          className="btn btn-outline-primary"
          onClick={addWarehouse}
        >
          Add Warehouse
        </button>
          </div>
          <div className="col-lg-6 d-flex flex-row-reverse">
          <div className={` filter_width ${commonstyles.dropdown}`}>
          <Menu className={`filter_dropdown datefilter`}>
          <div className="card">
            <div className="card_contents">
              <div className="row no-gutters">
                <div className="card_dropdown show col-md-6">
                  <select
                    name="logisticCode"
                    value={logisticCode}
                    onChange={handleFormInput}
                    className={`form-control  ${commonstyles.form_control} `}
                    style={{ cursor: "pointer" }}
                  >
                    <option value="">ALL</option>
                    {Array.isArray(logistic)
                     ? logistic.sort((a, b) => (a.code > b.code ? 1 : -1)).map((logi) => (
                          <option key={logi._id} value={logi.code}>
                            {logi.code}
                          </option>
                        ))
                      : null}
                  </select>
                  <img src={arrowDown} className="dropDownArrow" />
                </div>
              </div>
            </div>
          </div>
        </Menu>
        </div>
        <div className={` filter_width ${commonstyles.dropdown}`}>
          <Menu className={`filter_dropdown datefilter`}>
            <div className="card">
              <div className="card_contents">
                <div className="row no-gutters">
                  <div className="card_dropdown show col-md-6">
                    <select
                      name="filterCompany"
                      value={filterCompany}
                      onChange={filterOnChange}
                      className={`form-control  ${commonstyles.form_control} `}
                      style={{ cursor: "pointer" }}
                    >
                      <option value="">ALL</option>
                      {Array.isArray(getCompany)
                        ? getCompany.sort((a, b) => (a.code > b.code ? 1 : -1)).map((company) => (
                            <option key={company?.code} value={company?._id}>
                              {company?.code}
                            </option>
                          ))
                        : null}
                    </select>
                    <img src={arrowDown} className="dropDownArrow" />
                  </div>
                </div>
              </div>
            </div>
          </Menu>
        </div>
          </div>
        </div>

        
      
      </div>
      <div /* className={styles.administrator} */>
        <div
          className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
        >
          {/* <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <input
            type="search"
            className={`${commonstyles.form_control} form-control`}
            aria-label="Search"
            placeholder="Search by Phone Number, Warehouse Name and logName "
            aria-describedby="search-addon"
            value={search}
            onChange={ handleInput}
          />
          <img src={Search} alt="" />
        </div> */}
        </div>
        <div className={`${commonstyles.management_table} table-responsive`}>
          {/* {!IsLoading && regionList.length > 0 && (
                    <input
                        type="checkbox"
                        className={`${commonstyles.parentCheckbox}`}
                        onChange={parentCheckbox}
                    ></input>
                )} */}
          {IsLoading === true ? (
            <div
              style={{
                marginLeft: "70vh",
                marginTop: "10vh",
              }}
            >
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching Warehouse details..." />
                </td>
              </tr>
            </div>
          ) : (
            <AntTableComponent
              className="order_table"
              tableProps={{
                columns: columns,
                dataSource: regionList,
                pagination: false,
              }}
            />
          )}
        </div>
      </div>
      {!IsLoading.list && Array.isArray(regionList) && regionList.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          handlepaginationChanges={handlepaginationChanges}
        />
      ) : null}
    </div>
  );
}

export default WarehouseList;
