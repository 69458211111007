import React from "react";
import commonstyles from "../../styles/SettingsTable.module.css";
import { useHistory } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import BackArrow from "../../assets/icons/back_arrow.svg";
import { AxiosInstance } from "../../utilities";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import "antd/dist/antd.css";
import deleteIcon from "../../assets/img/delete.svg";
import editIcon from "../../assets/img/edit.svg";
import { NavLink } from "react-router-dom";
import { Modal, Button, Space, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

function CompanyList() {
  const history = useHistory();
  const [companyList, setCompanyList] = useState([]);
  // const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setpagination] = useState({});
  const [IsLoading, setIsLoading] = useState(false);
  const company = JSON.parse(localStorage.getItem("companydata"));
  const { confirm } = Modal;
  const [columns, setColumns] = useState([
    /* {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    }, */
    {
      title: "Site Name",
      visible: false,
      key: "name",
      render: (text, record, index) => (
        <span>{record?.name ? record?.name : "-"}</span>
      ),
    },
    {
      title: "Code",
      visible: false,
      key: "code",
      render: (text, record, index) => (
        <span>{record?.code ? record?.code : "-"}</span>
      ),
    },
    {
      title: "Date",
      visible: false,
      key: "createdAt",
      render: (text, record, index) => (
        <span>
          {moment(record?.createdAt ? record?.createdAt : "-").format(
            "MMM DD YYYY"
          )}
        </span>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) => (
        <>
          <NavLink to={`/settings/editCompany/${record.code}`}>
            <img
              src={editIcon}
              className={`${commonstyles.admin_table_edit_img} mr-2`}
              alt="Edit Site Details"
              title="Edit site"
              style={{ cursor: "pointer" }}
            />
          </NavLink>
          <img
            src={deleteIcon}
            className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
            onClick={() => showDeleteConfirm(record.code)}
            alt="Delete Site"
            title="Delete site"
          />
        </>
      ),
    },
  ]);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  useEffect(() => {
    getCompany(pageSize, nextPage, "");
    localStorage.removeItem("selectedIds");
    return () => {
      localStorage.removeItem("companyList");
      localStorage.removeItem("selectedIds");
    };
  }, []);

  // *** GET COMPANY LIST *** //
  function getCompany(pageSize, nextPage, filterarr, type) {
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };

    AxiosInstance(8000, "application/json")
      .get(
        `companies/getCompanies/settingsSection?pageNum=${nextPage}&count=${pageSize}&sortField=createdAt&sortValue=DSC`,
        filterOptions
      )
      .then((res) => {
        // console.log(filterOptions);
        if (res && res.data && res.status == "200") {
          // console.log(res.data);
          if (res?.data?.data.length > 0) {
            res?.data?.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // console.log(res?.data?.data)
            localStorage.setItem(
              "companyList",
              JSON.stringify(res?.data?.data)
            );
            setCompanyList(res?.data?.data);
            setpagination(res.data.pagination);
          } else {
            setCompanyList([]);
            setpagination({});
          }
        }
      })
      .catch((er) => {
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get site",
        };
        showNotification(error);
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }
  /* ADD COMPANY TO RE-DIRECT */
  function addCompany() {
    history.push(`/settings/addCompany`);
  }

  // DELETE
  function showDeleteConfirm(code) {
    confirm({
      title: "Are you sure want to delete this ? ",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        deleteWarehouse(code);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  }

  function deleteWarehouse(code) {
    // console.log(id);
    setIsLoading(true);
    AxiosInstance(8000, "application/json")
      .delete(`companies/removeCompany/${code}`)
      .then((res) => {
        // console.log(res);
        if (res && res?.data && res?.status == "200") {
          showNotification({
            type: "success",
            message: "Success",
            description: "Site deleted successfully",
          });
          getCompany(pageSize, nextPage, "");
          setIsLoading(false);
        } else if (res.status === 400) {
          showNotification({
            type: "error",
            message: "Failed to delete",
            description: "Error occured",
          });
        }
      })
      .catch((er) => {
        // return er;
        showNotification({
          type: "Error",
          message: "Error",
          description: "Failed to delete site",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function debounceSearch(filter) {
    // console.log(filter);
    setIsLoading(true);
    setNextPage(1);
    getCompany(pageSize, 1, filter);
  }

  //PAGINATION
  function handlepaginationChanges(value, type) {
    let filter = {};
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getCompany(value, 1, filter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getCompany(pageSize, value, filter);
    }
  }

  //RETURN
  return (
 
      <div className={commonstyles.management_contents}>
      <div className="row d-flex">
          <div className="back_arrow">
            <img
              src={BackArrow}
              onClick={() => history.push("/settings")}
              alt=""
              className="cursor-pointer"
              title="back"
              width={"25px"}
              height={"21px"}
            />
            <h1 className="heading">Site</h1>
          </div>
        </div>
        <div className={commonstyles.management_header}>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={addCompany}
          >
           Add Site
          </button>
        </div>
        <div>
          <div
            className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
          >
            {/* <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <input
            type="search"
            className={`${commonstyles.form_control} form-control`}
            aria-label="Search"
            placeholder="Search by Phone Number, Warehouse Name and logName "
            aria-describedby="search-addon"
            value={search}
            onChange={ handleInput}
          />
          <img src={Search} alt="" />
        </div> */}
          </div>
          <div className={`${commonstyles.management_table} table-responsive`}>
            {/* {!IsLoading && companyList.length > 0 && (
                    <input
                        type="checkbox"
                        className={`${commonstyles.parentCheckbox}`}
                        onChange={parentCheckbox}
                    ></input>
                )} */}
            {IsLoading === true ? (
              <div
                style={{
                  marginLeft: "70vh",
                  marginTop: "10vh",
                }}
              >
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching Site details..." />
                  </td>
                </tr>
              </div>
            ) : (
              <AntTableComponent
                className="order_table"
                tableProps={{
                  columns: columns,
                  dataSource: companyList,
                  pagination: false,
                }}
              />
            )}
          </div>
        </div>
        {!IsLoading.list && Array.isArray(companyList) && companyList.length ? (
          <Pagination
            pageSizes={pageSizes}
            pageSize={pageSize}
            nextPage={nextPage}
            data={pagination}
            handlepaginationChanges={handlepaginationChanges}
          />
        ) : null}
      </div>
   
  );
}

export default CompanyList;
