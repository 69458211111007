import React, { useState, useEffect } from "react";
import styles from "../../styles/Warehouse.module.css";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification, Select } from "antd";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import SelectComponent from "../ReusableComponent/SelectComponent";
import FormErrorText from "../FormErrorText";
import {
  userNameWithSpace,
  passwordReg,
  alphaNum,
  twoDigitWithFloat
} from "../RegularExp/RegularExp";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import validationRules from "../../validationRules";
import { isObject, isString, isEqual } from "lodash";
import { Link, useParams } from "react-router-dom";
import eyeOpen from "../../assets/icons/eye_icon.svg";
import eyeClose from "../../assets/icons/eye_close.svg";
const { Option } = Select;

const initialFormState = {
  name: "",
  logisticCode: [],
};

const refundNew = {
  defaultLogisticCode: "",
  defaultCharge: "",
};

const refund = JSON.parse(JSON.stringify(refundNew));

const rules = {
  refundform: [
    (val) => {
      if (Array.isArray(val)) {
        // console.log(val);
        const errors = val.reduce(validateAvenueformItem, {});
        if (Object.keys(errors).length) {
          return errors;
        }
        return true;
      }
    },
  ],
};

function validateAvenueformItem(accumulator, refundItem, indx) {
  const error = {};
  ["defaultLogisticCode", "defaultCharge"].forEach((field) => {
    // console.log(field);
    if (!refundItem[field]) {
      error[field] = field + " ";
    }
    if (!field === "defaultLogisticCode" || !alphaNum.test(refundItem[field])) {
      error[field] = field + " ";
    }
  });
  if (Object.keys(error).length) {
    accumulator[indx] = error;
  }
  return accumulator;
}

export default function EditCompanyComponent() {
  const history = useHistory();
  const { id } = useParams();

  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const [logistic, setLogistic] = useState([]);
  const company = JSON.parse(localStorage.getItem("companydata"));
  const [showOption, setShowOption] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);

  const [refundform, setRefundForm] = useState([refund]);
  const selectedLogisticCodes = Array.isArray(refundform)
    ? refundform.map((stock) => stock.defaultLogisticCode)
    : [];
  // console.log(selectedLogisticCodes);

  const rules = {
    name: [validationRules.required],
  };

  useEffect(() => {
    getLogistics();
    // console.log(id);
    if (id) {
      getCompanyDetails(id);
    } else {
      setIsLoading(false);
    }
  }, [refundform?._id]);

  /**
   * @author Prasanthu
   * GET LOGISTIC DETAILS
   */
  function getLogistics() {
    AxiosInstance(8000, "application/json")
      .get(
        "logistics/getLogistics?pageNum=1&count=10&sortField=createdAt&sortValue=DSC"
      )
      .then((res) => {
        // console.log(res);
        if (res && res.data && res.status == "200") {
          // console.log(res.data.data);
          setLogistic(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      });
  }

  /**
   * @author Prasanthu
   * GET DETAILS
   */
  function getCompanyDetails(id) {
    //  console.log(id);
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`companies/getCompany/${id}`)
      .then((res) => {
        // console.log(res);
        if (res && res?.data && res?.status == "200") {
          const val = res.data;
          // console.log(val)
          // setEditForm(val);
          const value = val.integratedLogistics.map((item) => {
            return item.code;
          });
          //   console.log(value);
          // editForm.logisticCode(value)
          setEditForm({ ...val, logisticCode: value });
          const valueDefaultHandle = val.defaultHandleCharges.map((item) => {
            // console.log(item);
            // return item
            return {
              defaultLogisticCode: item.logisticCode,
              defaultCharge: item.charge,
            };
          });
          const a = valueDefaultHandle.map((item) => {
            // console.log(item);
            return item;
          });
          //   console.log("___________",valueDefaultHandle,);
          setRefundForm(a);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }
  // console.log(editForm);

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    let { name, value } = e.target;
    // console.log(name, value);

    if (name === "name") {
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 3 and maximum 30)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    const updatedValues = { ...editForm };
    updatedValues[name] = value;
    setEditForm(updatedValues);
  }

  function handleLogisticChange(e) {
    if (e.length < 0) {
      // console.log(".............");
      formErrors.logisticCode = "Required";
    } else {
      formErrors.logisticCode = "";
    }

    editForm.logisticCode = e;
    setEditForm({ ...editForm });
  }

  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  // console.log(editForm);

  function handleAvenueFormChange(e, indx) {
    let { name, value, checked } = e.target;
    value = value.trim();
    if (["defaultCharge", "defaultLogisticCode"].includes(name)) {
      if (name == "defaultLogisticCode") {
        // console.log("--++++++++++++--");
        if (logistic.map((item) => item.code == value.toString())) {
          let filtered = logistic.filter(function (item, index, array) {
            return value.toString() != item.code;
          });
          setSelectedValue(filtered);
        } 
        if (!value && value === "") {
          formErrors.refundform[indx].defaultLogisticCode = "Required";
        } else {
          if (
            formErrors.refundform &&
            formErrors.refundform[indx] &&
            formErrors.refundform[indx].defaultLogisticCode
          ) {
            formErrors.refundform[indx].defaultLogisticCode = "";
          }
        }
      }
      /* if (name === "defaultCharge") {
        // console.log("-------------------");
        // console.log(value);
        if (!alphaNum.test(value)) {
          if (!formErrors.refundform) {
            // console.log("if1");
            formErrors.refundform = {};
          }
          if (formErrors.refundform && !formErrors.refundform[indx]) {
            // console.log("if2");

            formErrors.refundform[indx] = {};
          }
          // console.log("ifout");
          formErrors.refundform[indx][name] = "";
          // formErrors.defaultCharge[indx][name] = "Enter number only";
        }
      } else {
        if (
          // !value &&
          formErrors.refundform &&
          formErrors.refundform[indx] &&
          formErrors.refundform[indx][name]
        ) {
          // console.log("else");
          formErrors.refundform[indx][name] = "";
        }
      } */
      if (name === "defaultCharge") {
        console.log(value);
        /* if (!twoDigitWithFloat.test(value) ) {
          // hasError = true;

          formErrors.defaultCharge[indx][name] = "Please enter a valid number";
          // formErrors.refundform[indx].defaultCharge =
          //   "Please enter a valid number";
            return false;
        }else{
          formErrors.refundform[indx].defaultCharge ="";
        } */
        if (!twoDigitWithFloat.test(value)) {
          console.log(value);
          if (!formErrors.refundform) {
            formErrors.refundform = {};
          }
          if (formErrors.refundform && !formErrors.refundform[indx]) {
            formErrors.refundform[indx] = {};
          }
          formErrors.refundform[indx].defaultCharge = "Please enter a valid number";
          // console.log(alphaNum.test(value))
        } else {
          if (
            formErrors.refundform &&
            formErrors.refundform[indx] &&
            formErrors.refundform[indx].defaultCharge
          ) {
            formErrors.refundform[indx].defaultCharge = "";
          }
        }
      }
      // console.log(name);
      refundform[indx][name] = value;
      setRefundForm([...refundform]);
    }
  }

  function addNew() {
    //already updated refund data form
    const updatedValue = JSON.parse(JSON.stringify(refundform));
    //whenever add a new feild it will empty
    const newData = JSON.parse(
      JSON.stringify({ defaultLogisticCode: "", defaultCharge: "" })
    );
    updatedValue.push(newData);

    //this is new object created for as refund data form
    // const newAvenue = JSON.parse(JSON.stringify(refund));
    //pushing new object with already updated record i.e refund form data
    // updatedValue.push(newAvenue);
    //then set old and new record to refund form
    setRefundForm(updatedValue);
  }

  function runValidationChecks(rules, name = undefined, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      // const fieldRules = rules[name];
      // if (Array.isArray(fieldRules)) {
      //   for (const rule in fieldRules) {
      //     const val = rule(data[name]);
      //     if (isString(val) || isObject(val)) {
      //       // setFormErrors({ ...formErrors, [name]: val });
      //     } else if (name in formErrors) {
      //       const { name, ...restOfErros } = formErrors;
      //       // setFormErrors(restOfErros);
      //     }
      //   }
      // }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  function submit(e) {
    // let data = JSON.parse(JSON.stringify(editForm));
    // console.log(refundform);
    let hasError = false;
    setFormErrors({ refundform: [] });
    let formErrors = { refundform: [] };
    refundform.forEach((faq, ind) => {
      formErrors.refundform[ind] = {};
      // console.log("Required");
      if (faq.defaultLogisticCode === "") {
        hasError = true;
        formErrors.refundform[ind].defaultLogisticCode = "Required";
      } else {
        formErrors.refundform[ind].defaultLogisticCode = "";
      }
      // console.log(faq.defaultCharge);
      if (faq.defaultCharge === "") {
        hasError = true;
        formErrors.refundform[ind].defaultCharge = "Required";
      } else if (!twoDigitWithFloat.test(faq.defaultCharge) && faq.defaultCharge !== "") {
        hasError = true;
        formErrors.refundform[ind].defaultCharge = "Please enter a valid number";
      }else if (
        !alphaNum.test(faq.defaultCharge) &&
        !faq.defaultCharge === ""
      ) {
        // hasError = true;
        formErrors.refundform[ind].defaultCharge = "Enter number only";
      }
    });
    console.log(editForm);
    console.log(editForm.logisticCode === "" ? "requii" : "plplp");

    if (editForm.logisticCode == "") {
      hasError = true;
      formErrors.logisticCode = "Required";
    } else {
      // hasError = false;
      formErrors.logisticCode = "";
    }

   /*  // console.log(formErrors);
    if (hasError) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      setFormErrors({ ...formErrors });
      return;
    } */

    let logis = editForm.logisticCode.map((a) => {
      return { logisticCode: a };
    });
    let logisWithCharge = refundform.map((item) => {
      return {
        _id: item._id,
        logisticCode: item.defaultLogisticCode,
        charge: parseFloat(item.defaultCharge),
      };
    });

    let data = {
      name: editForm.name,
      integratedLogistics: logis,
      defaultHandleCharges: logisWithCharge,
    };

    if (data.name) {
      if (!userNameWithSpace.test(data.name) || !data.name === "") {
        setFormErrors({
          ...formErrors,
          name: "Enter only alphabets (minimum 3 and maximum 30)",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, name: "" });
      }
    }

    delete data.createdAt;
    delete data.updatedAt;
    delete data.__v;
    delete data.deleted;

    // console.log(data);
    let dataValue = JSON.parse(JSON.stringify(data));

    const result = runValidationChecks(rules, undefined, dataValue);
    // console.log(result);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }else if (hasError) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      setFormErrors({ ...formErrors });
      return;
    }

    
    setLoading({ ...loading, update: true });

    // console.log(result);
    if (id) {
      AxiosInstance(8000, "application/json")
        .put(`companies/updateCompany/${editForm.code}`, dataValue)
        .then((res) => {
          //   console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Site updated successfully",
            });
            history.push(`/settings/company`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding Site failed",
              description: "Error occured",
            });
          }
        })
        .catch((error) => {
          showNotification({
            type: "error",
            message: "Site failed",
            description: error?.response?.data?.message,
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt="Back Arrow"
            className="cursor-pointer"
            onClick={() => history.push("/settings/company")}
          />
          {id ? "Edit Site" : "Add Site"}
        </h1>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching Site detail..." />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>
          <div className={styles.management_details_contents}>
            <div className="row">
              {/* Site */}
              <div className="col-lg-6 mb-4">
                <label className="theme-form-label">
                  Site
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.name}
                  formControl={{
                    placeholder: "Type the Site",
                    name: "name",
                    value: editForm?.name,
                    onChange: handleFormInput,
                  }}
                />
                <div
                
                >
                  <FormErrorText error={formErrors.name} />
                </div>
              </div>
              {/* Site */}

              <div className="col-lg-6 mb-4">
                <label>
                  Logistic
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <div className={styles.management_details_form}>
                  <Select
                    // error={formErrors.logisticCode}
                    className={`form-control-lg mb-3
                                ${
                                  formErrors.logisticCode && "theme-error-input"
                                } `}
                    mode="multiple"
                    name="logisticCode"
                    style={{ width: "100%" }}
                    placeholder="Select logistics name"
                    onChange={(e) => handleLogisticChange(e)}
                    optionLabelProp="label"
                    value={editForm?.logisticCode}
                  >
                    {logistic.map((logi) => (
                      <Option value={logi.code} label={logi.code}>
                        <div className="demo-option-label-item">
                          {logi.code}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </div>
                <div
                
                >
                  <FormErrorText error={formErrors.logisticCode} />
                </div>
              </div>

              {refundform.map((refund, indx) => (
                <React.Fragment key={indx}>
                  <div className={styles.management_details_contents}>
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <div className={styles.management_details_form}>
                          <label for="">
                            Default Handle charge
                            <span
                              className="text-danger"
                              style={{ fontSize: "20px" }}
                            >
                              *
                            </span>
                          </label>
                          <SelectComponent
                            className={`form-control form-control-lg mb-3 ${styles.form_control}`}
                            error={
                              formErrors.refundform &&
                              formErrors.refundform[indx] &&
                              formErrors.refundform[indx].defaultLogisticCode
                            }
                            attributes={{
                              name: "defaultLogisticCode",
                              value: refund.defaultLogisticCode,
                              onChange: (e) => handleAvenueFormChange(e, indx),
                            }}
                          >
                            <option value="" disabled>
                              Choose a logistic
                            </option>
                            {logistic.map((region) => (
                              <option
                                key={region._id}
                                value={region.code}
                                disabled={selectedLogisticCodes.includes(
                                  region.code
                                )}
                              >
                                {region.code}
                              </option>
                            ))}
                          </SelectComponent>
                          <div
                            style={{
                              marginTop: "30px",
                            }}
                          >
                            <FormErrorText
                              error={
                                formErrors.refundform &&
                                formErrors.refundform[indx] &&
                                formErrors.refundform[indx].defaultLogisticCode
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-4">
                        <div className={styles.management_details_form}>
                          <label for="">
                            Charge
                            <span
                              className="text-danger"
                              style={{ fontSize: "20px" }}
                            >
                              *
                            </span>
                          </label>
                          <InputFormComponent
                            className={`form-control form-control-lg mb-3 ${styles.form_control}`}
                            error={
                              formErrors.refundform &&
                              formErrors.refundform[indx] &&
                              formErrors.refundform[indx].defaultCharge
                            }
                            formControl={{
                              type: "number",
                              min: 0,
                              step: 0.01,
                              placeholder: "",
                              name: "defaultCharge",
                              // maxLength: 5,
                              value: refund.defaultCharge,
                              onChange: (e) => handleAvenueFormChange(e, indx),
                            }}
                          />
                          <div
                            style={{
                              marginTop: "30px",
                            }}
                          >
                            <FormErrorText
                              error={
                                formErrors.refundform &&
                                formErrors.refundform[indx] &&
                                formErrors.refundform[indx].defaultCharge
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}

              <div className={styles.add_page_save_button}>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={addNew}
                  disabled={(logistic.length <= refundform.length) && logistic.length !=0 ? true : false}
                >
                  Add default Handle Charges
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.add_page_save_button}>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={submit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
