import HeaderComponent from "./HeaderComponent";
import SidebarComponent from "./SidebarComponent";
const LayoutComponent = (props) => {
  return (
    <section>
      <HeaderComponent />
      <div className="row mx-0">
        <SidebarComponent />
        <div className="col-lg-10 col-md-9 col-sm-12 px-0">{props.children}</div>
      </div>
    </section>
  );
};

export default LayoutComponent;
