import React from "react";
import commonstyles from "../../styles/SettingsTable.module.css";
import { useHistory } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import BackArrow from "../../assets/icons/back_arrow.svg";
import { AxiosInstance } from "../../utilities";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import "antd/dist/antd.css";
import deleteIcon from "../../assets/img/delete.svg";
import editIcon from "../../assets/img/edit.svg";
import { NavLink } from "react-router-dom";
import { Modal, Button, Space, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

function LogisticsList() {
  const history = useHistory();
  const [LogisticsList, setLogisticsList] = useState([]);
  // const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setpagination] = useState({});
  const [IsLoading, setIsLoading] = useState(false);
  const company = JSON.parse(localStorage.getItem("companydata"));
  const { confirm } = Modal;
  const [columns, setColumns] = useState([
    {
      title: "Logistics name",
      visible: false,
      key: "name",
      render: (text, record, index) => (
        <span>{record?.name ? record?.name : "-"}</span>
      ),
    },
    {
      title: "Code",
      visible: false,
      key: "code",
      render: (text, record, index) => (
        <span>{record?.code ? record?.code : "-"}</span>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) => (
        <>
          <NavLink to={`/settings/editLogistics/${record._id}`}>
            <img
              src={editIcon}
              className={`${commonstyles.admin_table_edit_img} mr-2`}
              alt="Edit Logistics Details"
              title="Edit logistics"
              style={{ cursor: "pointer" }}
            />
          </NavLink>
          <img
            src={deleteIcon}
            className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
            onClick={() => showDeleteConfirm(record._id)}
            data-testid="deletebtn"
            alt="Delete logistics"
            title="Delete logistics"
          />
        </>
      ),
    },
  ]);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  useEffect(() => {
    getLogistics(pageSize, nextPage, "");
    localStorage.removeItem("selectedIds");
    return () => {
      localStorage.removeItem("LogisticsList");
      localStorage.removeItem("selectedIds");
    };
  }, []);

  // *** GET LOGISTICS LIST *** //
  function getLogistics(pageSize, nextPage, filterarr, type) {
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };

    AxiosInstance(8000, "application/json")
      .get(
        `logistics/getLogistics?pageNum=${nextPage}&count=${pageSize}&sortField=createdAt&sortValue=DSC`,
        filterOptions
      )
      .then((res) => {
        // console.log(filterOptions);
        if (res && res.data && res.status == "200") {
          // console.log(res.data);
          if (res?.data?.data.length > 0) {
            res?.data?.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // console.log(res?.data?.data)
            localStorage.setItem(
              "LogisticsList",
              JSON.stringify(res?.data?.data)
            );
            setLogisticsList(res?.data?.data);
            setpagination(res.data.pagination);
          } else {
            setLogisticsList([]);
            setpagination({});
          }
        }
      })
      .catch((er) => {
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get Logistics",
        };
        showNotification(error);
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }
  /* ADD LOGISTICS TO RE-DIRECT */
  function addLogistics() {
    history.push(`/settings/addLogistics`);
  }

  // DELETE
  function showDeleteConfirm(id) {
    confirm({
      title: "Are you sure want to delete this ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        deleteLogistics(id);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  }

  function deleteLogistics(id) {
    // console.log(id);
    setIsLoading(true);
    AxiosInstance(8000, "application/json")
      .delete(`logistics/removeLogistic/${id}`)
      .then((res) => {
        // console.log(res);
        if (res && res?.data && res?.status == "200") {
          showNotification({
            type: "success",
            message: "Success",
            description: "Logistics deleted successfully",
          });
          setIsLoading(false);
          getLogistics(pageSize, nextPage, "");
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Failed to delete Logistics",
          });
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function debounceSearch(filter) {
    // console.log(filter);
    setIsLoading(true);
    setNextPage(1);
    getLogistics(pageSize, 1, filter);
  }

  //PAGINATION
  function handlepaginationChanges(value, type) {
    let filter = {};
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getLogistics(value, 1, filter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getLogistics(pageSize, value, filter);
    }
  }

  //RETURN
  return (
    <div className={commonstyles.management_contents}>
      <div className="row d-flex">
        <div className="back_arrow">
          <img
            src={BackArrow}
            onClick={() => history.push("/settings")}
            alt=""
            className="cursor-pointer"
            title="back"
            width={"25px"}
            height={"21px"}
          />
          <h1 className="heading">Logistics</h1>
        </div>
      </div>
      <div className={commonstyles.management_header}>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={addLogistics}
        >
          Add Logistics
        </button>
      </div>
      <div>
        <div
          className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
        ></div>
        <div className={`${commonstyles.management_table} table-responsive`}>
          {IsLoading === true && (
            <div
              style={{
                marginLeft: "70vh",
                marginTop: "10vh",
              }}
            >
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching Logistics details..." />
                </td>
              </tr>
            </div>
          )}
          {!IsLoading && LogisticsList.length > 0 && (
            <AntTableComponent
              className="order_table"
              tableProps={{
                columns: columns,
                dataSource: LogisticsList,
                pagination: false,
              }}
            />
          )}
        </div>
      </div>
      {!IsLoading.list &&
      Array.isArray(LogisticsList) &&
      LogisticsList.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          handlepaginationChanges={handlepaginationChanges}
        />
      ) : null}
    </div>
  );
}

export default LogisticsList;
