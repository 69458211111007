import React from "react";
const InputFormComponent = (props) => {
    // console.log(props);
  let classes = "form-control theme-form-control";
  if (props.className) {
    classes = `${classes} ${props.className}`;
  }
  if (props.error) {
    classes = `${classes} theme-error-input`;
  }

  return (
    <input
      min={props.min}
      max={props.max}
      autoComplete="off"
      type="text"
      {...props.formControl}
      className={classes}
    />
  );
};

export default InputFormComponent;
