import React from "react";
import commonstyles from "../../styles/Management_Common.module.css";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { notification } from "antd";
import { AxiosInstance } from "../../utilities";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import { Link, useParams } from "react-router-dom";
import BlackLeftArrow from "../../assets/img/black_left_arrow.svg";
import moment from "moment";

const ViewLog = ({ Detail, setDetails }) => {
  const { id } = useParams();
  const history = useHistory();
  const [editState, setEditState] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState({ details: false, update: false });
  const [enquiryDetail, setEnquiryDetail] = useState([]);

  useEffect(() => {
    if (id) {
      getEnquiryDetails(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function getEnquiryDetails(id) {
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`/quotes/getQuote/${id}`)
      .then((res) => {
        console.log(res.data);
        if (res && res.data && res.status == "200") {
          const val = res.data;
          setEnquiryDetail(val);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldn't fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  return (
    <>
      {isLoading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "40vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching quote log details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div className={commonstyles.management_contents}>
          {/* Header */}
          {/* <button onClick={gotoServices}>click </button> */}
          <div className={commonstyles.management_header}>
            <h1 className={commonstyles.management_header_heading}>
              <img
                src={BlackLeftArrow}
                onClick={() => history.push("/quotes")}
                alt=""
                className="cursor-pointer"
                title="back"
              />
              Quote Logs
            </h1>
          </div>
          <div className={commonstyles.profile_display}>
            <div>
              <div className={`row p-5 `}>
                <div
                  className={`col-lg-6 col-md-6  pl-5 pr-5 pb-1 ${commonstyles.userView} `}
                >
                  <h4 className="heading-main">Request Details</h4>
                  <div className={commonstyles.border_line}>
                    <pre style={{ color: "black" }}>
                      {JSON.stringify(enquiryDetail.reqBody, null, 2)}
                    </pre>
                  </div>
                </div>

                <div
                  className={`col-lg-6 col-md-6 pl-5 pr-5 pb-1 ${commonstyles.userView}`}
                >
                  <h4 className="heading-main">Response</h4>
                  <div>
                    <pre style={{ color: "black" }}>
                      {JSON.stringify(enquiryDetail.resBody, null, 2)}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="customer-details-bg-wrapper">
                <div className="border-line">
                  <div className="row">
                    <div className="product_details-container">
                      <h4 className="heading-main">Product Details</h4>
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Code</th>
                            <th scope="col">Type</th>
                            <th scope="col">Weight</th>
                            <th scope="col">Dimension(L x H x W)</th>
                            <th scope="col">Freight Class</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(enquiryDetail?.productsWithDetails) &&
                          enquiryDetail?.productsWithDetails?.length > 0 ? (
                            enquiryDetail.productsWithDetails.map(
                              (datail, indx, arr) => (
                                <tr>
                                  <td scope="row">
                                    {datail?.name ? datail?.name : "-"}
                                  </td>
                                  <td>{datail?.code ? datail?.code : "-"}</td>
                                  <td>
                                    {datail?.frieght == true
                                      ? "FREIGHT"
                                      : "PARCEL"}
                                  </td>
                                  <td>
                                    {datail?.weight ? datail?.weight : "-"}
                                  </td>
                                  <td>{`${datail?.length} x ${datail?.height} x ${datail?.width}`}</td>
                                  <td>
                                    {datail?.freightClass?.class
                                      ? datail?.freightClass?.class
                                      : "-"}
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr className="bg-transparent">
                              <td></td>
                              <td
                                style={{ padding: "10px", textAlign: "center" }}
                              >
                                No results found!
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewLog;
