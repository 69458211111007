import React, { useState, useEffect } from "react";
import styles from "../../styles/Warehouse.module.css";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification } from "antd";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import SelectComponent from "../ReusableComponent/SelectComponent";
import FormErrorText from "../FormErrorText";
import { onlyAlphabetsWithSpace } from "../RegularExp/RegularExp";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import validationRules from "../../validationRules";
import { isObject, isString, isEqual } from "lodash";
import { Link, useParams } from "react-router-dom";

const initialFormState = {
  name: "",
  // logisticCode: "",
  companyId: "",
  originLocation: "",
  billingLocation: "",
  accountDetail: "",
};

export default function AddWarehouseComponent() {
  const history = useHistory();
  const { id } = useParams();

  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);

  const [logistic, setLogistic] = useState([]);
  const [originLoc, setOriginLoc] = useState([]);
  const [billingLoc, setBillingLoc] = useState([]);
  const [accountDet, setAccountDet] = useState([]);
  const company = JSON.parse(localStorage.getItem("companydata"));
  const [getCompany, setGetCompany] = useState([]);

  const rules = {
    // logisticCode: [validationRules.required],
    name: [validationRules.required],
    companyId: [validationRules.required],
    originLocation: [validationRules.required],
    billingLocation: [validationRules.required],
    accountDetail: [validationRules.required],
  };

  useEffect(() => {
    getLogistics();
    getCompanyDetail();
    // console.log(id);
    if (id) {
      getWarehouseDetails(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getOriginLocations();
    getBillingLocations();
    getAccountDetails();
  }, [editForm?.companyId]);

  function getCompanyDetail() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getCompanies/settingsSection?pageNum=1&count=20&sortField=createdAt&sortValue=DSC`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetCompany(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get company details",
        };
        showNotification(error);
        // return er;
      });
  }

  /**
   * @author Prasanthu
   * GET LOGISTIC DETAILS
   */
  function getLogistics() {
    AxiosInstance(8000, "application/json")
      .get("logistics/getLogistics")
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setLogistic(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      });
  }

  /**
   * @author Prasanthu
   * GET ORIGIN LOCATION DETAILS
   */
  function getOriginLocations() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getOriginLocations?pageNum=1&count=10&sortField=createdAt&sortValue=DSC&companyId=${editForm.companyId}`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setOriginLoc(res.data.data);
        }
      })
      .catch((er) => {
        return er;
      });
  }

  /**
   * @author Prasanthu
   * GET BILLING LOCATION DETAILS
   */
  function getBillingLocations() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getBillingLocations?pageNum=1&count=10&sortField=createdAt&sortValue=DSC&companyId=${editForm.companyId}`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setBillingLoc(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      });
  }

  /**
   * @author Prasanthu
   * GET ACCOUNT DETAILS
   */
  function getAccountDetails() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getAccountDetails?pageNum=1&count=10&sortField=createdAt&sortValue=DSC&companyId=${editForm.companyId}`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setAccountDet(res.data.data);
        }
      })
      .catch((er) => {
        return er;
      });
  }

  function getWarehouseDetails(id) {
    //  console.log(id);
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`companies/getWarehouse/${id}`)
      .then((res) => {
        //   console.log(res);
        if (res && res?.data && res?.status == "200") {
          const val = res.data;
          // console.log(val.originLocation.displayName)
          setEditForm({
            name: val?.name,
            companyId: val?.company,
            originLocation: val?.originLocation?._id,
            billingLocation: val?.billingLocation?._id,
            accountDetail: val?.accountDetail?._id,
          });
          // console.log(editForm, "get by id ");
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    let { name, value } = e.target;
    // console.log(name, value);

    if (name === "companyId") {
      // setIsLoading(true);

      // console.log(value);
      if (!value && value === "") {
        formErrors.companyId = "Required";
      } else {
        formErrors.companyId = "";
      }
      setTimeout(() => {
        // setEditForm([]);
        setEditForm({
          name: editForm?.name,
          companyId: value,
          originLocation: "" || null,
          billingLocation: "" || null,
          accountDetail: "" || null,
        });

        // setIsLoading(false);
      }, 1000);
    } else if (name === "accountDetail") {
      if (!value && value === "") {
        formErrors.accountDetail = "Required";
      } else {
        formErrors.accountDetail = "";
      }
    } else if (name === "billingLocation") {
      if (!value && value === "") {
        formErrors.billingLocation = "Required";
      } else {
        formErrors.billingLocation = "";
      }
    } else if (name === "originLocation") {
      if (!value && value === "") {
        formErrors.originLocation = "Required";
      } else {
        formErrors.originLocation = "";
      }
    } else if (name === "name") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (
        !e.target.value.match(onlyAlphabetsWithSpace) ||
        !e.target.value === ""
      ) {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 3 and maximum 30)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    // console.log("______", editForm);
    const updatedValues = { ...editForm };
    updatedValues[name] = value;
    // console.log("update", updatedValues);
    setEditForm(updatedValues);
  }

  // console.log(editForm);
  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  // console.log(editForm);

  function submit(e) {
    if (id) {
      let data1 = JSON.parse(JSON.stringify(editForm));
      // console.log(data1);
      delete data1.createdAt;
      delete data1.updatedAt;
      delete data1.__v;
      delete data1.deleted;
      /* let data = {
      warehouseName: editForm.warehouseName,
      _id: id,
    }; */
      // console.log(editForm);
      const result = validate(undefined, data1);
      /* let data = {
        accountDetail: editForm?.accountDetail?._id,
        billingLocation: editForm?.billingLocation?._id,
        companyId: editForm?.companyId,
        // logisticCode: editForm.logisticCode,
        name: editForm?.name,
        originLocation: editForm?.originLocation?._id,
      }; */

      if (Object.keys(result).length) {
        setFormErrors(result);
        showNotification({
          type: "warning",
          message: "There were few errors",
          description: "Resolve the errors and try again",
        });
        return;
      }
      if (data1.name) {
        if (!onlyAlphabetsWithSpace.test(data1.name) || !data1.name === "") {
          setFormErrors({
            ...formErrors,
            name: "Enter only alphabets (minimum 3 and maximum 30)",
          });
          return false;
        } else {
          setFormErrors({ ...formErrors, name: "" });
        }
      }
      setLoading({ ...loading, update: true });
      AxiosInstance(8000, "application/json")
        .put(`/companies/updateWarehouse/${id}`, data1)
        //    .post(`/companies/${id ? "updateWarehouse" : "addWarehouse"}/${company.name}`, data1)
        .then((res) => {
          //   console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Warehouse updated successfully",
            });
            history.push(`/settings/warehouse`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding Warehouse failed",
              description: "Error occured",
            });
          }
        })
        .catch((error) => {
          showNotification({
            type: "error",
            message: "Warehouse failed",
            description: error?.response?.data?.message,
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    } else {
      let data1 = JSON.parse(JSON.stringify(editForm));
      delete data1.createdAt;
      delete data1.updatedAt;
      delete data1.__v;
      delete data1.deleted;
      /* let data = {
      warehouseName: editForm.warehouseName,
      _id: id,
    }; */
      // console.log(editForm);
      const result = validate(undefined, data1);
      /* let data = {
        accountDetail: editForm.accountDetail,
        billingLocation: editForm.billingLocation,
        companyId: editForm.companyId,
        // logisticCode: editForm.logisticCode,
        name: editForm.name,
        originLocation: editForm.originLocation,
      }; */
      if (Object.keys(result).length) {
        setFormErrors(result);
        showNotification({
          type: "warning",
          message: "There were few errors",
          description: "Resolve the errors and try again",
        });
        return;
      }
      if (data1.name) {
        if (!onlyAlphabetsWithSpace.test(data1.name) || !data1.name === "") {
          setFormErrors({
            ...formErrors,
            name: "Enter only alphabets (minimum 3 and maximum 30)",
          });
          return false;
        } else {
          setFormErrors({ ...formErrors, name: "" });
        }
      }
      setLoading({ ...loading, update: true });
      AxiosInstance(8000, "application/json")
        .post(`/companies/addWarehouse`, data1)
        .then((res) => {
          //   console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Warehouse added successfully",
            });
            history.push(`/settings/warehouse`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding Warehouse failed",
              description: "Error occured",
            });
          }
        })
        .catch((error) => {
          showNotification({
            type: "error",
            message: "Warehouse failed",
            description: error?.response?.data?.message,
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt="Back Arrow"
            className="cursor-pointer"
            onClick={() => history.push("/settings/warehouse")}
          />
          {id ? "Edit Warehouse" : "Add Warehouse"}
        </h1>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching Warehouse detail..." />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>
          <div className={styles.management_details_contents}>
            <div className="row">
              {/* <div className="col-lg-6 mb-4">
                <label>
                  Logistics Name
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.logisticCode}
                  className="form-control-md mb-3"
                  attributes={{
                    name: "logisticCode",
                    value: editForm.logisticCode,
                    onChange: (e) => {
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                    Select option
                  </option>
                  {Array.isArray(logistic)
                    ? logistic.map((logi) => (
                        <option key={logi._id} value={logi.code}>
                          {logi.code}
                        </option>
                      ))
                    : null}
                </SelectComponent>
                <div
                 
                >
                  <FormErrorText error={formErrors.logisticCode} />
                </div>
              </div> */}
              <div className="col-lg-6 mb-4">
                <label>
                  Site
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.companyId}
                  className="form-control-md mb-3"
                  attributes={{
                    name: "companyId",
                    value: editForm?.companyId,
                    onChange: handleFormInput,
                  }}
                >
                  <option value="" disabled>
                    Select option
                  </option>
                  {Array.isArray(getCompany)
                    ? getCompany.map((company) => (
                        <option key={company?._id} value={company?._id}>
                          {company?.code}
                        </option>
                      ))
                    : null}
                </SelectComponent>
                <div>
                  <FormErrorText error={formErrors.companyId} />
                </div>
              </div>
              {/* Warehouse Name */}
              <div className="col-lg-6 mb-4">
                <label className="theme-form-label">
                  Warehouse Name ( Please enter a name that is similar to the
                  product warehouse)
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.name}
                  formControl={{
                    placeholder: "Type the warehouse name",
                    name: "name",
                    value: editForm?.name,
                    onChange: handleFormInput,
                  }}
                />
                <div>
                  <FormErrorText error={formErrors.name} />
                </div>
              </div>
              {/* Warehouse end */}
              {editForm.companyId && (
                <>
                  <div className="col-lg-6 mb-4">
                    <label>
                      Origin location
                      <span
                        className="text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        *
                      </span>
                    </label>
                    <SelectComponent
                      error={formErrors.originLocation}
                      className="form-control-md mb-3"
                      attributes={{
                        name: "originLocation",
                        value: editForm?.originLocation,
                        onChange: (e) => {
                          handleFormInput(e);
                        },
                      }}
                    >
                      <option value="">Select option</option>
                      {Array.isArray(originLoc)
                        ? originLoc.map((origin) => (
                            <option key={origin._id} value={origin._id}>
                              {origin.displayName}
                            </option>
                          ))
                        : null}
                    </SelectComponent>
                    <div>
                      <FormErrorText error={formErrors.originLocation} />
                    </div>
                  </div>

                  <div className="col-lg-6 mb-4">
                    <label>
                      Billing location
                      <span
                        className="text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        *
                      </span>
                    </label>
                    <SelectComponent
                      error={formErrors.billingLocation}
                      className="form-control-md mb-3"
                      attributes={{
                        name: "billingLocation",
                        value: editForm?.billingLocation,
                        onChange: (e) => {
                          handleFormInput(e);
                        },
                      }}
                    >
                      <option value="">Select option</option>
                      {Array.isArray(billingLoc)
                        ? billingLoc.map((bill) => (
                            <option key={bill._id} value={bill._id}>
                              {bill.displayName}
                            </option>
                          ))
                        : null}
                    </SelectComponent>
                    <div>
                      <FormErrorText error={formErrors.billingLocation} />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <label>
                      Account details
                      <span
                        className="text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        *
                      </span>
                    </label>
                    <SelectComponent
                      error={formErrors.accountDetail}
                      className="form-control-md mb-3"
                      attributes={{
                        name: "accountDetail",
                        value: editForm?.accountDetail,
                        onChange: (e) => {
                          handleFormInput(e);
                        },
                      }}
                    >
                      <option value="">Select option</option>
                      {Array.isArray(accountDet)
                        ? accountDet.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.displayName}
                            </option>
                          ))
                        : null}
                    </SelectComponent>
                    <div>
                      <FormErrorText error={formErrors.accountDetail} />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={styles.add_page_save_button}>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={submit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
