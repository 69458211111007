import React, { useState, useEffect } from "react";
import styles from "../../styles/Warehouse.module.css";
import BlackLeftArrow from "../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification } from "antd";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import FormErrorText from "../FormErrorText";
import {
  userNameWithSpace,
  twodigit,
  onlyNumbers,
  postalCode,
  onlyAlphabetsWithSpace,
  alphaNum,
  numbersAlphabetsWithSpecialCharactersSpace
} from "../RegularExp/RegularExp";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import validationRules from "../../validationRules";
import { isObject, isString } from "lodash";
import { useParams } from "react-router-dom";
import { phoneRegx } from "../../RegularExp";
import SelectComponent from "../ReusableComponent/SelectComponent";

const initialFormState = {
  displayName: "",
  name: "",
  company: "",
  street1: "",
  street2: "",
  city: "",
  state: "",
  country: "",
  postalCode: "",
  phone: "",
};

export default function AddBillingLocationComponent() {
  const history = useHistory();
  const { id } = useParams();

  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const company = JSON.parse(localStorage.getItem("companydata"));
  //const onlyNumber1 = /^[0-9]*$/;
  const onlyNumber1 = /^(?:0?[1-9]\d*(?:\.\d*[0-9]*)?)|(:?^0.[1-9]+0)$/;
  const [getCompany, setGetCompany] = useState([]);

  const rules = {
    displayName: [validationRules.required],
    name: [validationRules.required],
    street1: [validationRules.required],
    street2: [validationRules.required],
    city: [validationRules.required],
    state: [validationRules.required],
    country: [validationRules.required],
    postalCode: [validationRules.required],
    phone: [validationRules.required],
    company: [validationRules.required],
  };

  useEffect(() => {
    getCompanyDetail();
    if (id) {
      getBillingLocationDetails(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  function getCompanyDetail() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getCompanies/settingsSection?pageNum=1&count=20&sortField=createdAt&sortValue=DSC`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetCompany(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get company details",
        };
        showNotification(error);
        // return er;
      });
  }

  function getBillingLocationDetails(id) {
    setLoading({ details: true, update: false });
    AxiosInstance(8000, "application/json")
      .get(`companies/getBillingLocation/${id}`)
      .then((res) => {
        //   console.log(res);
        if (res && res?.data && res?.status == "200") {
          const val = res.data;
          // console.log(val)
          setEditForm(val);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    let { name, value } = e.target;
    console.log(name, value);

    if (name === "street1") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(alphaNum) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 3 and maximum 30)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "street2") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(alphaNum) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 3 and maximum 30)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "state") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(twodigit) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 2 and maximum 30)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "country") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(twodigit) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 2 and maximum 30)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "city") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(onlyAlphabetsWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 3 and maximum 30)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "company") {
      if (!value && value === "") {
        formErrors.company = "Required";
      } else {
        formErrors.company = "";
      }
    }
    if (name === "postalCode") {
      value = value.trim();
      if (!value && value === "") {
        formErrors.postalCode = "Required";
      } else if (!postalCode.test(value.trim()) && value.trim() !== "") {
        formErrors.postalCode = "Please enter a valid zip";
      } else {
        formErrors.postalCode = "";
      }
    }
    if (name === "phone") {
      value = value.trim();
      if (!value && value === "") {
        formErrors.phone = "Required";
      } else if (!phoneRegx.test(value.trim()) && value.trim() !== "") {
        formErrors.phone = "Please enter a valid phone number";
      } else {
        formErrors.phone = "";
      }
    }
    if (name === "name") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(numbersAlphabetsWithSpecialCharactersSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 3 and maximum 30)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "displayName") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(onlyAlphabetsWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabets (minimum 3 and maximum 30)",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }

    const updatedValues = { ...editForm };
    updatedValues[name] = value;
    setEditForm(updatedValues);
  }

  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  // console.log(editForm);

  function submit(e) {
    let data1 = JSON.parse(JSON.stringify(editForm));
    delete data1.createdAt;
    delete data1.updatedAt;
    delete data1.__v;
    delete data1.deleted;
    const result = validate(undefined, data1);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }

    let data = {
      displayName: editForm.displayName,
      city: editForm.city,
      companyId: editForm.company,
      country: editForm.country,
      name: editForm.name,
      phone: editForm.phone,
      postalCode: editForm.postalCode,
      state: editForm.state,
      street1: editForm.street1,
      street2: editForm.street2,
    };
    if (data.displayName) {
      if (
        !onlyAlphabetsWithSpace.test(data.displayName) ||
        !data.displayName === ""
      ) {
        setFormErrors({
          ...formErrors,
          displayName: "Enter only alphabets (minimum 3 and maximum 30)",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, displayName: "" });
      }
    }
    if (data.city) {
      if (!userNameWithSpace.test(data.city) || !data.city === "") {
        setFormErrors({
          ...formErrors,
          city: "Enter only alphabets (minimum 3 and maximum 30)",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, city: "" });
      }
    }

    if (data.street1) {
      if (!alphaNum.test(data.street1) || !data.street1 === "") {
        setFormErrors({
          ...formErrors,
          street1: "Enter only alphabets (minimum 3 and maximum 30)",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, street1: "" });
      }
    }
    if (data.street2) {
      if (!alphaNum.test(data.street2) || !data.street2 === "") {
        setFormErrors({
          ...formErrors,
          street2: "Enter only alphabets (minimum 3 and maximum 30)",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, street2: "" });
      }
    }
    if (data.name) {
      if (!numbersAlphabetsWithSpecialCharactersSpace.test(data.name) || !data.name === "") {
        setFormErrors({
          ...formErrors,
          name: "Enter only alphabets (minimum 3 and maximum 30)",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, name: "" });
      }
    }
    if (data.postalCode) {
      if (!postalCode.test(data.postalCode) || !data.postalCode === "") {
        setFormErrors({
          ...formErrors,
          postalCode: "Enter only alphabets (minimum 3 and maximum 30)",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, postalCode: "" });
      }
    }
    if (data.state) {
      if (!twodigit.test(data.state) || !data.state === "") {
        setFormErrors({
          ...formErrors,
          state: "Enter only alphabet, minimum 2 and maximum 2",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, state: "" });
      }
    }
    if (data.country) {
      if (!twodigit.test(data.country) || !data.country === "") {
        setFormErrors({
          ...formErrors,
          country: "Enter only alphabet, minimum 2 and maximum 2",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, country: "" });
      }
    }
    if (data.phone) {
      if (!phoneRegx.test(data.phone) || !data.phone === "") {
        setFormErrors({
          ...formErrors,
          phone: "Enter only valid phone number",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, phone: "" });
      }
    }

    setLoading({ ...loading, update: true });

    if (id) {
      AxiosInstance(8000, "application/json")
        .put(`/companies/updateBillingLocation/${id}`, data)
        .then((res) => {
          //   console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Billing Location updated successfully",
            });
            history.push(`/settings/listBillingLocation`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Editing Billing Location failed",
              description: "Error occured",
            });
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Billing Location failed",
            description: "Error occured",
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    } else {
      AxiosInstance(8000, "application/json")
        .post(`/companies/addBillingLocation`, data)
        .then((res) => {
          //   console.log(res);
          if (res.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Billing Location added successfully",
            });
            history.push(`/settings/listBillingLocation`);
          } else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding Billing Location failed",
              description: "Error occured",
            });
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Adding Billing Location failed",
            description: "Error occured",
          });
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt="Back Arrow"
            className="cursor-pointer"
            onClick={() => history.push("/settings/listBillingLocation")}
          />
          {id ? "Edit Billing Location" : "Add Billing Location"}
        </h1>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching Billing Location detail..." />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>
          <div className={styles.management_details_contents}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  Description
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.displayName}
                  formControl={{
                    placeholder: "Type the Warehouse Name",
                    name: "displayName",
                    value: editForm?.displayName,
                    onChange: handleFormInput,
                  }}
                />

                <div>
                  <FormErrorText error={formErrors.displayName} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  Building Name
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.name}
                  formControl={{
                    placeholder: "Type the billing location name",
                    name: "name",
                    value: editForm?.name,
                    onChange: handleFormInput,
                  }}
                />

                <div>
                  <FormErrorText error={formErrors.name} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  Street 1
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.street1}
                  formControl={{
                    placeholder: "Type the billing location street1",
                    name: "street1",
                    value: editForm?.street1,
                    onChange: handleFormInput,
                  }}
                />
                <div>
                  <FormErrorText error={formErrors.street1} />
                </div>
              </div>
              {/* </div>
            <div className="row"> */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  Street 2
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.street2}
                  formControl={{
                    placeholder: "Type the billing location street2",
                    name: "street2",
                    value: editForm?.street2,
                    onChange: handleFormInput,
                  }}
                />
                <div>
                  <FormErrorText error={formErrors.street2} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  City
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.city}
                  formControl={{
                    placeholder: "Type the billing location city",
                    name: "city",
                    value: editForm?.city,
                    onChange: handleFormInput,
                  }}
                />
                <div>
                  <FormErrorText error={formErrors.city} />
                </div>
              </div>
              {/* </div>
            <div className="row"> */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  State
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.state}
                  formControl={{
                    placeholder: "Type the billing location state",
                    name: "state",
                    value: editForm?.state,
                    onChange: handleFormInput,
                  }}
                />
                <div>
                  <FormErrorText error={formErrors.state} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  Country
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.country}
                  formControl={{
                    placeholder: "Type the billing location country",
                    name: "country",
                    value: editForm?.country,
                    onChange: handleFormInput,
                  }}
                />
                <div>
                  <FormErrorText error={formErrors.country} />
                </div>
              </div>
              {/* </div>
            <div className="row"> */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  Postal Code
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.postalCode}
                  formControl={{
                    placeholder: "Type the billing location postal code",
                    name: "postalCode",
                    value: editForm?.postalCode,
                    onChange: handleFormInput,
                  }}
                />
                <div>
                  <FormErrorText error={formErrors.postalCode} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label className="theme-form-label">
                  Phone Number
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.phone}
                  formControl={{
                    placeholder: "Type the billing location phone number",
                    name: "phone",
                    value: editForm?.phone,
                    onChange: handleFormInput,
                  }}
                />
                <div>
                  <FormErrorText error={formErrors.phone} />
                </div>
              </div>
              {/* </div>
            <div className="row"> */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                <label>
                  Site
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.company}
                  className="form-control-md mb-3"
                  attributes={{
                    name: "company",
                    value: editForm.company,
                    onChange: (e) => {
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                    Select option
                  </option>
                  {Array.isArray(getCompany)
                    ? getCompany.map((company) => (
                        <option key={company?.code} value={company?._id}>
                          {company?.code}
                        </option>
                      ))
                    : null}
                </SelectComponent>
                <FormErrorText error={formErrors.company} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.add_page_save_button}>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={submit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
