import React from "react";
import commonstyles from "../../styles/SettingsTable.module.css";
import { useHistory } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { AxiosInstance } from "../../utilities";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import "antd/dist/antd.css";
import { Modal, Button, Space, notification, Menu } from "antd";
import arrowDown from "../../assets/images/arrow_down.svg";
import BackArrow from "../../assets/icons/back_arrow.svg";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

function ApiKeyList() {
  const history = useHistory();
  const [apiKey, setapiKey] = useState([]);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setpagination] = useState({});
  const [IsLoading, setIsLoading] = useState(false);
  const [getCompany, setGetCompany] = useState([]);
  const [filterCompany, setfilterCompany] = useState("");
  const company = JSON.parse(localStorage.getItem("companydata"));
  const { confirm } = Modal;
  const [columns, setColumns] = useState([
    {
      title: "Site Name",
      visible: false,
      key: "name",
      render: (text, record, index) => (
        <span>{record?.company?.name ? record?.company?.name : "-"}</span>
      ),
    },
    {
      title: "Cart API Key",
      visible: false,
      key: "key",
      render: (text, record, index) => (
        <span>{record?.apiKey ? record?.apiKey : "-"}</span>
      ),
    },
  ]);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  useEffect(() => {
    getCompanyDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWarehouse(pageSize, nextPage, "");
    localStorage.removeItem("selectedIds");
    return () => {
      localStorage.removeItem("apiKey");
      localStorage.removeItem("selectedIds");
    };
  }, [filterCompany]);

  function getCompanyDetail() {
    AxiosInstance(8000, "application/json")
      .get(
        `companies/getCompanies/settingsSection?pageNum=1&count=20&sortField=createdAt&sortValue=DSC`
      )
      .then((res) => {
        if (res && res.data && res.status == "200") {
          //   console.log(res.data);
          setGetCompany(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get company details",
        };
        showNotification(error);
        // return er;
      });
  }

  // *** FETCH WAREHOUSE LIST *** //
  function getWarehouse(pageSize, nextPage, filterarr, type) {
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      companyId: filterCompany,
    };

    AxiosInstance(8000, "application/json")
      .get(
        `apikeys/getApikeys?pageNum=${nextPage}&count=${pageSize}&sortField=createdAt&sortValue=DSC&companyId=${filterCompany}`,
        filterOptions
      )
      .then((res) => {
        // console.log(filterOptions);
        if (res && res.data && res.status == "200") {
          // console.log(res.data);
          if (res?.data?.data.length > 0) {
            res?.data?.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // console.log(res?.data?.data)
            localStorage.setItem("apiKey", JSON.stringify(res?.data?.data));
            setapiKey(res?.data?.data);
            setpagination(res.data.pagination);
          } else {
            setapiKey([]);
            setpagination({});
          }
        }
      })
      .catch((er) => {
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get warehouse",
        };
        showNotification(error);
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }
  /* ADD WAREHOUSE */
  function generateNewKey() {
    history.push(`/settings/addNewKey`);
  }

  function debounceSearch(filter) {
    // console.log(filter);
    setIsLoading(true);
    setNextPage(1);
    getWarehouse(pageSize, 1, filter);
  }

  //PAGINATION
  function handlepaginationChanges(value, type) {
    let filter = {};
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getWarehouse(value, 1, filter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getWarehouse(pageSize, value, filter);
    }
  }

  function filterOnChange(e) {
    const { name, value } = e.target;
    console.log(value);
    setfilterCompany(value);
    // setIsLoading(true);
  }
  //RETURN
  return (
    <div className={commonstyles.management_contents}>
      <div className="">
        <div className="row d-flex">
          <div className="back_arrow">
            <img
              src={BackArrow}
              onClick={() => history.push("/settings")}
              alt=""
              className="cursor-pointer"
              title="back"
              width={"25px"}
              height={"21px"}
            />
            <h1 className="heading" aria-label="heading">
              Cart API Key
            </h1>
          </div>
        </div>
      </div>
      <div className={commonstyles.management_header}>
        <div className="row">
          <div className="col-lg-6">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={generateNewKey}
            >
              Generate New Key
            </button>
          </div>
          <div className="col-lg-6 d-flex flex-row-reverse">
            <div className={` filter_width ${commonstyles.dropdown}`}>
              <Menu className={`filter_dropdown datefilter`}>
                <div className="card">
                  <div className="card_contents">
                    <div className="row no-gutters">
                      <div className="card_dropdown show col-md-6">
                        <select
                          name="filterCompany"
                          value={filterCompany}
                          onChange={filterOnChange}
                          className={`form-control  ${commonstyles.form_control} `}
                          style={{ cursor: "pointer" }}
                        >
                          <option value="">ALL</option>
                          {Array.isArray(getCompany)
                            ? getCompany.sort((a, b) => (a.code > b.code ? 1 : -1)).map((company) => (
                                <option
                                  key={company?.code}
                                  value={company?._id}
                                >
                                  {company?.code}
                                </option>
                              ))
                            : null}
                        </select>
                        <img src={arrowDown} className="dropDownArrow" />
                      </div>
                    </div>
                  </div>
                </div>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div /* className={styles.administrator} */>
        <div
          className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
        >
          {/* <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <input
            type="search"
            className={`${commonstyles.form_control} form-control`}
            aria-label="Search"
            placeholder="Search by Phone Number, Warehouse Name and logName "
            aria-describedby="search-addon"
            value={search}
            onChange={ handleInput}
          />
          <img src={Search} alt="" />
        </div> */}
        </div>
        <div className={`${commonstyles.management_table} table-responsive`}>
          {/* {!IsLoading && apiKey.length > 0 && (
                    <input
                        type="checkbox"
                        className={`${commonstyles.parentCheckbox}`}
                        onChange={parentCheckbox}
                    ></input>
                )} */}
          {IsLoading === true ? (
            <div
              style={{
                marginLeft: "70vh",
                marginTop: "10vh",
              }}
            >
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching get key details..." />
                </td>
              </tr>
            </div>
          ) : (
            <AntTableComponent
              className="order_table"
              tableProps={{
                columns: columns,
                dataSource: apiKey,
                pagination: false,
              }}
            />
          )}
        </div>
      </div>
      {!IsLoading.list && Array.isArray(apiKey) && apiKey.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          handlepaginationChanges={handlepaginationChanges}
        />
      ) : null}
    </div>
  );
}

export default ApiKeyList;
